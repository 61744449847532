function ShippingDetails({shipping}) {
  return (
    <div className="col-md-4">
      <article className="icontext align-items-start">
        <span className="icon icon-sm rounded-circle bg-primary-light">
          <i className="text-primary material-icons md-local_shipping"></i>
        </span>
        <div className="text">
          <h6 className="mb-1">Shipping Address</h6>
          <p className="mb-1">
            {shipping.firstName} {shipping.lastName}<br />
            {shipping.houseNumber}<br/>
            {shipping.street}, {shipping.city}, <br />
            {shipping.pin} <br />
            {shipping.phone}
          </p>
        </div>
      </article>
    </div>
  )
}

export default ShippingDetails;