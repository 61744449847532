import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getAddresses as listAddresses } from '../../redux/actions/adressActions';
import { getUserById } from '../../redux/actions/userAction';
import { Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";

function UserDetials({match, history}) {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const getUser = useSelector(state => state.getUser);
  const loggedInUser = useSelector((state) => state.user);
  const { user } = getUser;
  const getAddresses = useSelector(state => state.getAddresses);
  const { addresses } = getAddresses;

  useEffect(() => {
    dispatch(getUserById(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    dispatch(listAddresses(match.params.id))
  }, [dispatch, match.params.id]);

  const goBack = () => {
    navigate.goBack(-1);
  }

  return (
    <>
      <div className='container'>
        {loggedInUser.userInfo.details.isAdmin &&
          <Link to="#" onClick={goBack}>
            <Button variant="outline-light" size="lg" className="btn btn-secondary btn-sm rounded font-sm ms-2" >
              Back
            </Button>
          </Link>
        }
        <div className='row'>
          <div className='col-12 pt-30 pb-30'>
            <div className="card">
              <div className="card-header">
                <h5>User</h5>
              </div>
              <div className="card-body">
              <div className='row'>
                <p>
                  Name: {user.firstName} {user.lastName} <br/>
                  Email: {user.email} <br/>
                </p>
              </div>
              <div className="row col mt-30">
                {addresses && addresses.map((a, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className="card mb-3 mb-lg-0">
                      <div className="card-header">
                        <h4 className="mb-0">{a.addressType}</h4>
                      </div>
                      <div className="card-body">
                        <address>
                          {a.name}<br />
                          {a.street}, {a.houseNumber}<br />
                          {a.city} - {a.pin}<br />Phone: {a.phone}<br />
                          Email: {a.email}
                        </address>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetials;