export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";
export const GET_ORDER_DETAILS_RESET = "GET_ORDER_DETAILS_RESET";

export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const FETCH_MY_ORDER = 'FETCH_MY_ORDER';

export const GET_DOWNLOAD_INVOICE_REQUEST = "GET_DOWNLOAD_INVOICE_REQUEST";
export const GET_DOWNLOAD_INVOICE_SUCCESS = "GET_DOWNLOAD_INVOICE_SUCCESS";
export const GET_DOWNLOAD_INVOICE_FAIL = "GET_DOWNLOAD_INVOICE_FAIL";