import React from 'react';
import { useSelector } from 'react-redux';

function MyAccount() {
  const user = useSelector(state => state.user)
  return (
    <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
      <div className="card">
        <div className="card-header">
          <h3 className="mb-0">Hello {user.userInfo.details.fullName}({user.userInfo.details.email})!</h3>
        </div>
        <div className="card-body">
          <p>
            From your account dashboard. you can easily check &amp; view your <a href="/orders">recent orders</a>,<br />
            manage your <a href="/my-account">shipping and billing addresses</a> and <a href="/forgot-password">edit your password and account details.</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default MyAccount;