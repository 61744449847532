import './Users.css';
import Pagination from '../../pagination/Pagination';
import {useMemo, useState} from 'react'
import { useDispatch } from "react-redux";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
import NewCustomer from "../../screens/Admin/NewCustomer";
import EditCustomer from "../../screens/Admin/EditCustomer";
import { removeUserDetails } from '../../redux/actions/userAction'
function List({users}) {
  const dispatch = useDispatch();
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  const removeUser = (item) => {
    dispatch(removeUserDetails({ _id: item._id }));
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-3'>
          <NewCustomer />
        </div>
        <div className='col-3'>
          <Link to={`/admin/users/download`}>
            <Button variant="outline-light" size="lg">
              Download
            </Button>
          </Link>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Admin</th>
            <th colSpan="3">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 && currentTableData.length > 0 && currentTableData.map((user, index) => (
            <tr key={user._id}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.mobile}</td>
              <td>{user.isAdmin ? "True" : "False"}</td>
              <td>
                <EditCustomer user={user} />
              </td>
              <td>
                <Link to={`/users/${user._id}`}>
                  <Button variant="outline-light" size="lg">
                    View
                  </Button>
                </Link>
              </td>
              <td>
              <Button variant="warning" size="lg" onClick={() => removeUser(user)}>
                Delete
              </Button>
                {/* <Link to={`/admin/users/${user._id}`}>
                  <Button variant="outline-light" size="lg">
                    Delete
                  </Button>
                </Link> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        key={Date.now()}
        id={Date.now()}
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)} />
    </>
  )
}

export default List;