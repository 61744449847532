import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { updateCategory } from '../../redux/actions/categoryActions';
import { Link, useHistory } from "react-router-dom";
import './Admin.css';

const CategoryTypes = [
  { value: 'category', text: 'Category' },
  { value: 'solution', text: 'Solution' },
];

function EditCategoryForm({category, history}) {
  const dispatch = useDispatch()
  const navigate = useHistory();

  const [name, setName] = useState(category.name);
  const [type, setType] = useState(category.type);
  const [image, setImage] = useState("");
  const [popular, setPopular] = useState(category.popular);

  const checkPopular = () => {
    return category.popular === true
  }
  const handleScreenImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result)
      };
    } else {
      setImage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateCategory(category._id, {
        name,
        type,
        imageUrl: category.imageUrl,
        image,
        popular
      })
    );
    navigate.push("/admin/#categories");
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setPopular(checked);
  };

  const cancelPage = () => {
    debugger;
    history.goBack();
  }
  return (
    <>
      <div className='main pb-50'>
        <div className='col-md-10' style={{margin: "50px"}}>
          <div className="card">
            <div className="card-header">
              <h5>Category</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-20">
                  <div className="form-group col-md-12">
                    <label>Upload files <span className="required">*</span></label>
                    <input
                      id="imgUpload"
                      name='file'
                      accept="image/*"
                      type="file"
                      className="button-upload"
                      onChange={handleScreenImageUpload}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <select value={type} onChange={(e) => setType(e.target.value)} required>
                      <option value="">Shop by</option>
                      {CategoryTypes.map(item => (
                        <option value={item.value} key={item.value}>{item.text}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label>Name <span className="required">*</span></label>
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required />
                  </div>
                  <div className="col-md-12">
                    <div className='col-md-4'>
                      <label >Set as Popular</label>
                    </div>
                    <div className='float-start'>
                      <input
                      type="checkbox"
                      defaultChecked={checkPopular()}
                      onChange={(e) => handleChange(e)}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 float-end">
                  <Button variant="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Link className="btn mx-2" to="#" variant="normal" onClick={cancelPage}>
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditCategoryForm;