import SideNav from "../SideNav";
import './Advertisements.css';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//Actions
import { getAdvertisements as listAdvertisements } from '../../redux/actions/advertisementActions'

import List from "./list";

const Advertisements = () => {
  const dispatch = useDispatch()
  const getAdvertisements = useSelector(state => state.getAdvertisements)
  const { advertisements } = getAdvertisements;
  
  useEffect(() => {
    dispatch(listAdvertisements())
  }, [dispatch])

  return (
    <><div className="styled-dashboard">
      <SideNav />
      <div className='content'>
        <div className='float-end'>
          <Link to={`/admin/advertisements/new`}>
            <Button variant="outline-light" size="lg">
              New
            </Button>
          </Link>
        </div>
        { advertisements.length > 0 &&
          <List advertisements={advertisements} />
        }
      </div>
    </div></>
  );
};
  
export default Advertisements;