
function TermsConditions() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2 text-center">
                    <h2>Terms of Service</h2>
                  </div>
                  <div className="single-content mb-50">
                    <p>Welcome to Dhenki Foods Private Limited. These Terms of Service ("Agreement") govern your use of our website and services. By accessing or using any part of our site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this Agreement, you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                                        <h5>SECTION 1: - Overview</h5>
                    <p>This website is operated by Dhenki Foods Private Limited. Throughout the document, the terms “we,” “us,” and “our” refer to Dhenki Foods Private Limited. By using our website, you engage in our “Service” and agree to be bound by these Terms.</p>
                    
                    <h5>SECTION 2: - Online Store Terms</h5>
                    <p>By agreeing to these Terms of Service, you confirm that you are of legal age in your state or province of residence, or you have given consent for any minor dependents to use this site. The use of our products for any illegal or unauthorized purpose is prohibited.</p>
                    
                    <h5>SECTION 3: - General Conditions</h5>
                    <p>We reserve the right to refuse service to anyone at any time for any reason. Your content may be transferred unencrypted and involve transmissions over various networks. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without express written permission.</p>
                    
                    <h5>SECTION 4: - Accuracy, Completeness, and Timeliness of Information</h5>
                    <p>We are not responsible if information made available on this site is not accurate, complete, or current. Historical information is provided for reference, and we reserve the right to modify the contents of this site at any time. </p>
                    
                    <h5>SECTION 5: - Modifications to the Service and Prices</h5>
                    <p>Prices for our products are subject to change without notice. We reserve the right to modify or discontinue the Service without notice. We shall not be liable for any modification, price change, suspension, or discontinuance of the Service.</p>
                    
                    <h5>SECTION 6: - Products or Services (if applicable)</h5>
                    <p>Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
                    
                    <h5>SECTION 7: - Accuracy of Billing and Account Information</h5>
                    <p>We reserve the right to refuse any order and limit or cancel quantities purchased per person or order. You agree to provide current, complete, and accurate purchase and account information.</p>
                    
                    <h5>SECTION 8: - Optional Tools</h5>
                    <p>We may provide access to third-party tools, and you agree that we provide access “as is” without any warranties. Your use of optional tools is at your own risk.</p>
                    
                    <h5>SECTION 9: - Third-Party Links</h5>
                    <p>Certain content, products, and services may include materials from third-parties. We are not responsible for third-party materials or websites.</p>
                    
                    <h5>SECTION 10: - User Comments, Feedback, and Other Submissions</h5>
                    <p>By submitting comments or materials, you agree that we may use and publish them. We have the right to monitor, edit, or remove content that we find objectionable.</p>
                    
                    <h5>SECTION 11: - Personal Information</h5>
                    <p>Your submission of personal information is governed by our Privacy Policy.</p>
                    
                    <h5>SECTION 12: - Errors, Inaccuracies, and Omissions</h5>
                    <p>We reserve the right to correct errors, inaccuracies, or omissions and to change or update information or cancel orders if information is inaccurate.</p>
                    
                    <h5>SECTION 13: - Prohibited Uses</h5>
                    <p>You are prohibited from using the site for unlawful purposes or to violate any laws, infringe upon intellectual property rights, or interfere with the security features.</p>
                    
                    <h5>SECTION 14: - Disclaimer of Warranties; Limitation of Liability</h5>
                    <p>We do not guarantee uninterrupted, timely, secure, or error-free service. Your use of the service is at your sole risk.</p>
                    
                    <h5>SECTION 15: - Indemnification</h5>
                    <p>You agree to indemnify and hold Dhenki Foods Private Limited harmless from any claim or demand.</p>
                    
                    <h5>SECTION 16: - Severability</h5>
                    <p>In the event that any provision of these Terms of Service is determined to be unlawful, void, or unenforceable, the remaining provisions shall remain enforceable.</p>
                    
                    <h5>SECTION 17: - Termination</h5>
                    <p>The obligations and liabilities of the parties incurred before the termination date shall survive the termination of this Agreement.</p>
                    
                    <h5>SECTION 18: - Entire Agreement</h5>
                    <p>These Terms of Service constitute the entire agreement between you and us, superseding any prior agreements.</p>
                    
                    <h5>SECTION 19: - Governing Law</h5>
                    <p>These Terms of Service are governed by the laws of India.</p>
                    
                    <h5>SECTION 20: - Changes to Terms of Service</h5>
                    <p>We reserve the right to update, change, or replace any part of these Terms of Service by posting updates on our website.</p>
                    
                    <h5>SECTION 21: - Contact Information</h5>
                    <p>For questions about these Terms of Service, please contact us at dhenkifood@gmail.com or Dhenki Foods Private Limited, Sammilani Park, Kolkata-700075, India, Customer Helpline: +91  8240379055.</p>
                    
                    <p>Thank you for choosing Dhenki Foods Private Limited. We look forward to providing you with exceptional service.</p>
  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsConditions;