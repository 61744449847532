
function  SkuInput({sku, setSku, deleteSkuRows, handleChange}) {
  const units = ['ml', 'l', 'grm', 'kg']
  const handleRadio = (index, event) => {
    const { name } = event.target;
    const rowsInput = [...sku];
    rowsInput.forEach((data, i) => {
      rowsInput[i][name] = index === i;
    });
    setSku(rowsInput);
  }
  return (
    sku.map((data, index) => {
      const { unit, value, defaultSku } = data;
      return (
        <tr key={index}>
          <td>
          <input
            type="number"
            name="value"
            placeholder="value"
            value={value}
            className="form-control"
            onChange={(evnt)=>(handleChange(index, evnt))}
            required
          />
          </td>
          <td>
            <select
              name="unit"
              onChange={(evnt) => handleChange(index, evnt)}
              value={unit} required className="form-control">
              <option value="">Select unit</option>
              {units.map(unit => (<option value={unit} key={unit}>{unit}</option>))}
            </select>
          </td>
          <td>
            {}
            <input type="radio" value={defaultSku} name='defaultSku'
              checked={defaultSku}
              onChange={(evnt) => handleRadio(index, evnt)} />
          </td>
          <td className="float-end">
            <button
              className="btn btn-outline-danger"
              onClick={()=>(deleteSkuRows(index))}>x</button>
          </td>
        </tr>
      )
    })
  )
}

export default SkuInput;