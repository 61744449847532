import {Api} from '../../utils/Api'
import * as actionTypes from '../constants/userContants'
import { toast } from "react-toastify";

export const addUser = (user) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_USER,
    payload: {
      fullname: user.name,
      mobile: user.mobile,
      email: user.fullname,
      isAdmin: user.isAdmin,
      passowrd: user.passowrd,
    },
  })

  Api.postRequest('/api/users', user)
  toast.success("User is created", {
    position: "top-right",
  });
}

export const updateUser = (id, user) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_USER,
    payload: {
      fullname: user.name,
      mobile: user.mobile,
      email: user.fullname,
      isAdmin: user.isAdmin,
    },
  })

  Api.putRequest(`/api/users/${id}`, user)
  toast.success("User is created", {
    position: "top-right",
  });
}

export const getUserById = (id) => async dispatch => {
  try {
    const {data} = await Api.getRequest(`/api/users/${id}`);
    dispatch({
      type: actionTypes.GET_USER_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUsers = () => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_USERS_REQUEST})
    const {data} = await Api.getRequest('/api/users');

    dispatch({
      type: actionTypes.GET_USERS_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const setUserDeatils = (isLoggedIn) => async dispatch => {
  const {statusCode, data} = await Api.getRequest(`/api/users/me`)
  if (statusCode === 400 || statusCode === 500) {
    dispatch({
      type: actionTypes.SET_INITIAL_STATE,
    })
    return
  }
  const {user} = JSON.parse(data)
  dispatch({
    type: actionTypes.SET_USER,
    payload: {
      isLogin: user ? true : false,
      details: {...user},
    },
  })
  if (isLoggedIn) {
    const cartLocalStorage = JSON.parse(localStorage.getItem("cartList") || "[]");
    cartLocalStorage.map((cart) =>  
      Api.postRequest('/api/cart', { productId: cart.productId, count: cart.qty, unit: cart.unit })
    )
    toast.success("Login is successful", {
      position: "top-right",
    });
  }
}

export const setInitialState = () => async dispatch => {
  dispatch({
    type: actionTypes.SET_INITIAL_STATE,
  })
}

export const updatePassword = (user) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_USER,
    payload: {
      email: user.fullname,
      passowrd: user.passowrd
    },
  })

  Api.postRequest(`/api/users/change-password`, user)
  toast.success("Password is updated", {
    position: "top-right",
  });
}


export const removeUserDetails = (id) => async dispatch => {
  try {
    await Api.DeleteRequest(`/api/users/${id._id}`)
    .then((response) => {
      dispatch(getUsers());
      toast.success("User is deleted", {
        position: "bottom-left",
      });
    });
  } catch (error) {
    console.log(error)
  }
}
