// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cartitem {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
    gap: 8px;
    background: #fff;
    border-radius: 2px;
    place-items: center;
    margin-bottom: 8px;
  }
  
  .cartItem__name {
    text-decoration: none;
    color: #171717;
  }
  
  .cartItem__name:hover {
    color: #dd219e;
  }
  
  .cartItem__select {
    padding: 10px 17px;
    border: 2px solid #3BB77E !important;
  }
  
  .cartItem__deleteBtn {
    padding: 10px 17px;
    color: red;
    background: #f4f4f4;
    border: 1px solid #171717;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  
  .cartItem__deleteBtn:hover,
  .carItem__deleteBtn:active,
  .carItem__deleteBtn:focus {
    background: #171717;
    transform: scale(1.2);
  }
  
  @media (max-width: 700px) {
    .cartItem__name {
      font-size: 0.8rem;
    }
  
    .cartItem__select,
    .cartItem__deleteBtn {
      padding: 8px 13px;
    }
  
    .cartitem__price {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 700px) {
    .cartItem__name {
      font-size: 0.6rem;
    }
  
    .cartItem__select,
    .cartItem__deleteBtn {
      padding: 5px 8px;
    }
  
    .cartitem__price {
      font-size: 0.6rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Cart/Cart.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,0CAA0C;IAC1C,QAAQ;IACR,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,oCAAoC;EACtC;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,6BAA6B;EAC/B;;EAEA;;;IAGE,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;;MAEE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;;MAEE,gBAAgB;IAClB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".cartitem {\n    width: 100%;\n    padding: 1rem;\n    display: grid;\n    grid-template-columns: 1fr 4fr 1fr 1fr 1fr;\n    gap: 8px;\n    background: #fff;\n    border-radius: 2px;\n    place-items: center;\n    margin-bottom: 8px;\n  }\n  \n  .cartItem__name {\n    text-decoration: none;\n    color: #171717;\n  }\n  \n  .cartItem__name:hover {\n    color: #dd219e;\n  }\n  \n  .cartItem__select {\n    padding: 10px 17px;\n    border: 2px solid #3BB77E !important;\n  }\n  \n  .cartItem__deleteBtn {\n    padding: 10px 17px;\n    color: red;\n    background: #f4f4f4;\n    border: 1px solid #171717;\n    cursor: pointer;\n    transition: all 0.3s ease-out;\n  }\n  \n  .cartItem__deleteBtn:hover,\n  .carItem__deleteBtn:active,\n  .carItem__deleteBtn:focus {\n    background: #171717;\n    transform: scale(1.2);\n  }\n  \n  @media (max-width: 700px) {\n    .cartItem__name {\n      font-size: 0.8rem;\n    }\n  \n    .cartItem__select,\n    .cartItem__deleteBtn {\n      padding: 8px 13px;\n    }\n  \n    .cartitem__price {\n      font-size: 0.8rem;\n    }\n  }\n  \n  @media (max-width: 700px) {\n    .cartItem__name {\n      font-size: 0.6rem;\n    }\n  \n    .cartItem__select,\n    .cartItem__deleteBtn {\n      padding: 5px 8px;\n    }\n  \n    .cartitem__price {\n      font-size: 0.6rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
