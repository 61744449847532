import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

//Actions
import {getUsers as listUsers} from '../../redux/actions/userAction'
import List from './list';

const Users = () => {
  const dispatch = useDispatch()
  const getUsers = useSelector(state => state.getUsers)
  const { users } = getUsers;
  
  useEffect(() => {
    dispatch(listUsers())
  }, [dispatch])

  return (
    <div className="styled-dashboard"><div className='content'>
        { users && users.length > 0 &&
          <List users={users} />
        }
      </div>
    </div>
  )
};
  
export default Users;