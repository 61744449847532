import * as actionTypes from "../constants/orderProductConstants";

export const getOrderProductsReducer = (state = { orderProducts: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_PRODUCTS_REQUEST:
      return {
        loading: true,
        orderProducts: [],
      };
    case actionTypes.GET_ORDER_PRODUCTS_SUCCESS:
      return {
        orderProducts: action.payload,
        loading: false,
      };
    case actionTypes.GET_ORDER_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
    }
  };

  export const getOrderProductsWithOrderIdReducer = (state = { orderProducts: [] }, action) => {
    switch (action.type) {
      case actionTypes.GET_ORDER_PRODUCTS_WITH_ORDER_REQUEST:
        return {
          loading: true,
          orderProducts: [],
        };
      case actionTypes.GET_ORDER_PRODUCTS_WITH_ORDER_SUCCESS:
        return {
          orderProducts: action.payload,
          loading: false,
        };
      case actionTypes.GET_ORDER_PRODUCTS_WITH_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
      }
    };
  
  export const getOrderProductDetailsReducer = (state = { orderProduct: {} }, action) => {
    switch (action.type) {
    case actionTypes.GET_ORDER_PRODUCT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_ORDER_PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        orderProduct: action.payload,
      };
    case actionTypes.GET_ORDER_PRODUCT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ORDER_PRODUCT_DETAILS_RESET:
      return {
        orderProduct: {},
      };
    default:
      return state;
    }
  };
  