// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    display: flex;
    flex-direction: column;
    /* max-width: 300px; */
    margin-top: 2rem;
  }
  
  form select, input {
    padding: 7px;
    min-height: 30px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin: 0.3rem 0;
  
    &:focus {
      border: 2px solid rgb(0, 208, 255);
    }
  }
  
  form select {
    color: rgb(95, 95, 95);
  }
  
  
  .styled-created-product {
    display: flex;
    justify-content: space-between;
  }
  
  .image-preview {
    margin: 2rem 0 2rem 2rem;
    padding: 2rem;
    border: 1px solid rgb(183, 183, 183);
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: rgb(78, 78, 78);
  
    img {
      max-width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/admin/categories/Categories.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,oCAAoC;IACpC,gBAAgB;;IAEhB;MACE,kCAAkC;IACpC;EACF;;EAEA;IACE,sBAAsB;EACxB;;;EAGA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,wBAAwB;IACxB,aAAa;IACb,oCAAoC;IACpC,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;;IAEtB;MACE,eAAe;IACjB;EACF","sourcesContent":["form {\n    display: flex;\n    flex-direction: column;\n    /* max-width: 300px; */\n    margin-top: 2rem;\n  }\n  \n  form select, input {\n    padding: 7px;\n    min-height: 30px;\n    outline: none;\n    border-radius: 5px;\n    border: 1px solid rgb(182, 182, 182);\n    margin: 0.3rem 0;\n  \n    &:focus {\n      border: 2px solid rgb(0, 208, 255);\n    }\n  }\n  \n  form select {\n    color: rgb(95, 95, 95);\n  }\n  \n  \n  .styled-created-product {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .image-preview {\n    margin: 2rem 0 2rem 2rem;\n    padding: 2rem;\n    border: 1px solid rgb(183, 183, 183);\n    max-width: 300px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2rem;\n    color: rgb(78, 78, 78);\n  \n    img {\n      max-width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
