import * as actionTypes from "../constants/orderConstants";
import { Api } from '../../utils/Api';
import { convertToOrderData } from "../../utils/utils.function";
import { toast } from "react-toastify";

export const fetchOrder = () => async dispatch => {
  try {
    const {data: strigifyData} = await Api.getRequest(`/api/orders/`)
    const orders  = JSON.parse(strigifyData)
    dispatch({
      type: actionTypes.GET_ORDERS_SUCCESS,
      payload: convertToOrderData(orders),
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
}

export const addOrder = (order) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_ORDER,
    payload: {
      userId: order.userId,
      paymentMethod: order.paymentMethod,
      products: order.products,
      subtotal: order.subtotal,
      total: order.total,
      shipping: order.address,
      shippingCost: order.shippingCost,
      currency: order.currency,
      orderNote: order.orderNote
    },
  })

  Api.postRequest('/api/orders', order).then(() =>{
    dispatch(fetchOrder());
  });
  toast.success("Order is created", {
    position: "top-right",
  });
}


export const updateOrder = (id, order) => async dispatch => {
  Api.putRequest(`/api/orders/${id}`, order).then(() =>{
    dispatch(fetchOrder());
  });
  toast.success("Order is updated", {
    position: "top-right",
  });
}

export const getOrderDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ORDER_DETAILS_REQUEST})

    const {data} = await Api.getRequest(`/api/orders/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_ORDER_DETAILS_SUCCESS,
      payload: {
        ...p.record,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const downloadInvoice = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_DOWNLOAD_INVOICE_REQUEST})

    const {data} = await Api.getRequest(`/api/orders/download/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_DOWNLOAD_INVOICE_SUCCESS,
      payload: {
        ...p.record,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_DOWNLOAD_INVOICE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}