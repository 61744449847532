import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchOrder as listOrders } from '../../redux/actions/orderActions'
// import {} from '../../redux/actions/userAction'

import SideNav from '../SideNav';
import OrderList from '../../components/js/OrderList';


const Orders = () => {
  const dispatch = useDispatch();
  const getOrders = useSelector(state => state.getOrders);
  const { orders } = getOrders;

  useEffect(() => {
    dispatch(listOrders());
  }, [dispatch])

  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className='content'>
        { orders.length > 0 &&
          <OrderList orders={orders} />
        }
      </div>
    </div>
  )  
};
  
export default Orders;
  