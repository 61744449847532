export const GET_ADVERTISEMENTS_REQUEST = "GET_ADVERTISEMENTS_REQUEST";
export const GET_ADVERTISEMENTS_SUCCESS = "GET_ADVERTISEMENTS_SUCCESS";
export const GET_ADVERTISEMENTS_FAIL = "GET_ADVERTISEMENTS_FAIL";

export const GET_ADVERTISEMENT_DETAILS_REQUEST = "GET_ADVERTISEMENT_DETAILS_REQUEST";
export const GET_ADVERTISEMENT_DETAILS_SUCCESS = "GET_ADVERTISEMENT_DETAILS_SUCCESS";
export const GET_ADVERTISEMENT_DETAILS_FAIL = "GET_ADVERTISEMENT_DETAILS_FAIL";
export const GET_ADVERTISEMENT_DETAILS_RESET = "GET_ADVERTISEMENT_DETAILS_RESET";

export const ADD_ADVERTISEMENT = 'ADD_ADVERTISEMENT';
export const ADD_ADVERTISEMENT_FAIL = 'ADD_ADVERTISEMENT_FAIL';
export const UPDATE_ADVERTISEMENT = 'UPDATE_ADVERTISEMENT';
export const UPDATE_ADVERTISEMENT_FAIL = 'UPDATE_ADVERTISEMENT_FAIL';
