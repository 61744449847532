import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../redux/actions/userAction'

function EditCustomer({user}) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState(user.fullName);
  const [email, setEmail] = useState(user.email);
  const [mobile, setMobile] = useState(user.mobile);
  const [password, setPassword] = useState(user.password);
  const [confirmPassword, setConfirmPassword] = useState(user.password);

  const handleSubmit = async (e) => {
    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      e.preventDefault();
      dispatch(
        updateUser(user._id, {
          fullName,
          email,
          mobile,
          password,
        })
      );
      handleClose();
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Customer</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Name <span className="required">*</span></label>
                    <input
                      value={fullName}
                      required=""
                      className="form-control"
                      name="fullName"
                      type="text"
                      onChange={(e) => setFullName(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Phone <span className="required">*</span></label>
                    <input
                      value={mobile}
                      required=""
                      className="form-control"
                      name="phone"
                      type="text"
                      onChange={(e) => setMobile(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Email <span className="required">*</span></label>
                    <input
                      value={email}
                      required=""
                      className="form-control"
                      name="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Password <span className="required">*</span></label>
                    <input
                      value={password}
                      required=""
                      className="form-control"
                      name="address_type"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Confirm Password <span className="required">*</span></label>
                    <input
                      value={confirmPassword}
                      required=""
                      className="form-control"
                      name="address_type"
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditCustomer;