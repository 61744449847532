import SideNav from "../SideNav";
import './Categories.css';

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//Actions
import {getCategories as listCategories} from '../../redux/actions/categoryActions'
import List from "./list";

const Categories = () => {
  const dispatch = useDispatch()
  const getCategories = useSelector(state => state.getCategories)
  const { categories } = getCategories;
  
  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  return (
    <><div className="styled-dashboard">
      <SideNav />
      <div className='content'>
        { categories.length > 0 &&
          <List categories={categories} />
        }
      </div>
    </div></>
  );
};
  
export default Categories;