import Pagination from '../../pagination/Pagination';
import { useMemo, useState } from 'react'
import ProductDetails from "./ProductDetails";

function ProductList({products, categories, itemcount}) {
  const records = products
  const PageSize = itemcount === "All" ? records.length : Number(itemcount);;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
      return records.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, records, PageSize]);

  return (
    <><div className="row product-grid">
      {currentTableData.map(product => (
        <ProductDetails
          key={product._id}
          name={product.name}
          categoryName={product.categoryId.name}
          categoryId={product.categoryId}
          price={product.price}
          imageUrl={product.assets && (product.assets.length > 0 ? product.assets[0]: '../logo192.png')}
          productId={product._id}
          countInStock={product.countInStock}
          sku={product.sku} />
      ))}
    </div><div className="pagination-area mt-20 mb-20">
        <nav aria-label="Page navigation example">
          <Pagination
            key={Date.now()}
            id={Date.now()}
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={records.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)} />
        </nav>
      </div></>
  )
}

export default ProductList;