import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getProducts as listProducts } from '../../redux/actions/productActions';
import { convertToProductSearchData } from '../../utils/utils.function';
import { useHistory } from "react-router-dom";

function Search() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const getProducts = useSelector(state => state.getProducts);
  const { products } = getProducts;
  const seachItems = convertToProductSearchData(products);

  useEffect(() => {
    dispatch(listProducts())
  }, [dispatch])

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    navigate.push(`/product/${item.id}`)
    console.log(item)

  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        {item.name}
      </>
    )
  }

  return (
    <div style={{ width: "300px", marginBottom: "20px", height: '20px'}}>
      <ReactSearchAutocomplete
        items={seachItems}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        autoFocus
        formatResult={formatResult}
        showIcon={true}
        styling={{borderRadius: '0', height: '20', zIndex: 999999}}
      />
    </div>
  )
}

export default Search;