import '../css/Main.css'
import Image from 'react-bootstrap/Image';
import { useSelector } from 'react-redux'

function Bottomheader() {
  const cart = useSelector(state => state.cart);
  const {cartItems} = cart;
  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0)
  }
    return (
      <div className="header-bottom header-bottom-bg-color sticky-bar">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            <div className="logo logo-width-1 d-block d-lg-none">
              <a href="/">
                <Image src="../images/theme/logo.svg" />
              </a>
            </div>
            <div className="header-nav d-none d-lg-flex">
            </div>
            <div className="header-action-icon-2 d-block d-lg-none">
              <div className="burger-icon burger-icon-white">
                <span className="burger-icon-top"></span>
                <span className="burger-icon-mid"></span>
                <span className="burger-icon-bottom"></span>
              </div>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <a className="mini-cart-icon" href="/cart">
                    <Image src="./images/theme/icons/icon-cart.svg" />
                    <span className="pro-count white">{getCartCount()}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Bottomheader;