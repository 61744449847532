import Listproducts from '../../admin/products/listproducts';
import NewProduct from "../../screens/Admin/NewProduct";
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";

function AdminProducts() {
  return (
    <div className="tab-pane" id="products" role="tabpanel" aria-labelledby="products-tab">
      <div className='row my-3'>
        <div className='col-3'>
          <NewProduct />
        </div>
        <div className='col-3'>
        <Link to={`/admin/products/download`}>
          <Button variant="outline-light" size="lg">
            Download
          </Button>
        </Link>
        </div>
      </div>
      <div className="row">
        <Listproducts />
      </div>
    </div>
  )
}
  
  export default AdminProducts;