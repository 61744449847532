import { useState  } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../redux/actions/userAction"

function ForgotPassword() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      e.preventDefault();
      dispatch(
        updatePassword({
          email,
          password,
        })
      );
    }
  };

  return (
    <div className="page-content pt-50 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 col-md-12 m-auto">
            <div className="row">
              <div className="heading_s1">
                <img className="border-radius-15" src="../images/page/reset_password.svg" alt="" />
                <h2 className="mb-15 mt-15">Set new password</h2>
                <p className="mb-30"></p>
              </div>
              <div className="col-lg-6 col-md-8">
                <div className="login_wrap widget-taber-content background-white">
                  <div className="padding_eight_all bg-white">
                    <form method="post">
                      <div className="form-group">
                        <input 
                          type="text"
                          required=""
                          name="email"
                          placeholder="Username or Email *"
                          onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          required=""
                          name="email"
                          placeholder="Password *"
                          onChange={(e) => setPassword(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          required=""
                          name="email"
                          placeholder="Confirm you password *"
                          onChange={(e) => setConfirmPassword(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-heading btn-block hover-up"
                          name="login"
                          onClick={handleSubmit}>Reset password</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-50">
                <h6 className="mb-15">Password must:</h6>
                <p>Be between 6 and 20 characters</p>
                <p>Include at least tow of the following:</p>
                <ol className="list-insider">
                  <li>An uppercase character</li>
                  <li>A lowercase character</li>
                  <li>A number</li>
                  <li>A special character</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;