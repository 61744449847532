import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories as listCategories } from '../../redux/actions/categoryActions'

function Popularproduct() {
  const dispatch = useDispatch();
  const getCategories = useSelector(state => state.getCategories)
  const { categories } = getCategories;
  const selectedCategories = categories.filter(category => category.popular);

  useEffect(() =>{
    dispatch(listCategories())
  }, [dispatch]);

  return (
    <>
    { selectedCategories.length> 0 ? (
      <div className="section-title style-2 wow animate__animated animate__fadeIn animated">
      <h3>Popular Products</h3>
      <ul className="nav nav-tabs links" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a className="nav-link active category-button"
            href={'/products'}
            id="nav-tab-one"   
            type="button"
            role="tab"
            aria-controls="tab-one"
            aria-selected="true"
            
            >All</a>
        </li>
        { selectedCategories.map(category => (
          <li className="nav-item" role="presentation" key={category._id}>
            <a className="nav-link active category-button"
              href={`products?categoryId=${category._id}`}
              id="nav-tab-one"
              type="button"
              role="tab"
              aria-controls="tab-one"
              aria-selected="true"
              >{category.name}</a>
          </li>
        ))}
      </ul>
    </div>
    ) : (
      <div></div>
    )}
  </>
  )
}

export default Popularproduct;