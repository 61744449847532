import '../../screens/ProductScreen.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Actions
import {getProductDetails} from '../../redux/actions/productActions'
// import {addToCart} from '../../redux/actions/cartActions'

const ProductShow = ({match, history}) => {
  // const [qty, setQty] = useState(1)
  // const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const productDetails = useSelector(state => state.getProductDetails)
  const {loading, error, product} = productDetails

  useEffect(() => {
    if (product && match.params.id !== product._id) {
      dispatch(getProductDetails(match.params.id))
    }
  }, [dispatch, match, product])

  const cancelPage = () => {
    history.goBack();
  }

  // const addToCartHandler = () => {
  //   if (user.userInfo.isLogin) {
  //     dispatch(addToCart(product._id, qty))
  //     history.push(`/cart`)
  //     return
  //   } else {
  //     alert('You need to first login.')
  //   }
  // }
  const imageUrl = product && product.imageUrl ? product.imageUrl : '../../logo192.png'
  return (
    <div className="container">
    {/* <SideNav/>
    <div className="productscreen content"> */}

      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <>
          <div className="productscreen__left">
            <div className="left__image">
              <img src={imageUrl} alt={product.name} />
            </div>
            <div className="left__info">
              <p className="left__name">{product.name}</p>
              <p>Price: ${product.price}</p>
              <p>Short Description: <div dangerouslySetInnerHTML={{__html: product.descriptionShort}} />
              </p>
              <p>Long Description:  <div dangerouslySetInnerHTML={{__html: product.descriptionLong}} /></p>
              <p>Additional Info:  <div dangerouslySetInnerHTML={{__html: product.additionalInfo}} /></p>
              <p>Size: {product.size}</p>
              <p>Weight: {product.weight}</p>
              <p>Color: {product.color}</p>
              <p>
                Status:
                <span>
                  {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                </span>
              </p>
              <p>
                Qty: {product.countInStock}
                {/* <select value={qty} onChange={e => setQty(e.target.value)}>
                  {[...Array(product.countInStock).keys()].map(x => (
                    <option key={x + 1} value={x + 1}>
                      {x + 1}
                    </option>
                  ))}
                </select> */}
              </p>
              <p>
                <Link className="btn mx-2 py-3" to="/" onClick={cancelPage}>
                  Back
                </Link>
              </p>
            </div>
          </div>
          {/* <div className="productscreen__right">
            <div className="right__info">
              <p>
                Price:
                <span>${product.price}</span>
              </p>
              <p>
                Status:
                <span>
                  {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                </span>
              </p>
              <p>
                Qty
                <select value={qty} onChange={e => setQty(e.target.value)}>
                  {[...Array(product.countInStock).keys()].map(x => (
                    <option key={x + 1} value={x + 1}>
                      {x + 1}
                    </option>
                  ))}
                </select>
              </p>
              <p>
                <button type="button" onClick={addToCartHandler}>
                  Add To Cart
                </button>
              </p>
            </div>
          </div> */}
        </>
      )}
    </div>
    // </div>
  )
}

export default ProductShow
