import * as actionTypes from '../constants/reviewConstants';
import {Api} from '../../utils/Api';

export const addReview = (review) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_REVIEW,
    payload: {
      name: review.name,
      comment: review.comment,
      email: review.email,
      website: review.website,
      rating: review.rating,
      productId: review.productId,
      userId: review.userId
    },
  })

  Api.postRequest('/api/reviews', review).then(() => {
    dispatch(getReviews());
    dispatch(getReviewsByProductId(review.productId));
  })
}

export const getReviews = () => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_REVIEWS_REQUEST})
    const {data} = await Api.getRequest('/api/reviews')
    dispatch({
      type: actionTypes.GET_REVIEWS_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
    type: actionTypes.GET_REVIEWS_FAIL,
    payload:
        error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    })
  }
}

export const getReviewsByProductId = (productId) => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_REVIEWS_BY_PRODUCT_ID_REQUEST})
    const {data} = await Api.getRequest(`/api/reviews/product/${productId}`)
    dispatch({
      type: actionTypes.GET_REVIEWS_BY_PRODUCT_ID_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
    type: actionTypes.GET_REVIEWS_BY_PRODUCT_ID_FAIL,
    payload:
        error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    })
  }
}
  