function TestDetails({product}) {
  return (
    <div className="attr-detail attr-size mb-30">
      <ul className="list-filter2 size-filter font-small flex justify text-center">
        { product.uspAssets.map((item, index) => (
          <li key={index}>
            <img src={item} alt="icon" id="xyz"  width="65" height="65"/>
            <h6>Lab-tested</h6>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TestDetails;