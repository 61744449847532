import './Advertisements.css'
// import SideNav from '../SideNav'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAdvertisementDetails } from '../../redux/actions/advertisementActions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function AdvertisementShow({match, history}) {
  // const navigate = useHistory()
  const dispatch = useDispatch();
  const advertisementDetails = useSelector(state => state.getAdvertisementDetails);
  const {loading, error, advertisement} = advertisementDetails

  useEffect(() => {
    if (advertisement && match.params.id !== advertisement._id) {
      dispatch(getAdvertisementDetails(match.params.id))
    }
  }, [dispatch, match, advertisement])

  const cancelPage = () => {
    history.goBack();
  };

  const imageUrl = advertisement && advertisement.imageUrl ? advertisement.imageUrl : '../../logo192.png'
  
  return (
    <div className="container">
      {/* <SideNav/> */}
      {/* <div className='styled-created-product content'> */}
      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error}</h2>
      ) : ( 
        <div className="productscreen__left">
        <div className="left__image">
          <img src={imageUrl} alt={advertisement.name} />
        </div>
        <div className="left__info">
          <p className="left__name"> <b>Description</b>: {advertisement.description}</p>
          <p>{advertisement.screenType}</p>
          <p>
            <Link className="btn mx-2 py-3" to="" onClick={(cancelPage)}>
              Back
            </Link>
          </p>
        </div>
      </div>
      )}
      </div>
    // </div>
  )
}

export default AdvertisementShow;