import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tracker from '../Admin/Tracker'
import { getOrderTrackersWithOrderId } from '../../redux/actions/orderTrackerActions';
import '../Admin/Admin.css';

const Statuses = [
  {value: 'placed', text: 'Placed'},
  {value: 'processed', text: 'Processed'},
  {value: 'shipped', text: 'Shipped'},
  {value: 'delivered', text: 'Delivered'},
];

function TrackOrder({order}) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const getOrderTrackers = useSelector(state => state.getOrderTrackerWithOrderId);
  const { orderTrackers } = getOrderTrackers;
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getOrderTrackersWithOrderId(order.id));
  }, [dispatch, order]);

  const completedSteps = () => {
    return orderTrackers ? orderTrackers.map((tracker) => tracker.status ) : [] ;
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className='btn btn-secondary btn-sm print ms-2'>
        Track
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Order Status</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-60">
                  <div className='row '>
                    <Tracker statuses={Statuses} steps={completedSteps()} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TrackOrder;