import CustomPaging from "./CustomPaging";
import DetailsInfo from "./DetailsInfo";
import DescriptionTab from "./DescriptionTab";
import AdditionalTab from "./AdditionalInfoTab";
import ProductInfoTab from "./ProductInfoTab";
import ReviewTab from "./ReviewTab";
import RelatedProducts from "./RelatedProducts";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getReviewsByProductId as listReviews } from "../../redux/actions/reviewActions"
import './Products.css';

function ProductInfo({product}) {
  const dispatch = useDispatch();
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const { reviews } = getReviews;

  useEffect(() => {
    dispatch(listReviews(product._id))
  }, [dispatch, product._id])

  return(
    <div>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/products" rel="nofollow"><i className="fi-rs-home mr-5"></i>Products</a>
            <span></span>
            <a href={`/products?categoryId=${product.categoryId._id}`}>{product.categoryId.name}</a> 
            <span></span> {product.name}
          </div>
        </div>
      </div>
      <div className="container mb-30">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mb-50 mt-30">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="detail-gallery">
                      { product.assets.length > 0 &&
                        <CustomPaging assets={product.assets}/>
                      }
                    </div>
                  </div>
                  <DetailsInfo product={product} />
                </div>
                <Tabs
                  defaultActiveKey="description"
                  id="product-info"
                  className="mb-3"
                  justify
                >
                  <Tab eventKey="description" title="Health Benefit">
                    <DescriptionTab product={product}/>
                  </Tab>
                  <Tab eventKey="additional-info" title="Additional info">
                    <AdditionalTab product={product} />
                  </Tab>
                  <Tab eventKey="product-info" title="Product Info">
                    <ProductInfoTab product={product}/>
                  </Tab>
                  <Tab eventKey="reviews" title={`Reviews (${reviews.length})`}>
                    <ReviewTab product={product} />
                  </Tab>
                </Tabs>
                <RelatedProducts />
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ProductInfo;