

function AdminSales() {
    return (
      <div className="tab-pane" id="sales" role="tabpanel" aria-labelledby="sales-tab">
        <div className="row">
  
        </div>
      </div>
    )
  }
  
  export default AdminSales;