function OurStory() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <section className="row align-items-center mb-50">
              <div className="col-lg-6">
                <img src="../images/blog/about-1.png" alt="" className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" />
              </div>
              <div className="col-lg-6">
                <div className="pl-25">
                  <h2 className="mb-30">Welcome to Dhenki!</h2>
                  <p className="mb-25">Dhenki started with an honest objective to provide 100% clean, unadulterated country variety organic food solutions including edible oils, rice, millets, spices and pulses.</p>
<p className="mb-25">In today's world, most of the food items are chemically processed in large factory setups with various preservatives, harmful colouring and essence chemicals, resulting severe health issues for all of us. </p>
                  <p className="mb-50">Dhenki as a food start-up is looking for solutions to the food adulteration crisis with clean and minimally processed Indian food. </p>
                </div>
              </div>
            </section>
            <section className="text-center mb-50">
              <h2 className="title style-3 mb-40">How does Dhenki provide pure food items?</h2>
              <div className="row">
                <p className="mb-25">One of our focus area is the edible oil segment. Edible oil is purposefully adulterated and over processed in large factory setups, which is the main cause for high cholesterol, high pressure, fatty liver, obesity and heart diseases. We are providing minimally processed, unadulterated, pure edible oil from country variety oil seeds which helps us to stay healthy. To address this issue Dhenki has set up wood pressed cold pressed Oil extracting machines, a first in eastern zone. For all food categories like rice, millets, spices and pulses, we are ensuring pure products.</p>
                <p className="mb-25">Our food quality experts (Technologists from IIT Kharagpur, Jadavpur University and Bidhan Chandra Krishi Viswavidyalaya) ensure, </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                  <h5 className="mb-30">1. Honest sourcing of pure food </h5>
                </div>
                <div className="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                  <h5 className="mb-30">2. Minimal processing to retain nutrition value </h5>
                </div>
                <div className="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                  <h5 className="mb-30">3. 100% pure unadulterated food </h5>
                </div>
              </div>       
            </section>
            <section className="text-center mb-50">
              <h2 className="title style-3 mb-40">Dhenki Pure Products</h2>
              <div className="row">
                <p className="mb-25">Dhenki offers wide range of pure and natural food products. We follow ancient Indian wisdom about minimal processing techniques which enables to retain most of the nutrient content in the food. All the items are ethically sourced as pure local/ country varieties, produced organically.</p>
                <p className="mb-25">Dhenki is the only company in entire India, which has the largest range of clean and cold pressed Oils, like mustard, groundnut, coconut, sesame, flax seed, sunflower, safflower, black cumin seed, hibiscus, almond, onion, apricot, castor oils and others. </p>
                <p className="mb-25">Dhenki has entire range of millets, traditional and arsenic free Rice Verities, Unpolished Dal/Pulses, Pure Jaggery (Date, Palm and Sugarcane), 100% Pure Desi Ghee & Honey, Hand pounded & unadulterated Spices etc. </p>
              </div>
            </section>
            <section className="text-center mb-50">
              <h2 className="title style-3 mb-40">Dhenki’s Purity Assurance</h2>
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-24">
                          <div className="featured-card">
                              <img src="../images/theme/icons/pure.png" alt="" />
                              <h4>100 % pure</h4>
                              <p>We strive to provide 100% pure & natural food products to our valued customers every time and hence we conduct over 25 different checks for each of our products before its being shipped.</p>
                              
                          </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                          <div className="featured-card">
                              <img src="../images/theme/icons/product_range.png" alt="" />
                              <h4>Wide Product Range</h4>
                              <p>Dhenki provides the widest range of 100 % Wood-pressed, Cold-pressed edible oils, Pure Jaggery items (Date, Palm, Sugarcane), Unpolished Dal/Pulses, 100% Pure Desi Ghee & Honey, Hand pounded & unadulterated Spices.</p>
                          </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                      <img src="../images/theme/icons/nutrition.png" alt="" />
                      <h4>100 % Nutrition Value </h4>
                      <p>Dhenki products are unprocessed or minimally processed, hence it retains almost all it nutrient content.</p>
                      
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                    <div className="featured-card">
                        <img src="../images/theme/icons/ethics.png" alt="" />
                        <h4>Ethical Sourcing</h4>
                        <p>Dhenki ensures that food products are sourced from deep rural organic farm lands, directly from farmers or farmer groups with mutual respect and care for farmers and the environment.</p>
                        
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                    <div className="featured-card">
                        <img src="../images/theme/icons/sustainability.png" alt="" />
                        <h4>Sustainable and Climate Conscious Sourcing</h4>
                        <p>We strive to minimize negative environmental impacts by reducing pollution, conserving water, protecting biodiversity, and enhancing soil health, thereby ensuring farms remain profitable and contribute to thriving rural communities.</p>
                        
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                    <div className="featured-card">
                        <img src="../images/theme/icons/empower_farmer.png" alt="" />
                        <h4>Empowerment of farmers</h4>
                        <p>We strongly believe that if farmers can be empowered both technically and economically, they can offer better food products all throughout the year</p>
                        
                    </div>
                </div>
              </div>
            </section>
            <section className="row align-items-center mb-50">
              <div className="row mb-50 align-items-center">
                <div className="col-lg-7 pr-30">
                  <img src="../images/blog/about-5.png" alt="" className="mb-md-3 mb-lg-0 mb-sm-4" />
                </div>
                <div className="col-lg-5">
                  <h1 className="heading-1 mb-40">Your Honest Partner for Weekly Grocery - Dhenki</h1>
                  <p className="mb-30">Thus Dhenki is a unique solution for health conscious customers who are looking for organic, healthy products from a single window at affordable prices. Trust us for your weekly/ monthly grocery and protect your health.</p>
<p className="mb-30">Dhenki products are available through our website and mobile app.</p>
                    
                </div>
              </div>     
            </section>
          </div>
        </div>
      </div>
   </div>
  )
}

export default OurStory;