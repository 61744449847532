import { useDispatch } from "react-redux";
import { addSubscribe } from "../../redux/actions/subscriptionActions";
import { useState } from "react";

function Subscribe() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addSubscribe({
        email,
      })
    );
  };

  return (
    <section className="newsletter mb-15">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="position-relative newsletter-inner">
              <div className="newsletter-content">
                <h2 className="mb-20">
                  Stay home & get your daily <br />
                  needs from our shop
                </h2>
                <p className="mb-45">Start You'r Shopping with <span className="text-brand">Dhenki.in</span></p>
                  <form className="form-subcriber d-flex" onSubmit={handleSubmit} style={{width: "68%"}}>
                    <div className='row' >
                      <div className='col-9'>
                        <input
                          type="email"
                          placeholder="Your emaill address"
                          style={{margin: "10px 0 0 0"}}
                          onChange={(e) => setEmail(e.target.value)}/>
                      </div>
                      <div className='col-3'>
                        <button className="btn" type="submit" style={{paddingBottom: "14px" }}>Subscribe</button>
                      </div>
                    </div>
                  </form>
                </div>
              <img src="../images/banner/banner-9.png" alt="newsletter" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscribe;