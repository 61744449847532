import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './components/css/Main.css'
import {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
// Components
import Header from './components/js/Header';
import Footerfeatured from "./components/js/Footerfeatured";
import SideDrawer from './components/SideDrawer';
import Backdrop from './components/Backdrop';

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import {useDispatch} from 'react-redux';
import {fetchCart} from './redux/actions/cartActions';
import {fetchWishlist} from './redux/actions/wishlistActions';
import {setUserDeatils} from './redux/actions/userAction';

// Screens
import HomeScreen from './screens/HomeScreen';
import DisplayProduct from './screens/Products/show';
import CartPage from './screens/Cart/index'
import Checkout from "./screens/Cart/Checkout";
import WishlistScreen from './screens/WishlistScreen';
import SignUp from './screens/SignUp';
import SignIn from './screens/SignIn';
import Prodducts from './screens/Products/index'
import MyDashboard from './screens/Account/dashboard';
import CheckoutSuccess from './screens/CheckoutSuccess';
import OrderScreen from './screens/OrderScreen';
import AdminHome from "./screens/Admin/home";
import ForgotPassword from "./screens/SignIn/ForgotPassword";
import Dashboard from './admin/Dashboard';
import Products from './admin/products/index';
import Orders from './admin/orders/index';
import OrderProductScreen from './screens/Account/orderProduct'
import Users from './admin/users/index';
import Categories from './admin/categories/index';
import CategoryNew from './admin/categories/new';
import CategoryShow from './admin/categories/show';
import EditCategory from './admin/categories/edit';
import ProductShow from './admin/products/show';
import ProductNew from './admin/products/new';
import EditProduct from './admin/products/edit';
import Advertisement from './admin/advertisements/index';
import AdvertisementNew from './admin/advertisements/new';
import EditAdvertisement from './admin/advertisements/edit';
import AdvertisementShow from './admin/advertisements/show';
import Success from './screens/Cashfree/Success';
import Failure from './screens/Cashfree/Failure';
import AboutUs from "./screens/AboutUs";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import ShippingPolicy from "./screens/ShippingPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import TermsConditions from "./screens/TermsCondition";
import Blog from "./screens/Blog";
import OurStory from "./screens/OurStory";
import KnowYourOil from "./screens/KnowYourOil";
import OrderDetails from "./screens/Account/orderDetails"
import OrderPlaced from "./screens/Cashfree/OrderPlaced";
import UserDetails from "./screens/Users/Show";

function App() {
  const [sideToggle, setSideToggle] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserDeatils());
  }, [dispatch]);

  const user = useSelector((state) => state.user); 

  useEffect(() => {
    user.userInfo.isLogin && dispatch(fetchCart());
    user.userInfo.isLogin && dispatch(fetchWishlist());
  }, [dispatch, user.userInfo.isLogin]);

  return (
    <div>
      <Router>
        <Header />
        <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
        <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
        <main className="main pages">
          <ToastContainer />
          <Switch>
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/our-story" component={OurStory} />
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/product/:id" component={DisplayProduct} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/checkout-success" component={CheckoutSuccess} />          
            <Route exact path="/wishlist" component={WishlistScreen} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signin" component={SignIn} >
              {localStorage.getItem('E_COMMERCE_TOKEN') ? <Redirect to="/" /> : <Route path="/signin" component={SignIn}/>}
            </Route>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/products" component={Prodducts} />
            <Route exact path='/aboutUs' component={AboutUs} />
            <Route exact path='/privacyPolicy' component={PrivacyPolicy} />
            <Route exact path='/shippingPolicy' component={ShippingPolicy} />
            <Route exact path='/refundPolicy' component={RefundPolicy} />
            <Route exact path='/termsAndConditions' component={TermsConditions} />
            <Route exact path='/success' component={Success} />
            <Route exact path='/order-placed' component={OrderPlaced} />
            <Route exact path='/failure' component={Failure} />
            <Route exact path='/know-your-oil' component={KnowYourOil} />
            { localStorage.getItem('E_COMMERCE_TOKEN') ? (
              <>
                <Route exact path="/users/:id" component={UserDetails} />
                <Route exact path="/orders" component={OrderScreen} />
                <Route exact path="/orderProducts/:id" component={OrderProductScreen} />
                <Route exact path="/orders/:id" component={OrderDetails} />
                <Route exact path="/my-account/:id" component={MyDashboard} />
                <Route exact path="/admin" component={AdminHome} />
                <Route exact path="/admin/dashboard" component={Dashboard} />
                <Route exact path="/admin/products" component={Products} />
                <Route exact path="/admin/products/new" component={ProductNew} />
                <Route exact path="/admin/products/:id" component={ProductShow} />
                <Route exact path="/admin/products/edit/:id" component={EditProduct} />
                <Route exact path="/admin/orders" component={Orders} />
                <Route exact path="/admin/categories" component={Categories} />
                <Route exact path="/admin/categories/new" component={CategoryNew} />
                <Route exact path="/admin/categories/:id" component={CategoryShow} />
                <Route exact path="/admin/categories/edit/:id" component={EditCategory} />
                <Route exact path="/admin/users" component={Users} />
                <Route exact path="/admin/advertisements" component={Advertisement} />
                <Route exact path="/admin/advertisements/new" component={AdvertisementNew} />
                <Route exact path="/admin/advertisements/edit/:id" component={EditAdvertisement} />
                <Route exact path="/admin/advertisements/:id" component={AdvertisementShow} />
              </>
            ) : (<Redirect to="/signin" />)}

          </Switch>
        </main>       
        <Footerfeatured />
      </Router>
    </div>
  )
}

export default App
