import * as actionTypes from '../constants/categoryConstants'
import {Api} from '../../utils/Api';
import { toast } from "react-toastify";

export const addCategory = (category) => async dispatch => {
  Api.postRequest('/api/categories', category).then(() => {
    dispatch(getCategories());
  });
  toast.success("Category is created", {
    position: "top-right",
  });
}

export const updateCategory = (id, category) => async dispatch => {
  Api.putRequest(`/api/categories/${id}`, category).then(() => {
    dispatch(getCategories());
  });
  toast.success("Category is updated", {
    position: "top-right",
  });
}

export const getCategories = () => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_CATEGORIES_REQUEST})    
    const {data} = await Api.getRequest('/api/categories')
    dispatch({
      type: actionTypes.GET_CATEGORIES_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
      dispatch({
        type: actionTypes.GET_CATEGORIES_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCategoryDetails = id => async dispatch => {
    try {
      dispatch({type: actionTypes.GET_CATEGORY_DETAILS_REQUEST})
  
      const {data} = await Api.getRequest(`/api/categories/${id}`)
      const p = JSON.parse(data)
      dispatch({
        type: actionTypes.GET_CATEGORY_DETAILS_SUCCESS,
        payload: {
          ...p,
        },
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_CATEGORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
  export const removeCategoryDetails = (id) => async dispatch => {
    try {
      await Api.DeleteRequest(`/api/categories/${id._id}`)
      .then((response) => {
        dispatch(getCategories());
        toast.success("Category is deleted", {
          position: "bottom-left",
        });
      });
    } catch (error) {
      console.log(error)
    }
  }
  
