// import '../css/Footerfeatured.css'
import Image from 'react-bootstrap/Image'
import Subscribe from './subscribe'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories as listCategories } from '../../redux/actions/categoryActions'
import { Code } from 'react-content-loader';

const Footerfeatured = () => {
  const MyCodeLoader = () => <Code />
  const popularCategories= [
    "Edible Oil & Ghee",
    "Honey",
    "Healthy Rice",
    "Millets"
  ]

  const dispatch = useDispatch()
  const getCategories = useSelector(state => state.getCategories)
  const { categories, loading, error } = getCategories;
  const filteredCategories = categories.filter(category => popularCategories.includes(category.name));

  useEffect(() =>{
    dispatch(listCategories())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <MyCodeLoader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <footer className="main">
          <Subscribe />
          <section className="featured section-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
                  <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                    <div className="banner-icon">
                      <Image src="/images/theme/icons/icon-1.svg" rounded />
                    </div>
                    <div className="banner-text">
                      <h3 className="icon-box-title">Best prices & offers</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                  <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                    <div className="banner-icon">
                      <Image src="/images/theme/icons/icon-2.svg" rounded />
                    </div>
                    <div className="banner-text">
                      <h3 className="icon-box-title">Minimum delivery charge</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                  <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                    <div className="banner-icon">
                      <Image src="/images/theme/icons/icon-3.svg" rounded />
                    </div>
                    <div className="banner-text">
                      <h3 className="icon-box-title">Great daily deal</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                  <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                    <div className="banner-icon">
                      <Image src="/images/theme/icons/icon-4.svg" rounded />
                    </div>
                    <div className="banner-text">
                      <h3 className="icon-box-title">Wide variety</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                  <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                    <div className="banner-icon">
                      <Image src="/images/theme/icons/icon-5.svg" rounded />
                    </div>
                    <div className="banner-text">
                      <h3 className="icon-box-title">Easy returns</h3>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </section>
          <section className="section-padding footer-mid footer-mid-img">
            <div className="container pt-15 pb-20">
              <div className="row">
                <div className="col">
                  <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0">
                    <div className="logo mb-30">
                      <a href="/" className="mb-15">
                        <Image src="/images/theme/logo.svg" rounded />
                      </a>
                    </div>
                    <ul className="contact-infor">
                      <li>
                        <Image src="/images/theme/icons/icon-location.svg" rounded />
                        <strong>Address: </strong>
                        <span>24, Metropolitan Co-Op. HSG, SCY. Ltd, Dhapa, South 24 Parganas Kolkata, West Bengal, India – 700105</span>
                      </li>
                      <li>
                        <Image src="/images/theme/icons/icon-contact.svg" rounded />
                        <strong>Call Us:</strong><span>(+91) - 82403 79055</span>
                      </li>
                      <li>
                        <Image src="/images/theme/icons/icon-email-2.svg" rounded />
                        <strong>Email:</strong><span>dhenkifoods@gmail.com</span>
                        </li>
                      <li>
                        <Image src="/images/theme/icons/icon-clock.svg" rounded />
                        <strong>Hours:</strong><span>10:00 - 18:00, Mon - Sat</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-link-widget col">
                  <h4 className="widget-title">Company</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li><a href="/aboutUs">About Us</a></li>
                    <li><a href="/privacyPolicy">Privacy Policy</a></li>
                    <li><a href="/refundPolicy">Refund Policy</a></li>
                    <li><a href="/shippingPolicy">Shipping Policy</a></li>
                    <li><a href="/termsAndConditions">Terms &amp; Conditions</a></li>
                    <li><a href="/contuct-us">Contact Us</a></li>
                  </ul>
                </div>
                <div className="footer-link-widget col">
                  <h4 className="widget-title">Account</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                      <li><a href="/signin">Sign In</a></li>
                      <li><a href="/cart">View Cart</a></li>
                      {/* <li><a href="/wishlist">My Wishlist</a></li> */}
                      <li><a href="/orders">Track My Order</a></li>
                      <li><a href="/my-account">Shipping Details</a></li>
                  </ul>
                </div>       
                <div className="footer-link-widget col">
                  <h4 className="widget-title">Popular</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                  { filteredCategories.map(category => (
                    <li key={category._id}><a href={`/categories${category._id}`}>{category.name}</a></li>
                  ))}
                  </ul>
                </div>
                <div className="footer-link-widget widget-install-app col">
                  <p className="mb-20">Secured Payment Gateways</p>
                  <Image src="/images/theme/payment-method.png" rounded className="wow fadeIn animated" style={{width: "60%"}}/>
                </div>
              </div>
            </div>
          </section>
          <div className="container pb-30">
            <div className="row align-items-center">
              <div className="col-12 mb-30">
                <div className="footer-bottom"></div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <p className="font-sm mb-0">&copy; 2024, <strong className="text-brand">Dhenki</strong> - All rights reserved</p>
              </div>
              <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                <div className="hotline d-lg-inline-flex mr-30">
                  <img src="/images/theme/icons/phone-call.svg" alt="hotline" />
                  <p>+91 8240379055<span>Working 8:00 - 22:00; 24/7 Support</span></p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                <div className="mobile-social-icon">
                  <h6>Follow Us</h6>
                  <a href="https://www.facebook.com/dhenki.in?mibextid=ZbWKwL"><img src="/images/theme/icons/icon-facebook-white.svg" alt="" /></a>
                  <a href="https://www.linkedin.com/company/dhenki-foods-pvt-ltd/"><img src="/images/theme/icons/linkedin icon.png" alt="" /></a>
                  <a href="https://www.instagram.com/dhenkifoods?utm_source=qr&igsh=OWpqa3pqbzkzc2du"><img src="/images/theme/icons/icon-instagram-white.svg" alt="" /></a>
                  <a href="https://youtube.com/@DhenkiFoods?si=ryECMQHUpEXISFOq"><img src="/images/theme/icons/icon-youtube-white.svg" alt="" /></a>
                </div>
                {/* <p className="font-sm">Up to 15% discount on your first subscribe</p> */}
              </div>
            </div>
          </div>
        </footer>
      )
    }
    </>
  )
}

export default Footerfeatured;
