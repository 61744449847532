import '../../components/css/Main.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'
import Image from 'react-bootstrap/Image'
import { getReviewsByProductId } from "../../redux/actions/reviewActions"
import { convertToTotalRating } from "../../utils/utils.function";

function RelatedProductItem({product}) {
  const dispatch = useDispatch();
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const { reviews } = getReviews;
  const totalRating = convertToTotalRating(reviews);
  const imageUrl = product.assets && product.assets.length > 0 ? product.assets[0]: '../logo192.png'
  useEffect(() => {
    dispatch(getReviewsByProductId(product._id))
  }, [dispatch, product._id]);

  return (
  <div className="col-lg col-12">
    <div className="product-cart-wrap hover-up mx-2">
      <div className="product-img-action-wrap">
        <div className="product-img product-img-zoom">
          <a href={`/product/${product._id}`} tabIndex="0">
            <Image className="default-img" src={`${imageUrl}`} />
            <Image className="default-img" src={`${imageUrl}`} />
          </a>
        </div>
        <div className="product-action-1">
          <a
            aria-label="Quick view"
            href={`/product/${product._id}`}
            className="action-btn small hover-up"
            data-bs-target="#quickViewModal">
            <i className="fi-rs-search"></i>
          </a>
        </div>
        <div className="product-badges product-badges-position product-badges-mrg">
        </div>
      </div>
      <div className="product-content-wrap">
        <h2><a href={`/product/${product._id}`} tabIndex="0">{product.name}</a></h2>
        <div className="product-detail-rating">
          <div className="product-rate-cover text-end">
            <div className="product-rate d-inline-block">
              <div className="product-rating" style={{ width: `${totalRating/5 * 100}%` }}></div>
            </div>
          </div>
        </div>
        <div className="product-price">
          <span style={{fontSize: "16px"}}>M.R.P: &#8377; {product.price}</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RelatedProductItem;