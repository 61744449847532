import * as actionTypes from '../constants/cartConstants'

const CART_INITIAL_STATE = {
  cartItems: localStorage.getItem('cartList')
  ? JSON.parse(localStorage.getItem('cartList'))
  : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
}
// const cartLocalStorage = JSON.parse(localStorage.getItem("cartList") || "[]");

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      const item = action.payload;
      const existItem = state.cartItems.find(x => x.productId === item.productId)
      if (existItem) {
        const cartItems = state.cartItems.map(x => x.productId === existItem.productId ? item : x);
        localStorage.setItem("cartList", JSON.stringify([...cartItems]));
        return {
          ...state,
          cartItems: cartItems,
        }
      } else {
        localStorage.setItem("cartList", JSON.stringify([...state.cartItems, item]));
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        }
      }
    case actionTypes.REMOVE_FROM_CART:
      const nextCartItem = state.cartItems.filter(x => x.productId !== action.payload);
      localStorage.setItem("cartList", JSON.stringify([...nextCartItem]));
      return {
        ...state,
        cartItems: state.cartItems.filter(x => x.productId !== action.payload),
      }
    case actionTypes.FETCH_MY_CART:
      const items = action.payload.carts;
      const existItems = state.cartItems;
      if (existItems && existItems.length > 0) {
        localStorage.setItem("cartList", JSON.stringify([...existItems]));
        return {
          ...state,
          cartItems: [...existItems],
        }
      } else {
        localStorage.setItem("cartList", JSON.stringify([...items]));
        return {
          ...state,
          cartItems: [...item],
        }
      }
    case actionTypes.CLEAR_MY_CART:
      localStorage.setItem("cartList", JSON.stringify([]));
      return {
        ...state,
        cartItems: [],
      }
    case actionTypes.GET_CART_TOTAL:
      return {
        ...state,
        cartTotalQuantity: [...action.payload.cartTotalQuantity],
        cartTotalAmount: [...action.payload.cartTotalAmount],
      }
    default:
      return state
  }
}
