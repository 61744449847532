import TestDetails from "./LabTested";
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from "../../redux/actions/cartActions";
import { getReviewsByProductId } from "../../redux/actions/reviewActions"
import { convertToTotalRating } from "../../utils/utils.function";
import { toast } from "react-toastify";

const cartLocalStorage = JSON.parse(localStorage.getItem("cartList") || "[]");

function DetailsInfo({product}) {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [productPrice, setProductPrice] = useState(product.price)
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const { reviews } = getReviews
  const totalRating = convertToTotalRating(reviews)
  const defaultSku = product.sku.filter((item)=> item.defaultSku);
  const defaultSkuUnitValue = defaultSku[0] ? `${defaultSku[0].value} ${defaultSku[0].unit}` : ''
  const [unit, setUnit] = useState(defaultSkuUnitValue)
  const cartItem = {
    productId: product._id,
    productName: product.name,
    desc: product.descriptionShort,
    imageUrl: product.assets && product.assets.length > 0 ? product.assets[0]: '../logo192.png',
    price: productPrice,
    countInStock: product.countInStock,
    qty: qty,
    unit: unit,
  }
  const [cartList, setCartList] = useState(cartLocalStorage)

  const addToCartHandler = () => {
    if (Number(product.countInStock) === 0) {
      toast.success("Product is out of stock now", {
        position: "top-right",
      });
    } else {
      setCartList([...cartList, cartItem]);
      const cart = {
        productId: product._id,
        productName: product.name,
        imageUrl: product.imageUrl ? product.imageUrl: '../logo192.png',
        price: productPrice,
        countInStock: product.countInStock,
        qty: qty,
        unit: unit,
      }
      dispatch(addToCart(cart, qty, unit, productPrice));
      return
    }
  }

  useEffect(() => {
    dispatch(getReviewsByProductId(product._id))
  }, [dispatch, product._id]);

  const unitValue = (sku) => {
    if (sku.length > 0) {
      return ['grm','ml'].includes(sku[0].unit) ? (Number(sku[0].value) / 1000) : Number(sku[0].value)
    } else {
     return
    }
  }

  useEffect(() => {
    const selectedSku = product.sku.filter((item)=> `${item.value} ${item.unit}` === unit);
    let price = product.discount ? (product.price * (1 - product.discount/100)).toFixed(2) : product.price;
    price = price / unitValue(defaultSku) * unitValue(selectedSku)
    setProductPrice(price);
  }, [product, defaultSku, unit]);

  return (
    <div className="col-md-6 col-sm-12 col-xs-12">
      <div className="detail-info pr-30 pl-30">
        <h2 className="title-detail">{product.name}</h2>
        <div className="product-detail-rating">
          <div className="product-rate-cover text-end">
            <div className="product-rate d-inline-block">
              <div className="product-rating" style={{ width: `${totalRating/5 * 100}%` }}></div>
            </div>
            <span className="font-small ml-5 text-muted"> ( {reviews.length} reviews)</span>
          </div>
        </div>
        <div className="clearfix product-price-cover">
          <div className="product-price primary-color float-left">
           <span className="current-price text-brand" style={{fontSize: '20px'}}>M.R.P: &#8377;{productPrice}</span>
          </div>
        </div>
        <div className="short-desc mb-30">
          <div dangerouslySetInnerHTML={{__html: product.descriptionShort}} />
        </div>
        <div className="attr-detail attr-size mb-30">
          <strong className="mr-10">Size / Weight: </strong>
          <ul className="list-filter size-filter font-small">
            {
              product.sku.map((item, index) =>(
                <li className={ `${item.value} ${item.unit}` === unit? "active" : ''} key={index}>
                <span 
                  onClick={e => setUnit(`${item.value} ${item.unit}`)}
                  style={{cursor: 'pointer'}}
                  value={`${item.value} ${item.unit}`}>{`${item.value} ${item.unit}`}</span>
              </li>
              ))
            }
          </ul>
        </div>
        <TestDetails product={product} />
        <div className="detail-extralink mb-50">
          <div className="detail-qty border radius" style={{padding: 0 }}>
            <select value={qty} onChange={e => setQty(e.target.value)} style={{marginTop: 0, marginBottom: 0}}>
              {[...Array(product.countInStock).keys()].map(x => (
                (x < 6) && (
                  <option key={x + 1} value={x + 1}>
                    {x + 1}
                  </option>
                )
              ))}
            </select>
          </div>
          <div className="product-extra-link2">
            <button type="submit" className="button button-add-to-cart" onClick={addToCartHandler}>
              <i className="fi-rs-shopping-cart"></i>
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsInfo;