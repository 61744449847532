import List from '../../admin/advertisements/list'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getAdvertisements as listAdvertisements } from '../../redux/actions/advertisementActions'
import NewAdvertisement from './NewAdvertisement'

function AdminAdvertisement() {
  const dispatch = useDispatch()
  const getAdvertisements = useSelector(state => state.getAdvertisements)
  const { advertisements } = getAdvertisements;
    
  useEffect(() => {
    dispatch(listAdvertisements())
   }, [dispatch])

  return (
    <div className="tab-pane" id="adevertisement" role="tabpanel" aria-labelledby="advertisement-tab">
      <div className="row">
        <div className='co-3 float-start'>
          <NewAdvertisement />
        </div>
        { advertisements && advertisements.length > 0 &&
          <List advertisements={advertisements} />
        }
      </div>
    </div>
  )
}

export default AdminAdvertisement;