import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from '../../pagination/Pagination';
import { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
// import EditProduct from '../../screens/Admin/EditProductForm';

// Actions
import { removeProductDetails } from "../../redux/actions/productActions";
import {getProducts as listProducts} from '../../redux/actions/productActions';
import { Code } from 'react-content-loader';

function Listproducts() {
  const dispatch = useDispatch();
  const getProducts = useSelector(state => state.getProducts)
  const { products, loading, error } = getProducts;
  const MyCodeLoader = () => <Code />;

  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return products.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, products]);

  const removeProduct = (item) => {
    dispatch(removeProductDetails({ _id: item._id }));
  };

  useEffect(() => {
    dispatch(listProducts())
  }, [dispatch])


  return (
    <div>
      <>
      {loading ? (
          <MyCodeLoader />
        ) : error ? (
          <h2>{error}</h2>
        ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Description</th>
              <th>Price</th>
              <th>Stock count</th>
              <th colSpan="3">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            { currentTableData.length > 0 && currentTableData.map((product, index) => (
              <tr key={index}>
                <td>{product.name}</td>
                <td>{product.categoryId.name}</td>
                <td><div dangerouslySetInnerHTML={{__html: product.descriptionShort}} /></td>
                <td>{product.price}</td>
                <td>{product.countInStock}</td>
                <td>
                  {/* <EditProduct product={product}/> */}
                  <Link to={`/admin/products/edit/${product._id}`}>
                    <Button variant="outline-light" size="lg" >
                      Edit
                    </Button>
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/products/${product._id}`}>
                    <Button variant="outline-light" size="lg">
                      View
                    </Button>
                  </Link>
                </td>
                <td>
                  {/* <Link to={`/admin/products/${product._id}`}> */}
                    <Button variant="warning" size="lg" onClick={() => removeProduct(product)}>
                      Delete
                    </Button>
                  {/* </Link> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        )}
        <Pagination
            key={Date.now()}
            id={Date.now()}
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={products.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)} />
      </>
    </div>
  )
}

export default Listproducts;