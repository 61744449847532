import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import MyAccount from './account';
import MyAddress from './address';
import MyOrders from './orders';
import MyDetails from './details';
import './Account.css'

function MyDashboard({match, history}) {
  return (
    <section className="newsletter mb-15 mt-40">
      <div className="container">
        <div className="container">
        <div className="row">
        <div className="col-lg-12 m-auto">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first"><i className="fi-rs-settings-sliders mr-10"></i>Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second"><i className="fi-rs-shopping-bag mr-10"></i>Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third"><i className="fi-rs-marker mr-10"></i>My Address</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth"><i className="fi-rs-user mr-10"></i>Account details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth"><i className="fi-rs-sign-out mr-10"></i>Logout</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first"><MyAccount /></Tab.Pane>
                <Tab.Pane eventKey="second"><MyOrders /></Tab.Pane>
                <Tab.Pane eventKey="third"><MyAddress userId={match.params.id}/></Tab.Pane>
                <Tab.Pane eventKey="fourth"><MyDetails /></Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        </div></div>
        </div>
      </div>
    </section>
  )
}

export default MyDashboard;