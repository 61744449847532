import './OrderScreen.css'
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//Actions
import {fetchOrder as listOrders} from '../redux/actions/orderActions'
import OrderList from "../components/js/OrderList";

function OrderScreen() {
  const dispatch = useDispatch()
  const getOrders = useSelector(state => state.getOrders)
  const { orders } = getOrders;
  const records = JSON.parse(JSON.stringify(orders));

  useEffect(() => {
    dispatch(listOrders())
  }, [dispatch])

  return (
    <div>
      { records && records.length > 0 &&
        <OrderList orders={records} />
      }
    </div>
  )
}

export default OrderScreen;