/* eslint-disable jsx-a11y/anchor-is-valid */
import { getCategories as listCategories } from '../../redux/actions/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { logout } from '../../utils/localstorage';
import { setInitialState } from '../../redux/actions/userAction';
import { toast } from "react-toastify";
import Search  from './Search';
import $ from 'jquery';
import './MobileHeader.css'

function MobileHeader() {
  const dispatch = useDispatch();
  const getCategories = useSelector(state => state.getCategories)
  const { categories } = getCategories;
  const user = useSelector(state => state.user);

  useEffect(() =>{
    dispatch(listCategories())
  }, [dispatch])

  const navbarTrigger = $(".burger-icon"),
    endTrigger = $(".mobile-menu-close"),
    container = $(".mobile-header-active"),
    wrapper4 = $("body");

  navbarTrigger.on("click", function (e) {
      e.preventDefault();
      container.addClass("sidebar-visible");
      wrapper4.addClass("mobile-menu-active");
  });

  endTrigger.on("click", function () {
      container.removeClass("sidebar-visible");
      wrapper4.removeClass("mobile-menu-active");
  });

  $(".body-overlay-1").on("click", function () {
      container.removeClass("sidebar-visible");
      wrapper4.removeClass("mobile-menu-active");
  });

  const $offCanvasNav = $(".mobile-menu"),
    $offCanvasNavSubMenu = $offCanvasNav.find(".dropdown");
  $offCanvasNavSubMenu.slideUp();
  const _offCanvasNav = () =>{
    if ($offCanvasNavSubMenu.siblings("ul:visible").length) {
      console.log('not visible');
      $offCanvasNavSubMenu.siblings("ul").toggle();
      $offCanvasNavSubMenu.parent("li").removeClass("active");
      $offCanvasNavSubMenu.siblings("ul").slideUp();
    } else {
      $offCanvasNavSubMenu.toggle();
      $offCanvasNavSubMenu.parent('li').find('[class^="fi-rs-angle"]').toggleClass('fi-rs-angle-small-down').toggleClass('fi-rs-angle-small-up');
      console.log('visible');
    }
  }

  const _handleLogout = () => {
    dispatch(setInitialState())
    logout()
    toast.success("Logout is successful", {
      position: "top-right",
    });
  }
  
  return (
    <div className="mobile-header-active mobile-header-wrapper-style">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <a href="/"><img src="/images/theme/logo.svg" alt="logo" /></a>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <button className="close-style search-close">
              <i className="icon-top"></i>
              <i className="icon-bottom"></i>
            </button>
          </div>
        </div>

        <div className="mobile-header-content-area">
          <div className="mobile-search search-style-3 mobile-header-border">
            <div style={{zIndex: 2000}}>
              <Search />
            </div>
          </div>
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu font-heading">
                <li className="menu-item-has-children" onClick={_offCanvasNav}>
                  <span className="menu-expand"><i className="fi-rs-angle-small-down"></i></span>
                  <a href="#" >Shop by Category</a>
                  <ul className="dropdown">
                    { categories.filter(category => category.type === 'category').map(category => (
                      <li key={category._id}><a href={`/products?categoryId=${category._id}`}>{category.name}</a></li>)
                    )}
                  </ul>
                </li>
                <li>
                  <a href="/our-story">Our Story</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li >
                  <a href="/know-your-oil">Know Your Oil</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="mobile-header-info-wrap">
            <div className="single-mobile-header-info">
              {!user.userInfo.isLogin ? (
                <a href="/signin"><i className="fi-rs-user"></i>Log In / Sign Up </a>
              ) : (
                <a href="/" onClick={_handleLogout}><i className="fi fi-rs-sign-out mr-10"></i>Sign out</a>
                )}
            </div>
            <div className="single-mobile-header-info">
              <a href="#"><i className="fi-rs-headphones"></i>(+91) 8240379055 </a>
            </div>
          </div>
          <div className="mobile-social-icon mb-50">
            <h6 className="mb-15">Follow Us</h6>
            <a href="https://www.facebook.com/dhenki.in?mibextid=ZbWKwL"><img src="../images/theme/icons/icon-facebook-white.svg" alt="" /></a>
            <a href="https://www.linkedin.com/company/dhenki-foods-pvt-ltd/"><img src="../images/theme/icons/linkedin icon.png" alt="" /></a>
            <a href="https://www.instagram.com/dhenkifoods?utm_source=qr&igsh=OWpqa3pqbzkzc2du"><img src="../images/theme/icons/icon-instagram-white.svg" alt="" /></a>
            <a href="https://youtube.com/@DhenkiFoods?si=ryECMQHUpEXISFOq"><img src="../images/theme/icons/icon-youtube-white.svg" alt="" /></a>
          </div>
          <div className="site-copyright">&copy; 2024, <strong className="text-brand">Dhenki</strong> - All rights reserved</div>
        </div>
      </div>
    </div>
  )
}

export default MobileHeader;