export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";

export const GET_SUBSCRIPTION_DETAILS_REQUEST = "GET_SUBSCRIPTION_DETAILS_REQUEST";
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = "GET_SUBSCRIPTION_DETAILS_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_FAIL = "GET_SUBSCRIPTION_DETAILS_FAIL";
export const GET_SUBSCRIPTION_DETAILS_RESET = "GET_SUBSCRIPTION_DETAILS_RESET";

export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'