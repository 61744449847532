export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const GET_PRODUCTS_REQUEST_WITH_CATEGORY = "GET_PRODUCTS_REQUEST_WITH_CATEGORY";
export const GET_PRODUCTS_SUCCESS_WITH_CATEGORY = "GET_PRODUCTS_SUCCESS_WITH_CATEGORY";
export const GET_PRODUCTS_FAIL_WITH_CATEGORY = "GET_PRODUCTS_FAIL_WITH_CATEGORY";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";
export const GET_PRODUCT_DETAILS_RESET = "GET_PRODUCT_DETAILS_RESET";

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
