import { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { addReview } from "../../redux/actions/reviewActions"
import { getReviewsByProductId } from "../../redux/actions/reviewActions"
import Moment from "react-moment";
import { convertToTotalRating } from "../../utils/utils.function";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9"
};

function ReviewTab({product}) {
  const dispatch = useDispatch();
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const user = useSelector(state => state.user)
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [comment, setComment] = useState("");
  const { reviews } = getReviews
  const totalRating = convertToTotalRating(reviews)

  useEffect(() => {
    dispatch(getReviewsByProductId(product._id))
  }, [dispatch, product._id])

  const stars = Array(5).fill(0)

  const handleClick = value => {
    setCurrentValue(value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addReview({
        name: user.userInfo.details.name,
        comment,
        productId: product._id,
        userId: user.userInfo.details._id,
        email: user.userInfo.details.email,
        currentValue,
        hoverValue
      })
    );
    dispatch(getReviewsByProductId(product._id))
  };

  return (
    <div style={styles.container}>
      <div className="comments-area" style={{width: "100%"}}>
        <div className="row">
          <div className="col-lg-8">
            <h4 className="mb-30">Customer questions & answers</h4>
            <div className="comment-list justify-content-start">
              { reviews && reviews.map((review, index) => (
                <div className="single-comment justify-content-start d-flex mb-30" key={index}>
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src="../images/blog/author-2.png" alt="" />
                      <span className="font-heading text-brand">{review.name}</span>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            <Moment format='MMMM Do YYYY, h:mm:ss a'>{review.createdAt}</Moment>
                          </span>
                        </div>
                        <div className="product-rate d-inline-block">
                          <div className="product-rating" style={{ width: `${review.rating/5 * 100}%` }}></div>
                        </div>
                      </div>
                      <p className="mb-10">{review.comment} <span className="reply">Reply</span></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          { user.userInfo.isLogin && 
            <div className="col-lg-4">
              <h4 className="mb-30">Customer reviews</h4>
              <div className="d-flex mb-30">
                <div className="product-rate d-inline-block mr-15">
                  <div className="product-rating" style={{ width: `${totalRating/5 * 100}%` }}></div>
                </div>
                <h6>{totalRating} out of 5</h6>
              </div>
              <div className="progress">
                <span>5 star</span>
                <div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
              </div>
              <div className="progress">
                <span>4 star</span>
                <div className="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
              </div>
              <div className="progress">
                <span>3 star</span>
                <div className="progress-bar" role="progressbar" style={{ width: "45%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">45%</div>
              </div>
              <div className="progress">
                <span>2 star</span>
                <div className="progress-bar" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
              </div>
              <div className="progress mb-30">
                <span>1 star</span>
                <div className="progress-bar" role="progressbar" style={{ width: "35%" }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="35">85%</div>
              </div>
            </div>
          }
        </div>
      </div>
      { user.userInfo.isLogin && 
        <div className="comment-form" style={{width: "100%"}}>
          <h4 className="mb-15">Add a review</h4>
          <div style={styles.stars}>
            {stars.map((_, index) => {
              return (
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                  style={{
                    marginRight: 10,
                    cursor: "pointer"
                  }}
                />
              )
            })}
          </div>

            <div className="row">
              <div className="col-lg-8 col-md-12">
                <form className="form-contact comment_form" action="#" id="commentForm" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          onChange={(e) => setComment(e.target.value)}
                          className="form-control w-100"
                          name="comment"
                          id="comment"
                          cols="30"
                          rows="9"
                          placeholder="Write Comment" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="button button-contactForm">Submit Review</button>
                  </div>
                </form>
              </div>
            </div>
        </div>
      }
    </div>
  )
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  }
};

export default ReviewTab;