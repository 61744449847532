import {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getProductsByCategoryId as listProducts} from '../../redux/actions/productActions'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation,  } from 'react-router-dom'
import RelatedProductItem from './RelatedProductItem';


const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function RelatedProducts() {
  const dispatch = useDispatch()
  const getProducts = useSelector(state => state.getProducts)
  const { products, loading, error } = getProducts;
  const searchParams = useQuery();
  const categoryId = searchParams.get('categoryId');

  useEffect(() => {
    dispatch(listProducts(categoryId))
  }, [dispatch, categoryId])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="row mt-60">
      <div className="col-12">
        <h2 className="section-title style-1 mb-30">Related products</h2>
      </div>
      <div className="col-12">
        <div className="row related-products">
        <Carousel responsive={responsive}>
          {loading ? (
            <h2>Loading...</h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            products.map(product => (
              <RelatedProductItem product={product} key={product._id}/>
            ))
          )}
        </Carousel>
        </div>
      </div>
    </div>
  )
}

export default RelatedProducts;