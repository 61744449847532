import * as actionTypes from '../constants/advertisementConstants'
import {Api} from '../../utils/Api'
import { toast } from "react-toastify";

export const addAdvertisement = (advertisement) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.ADD_ADVERTISEMENT,
      payload: {
        name: advertisement.name,
      },
    })
    Api.postRequest('/api/advertisements', advertisement)
    .then(response => {
      dispatch(getAdvertisements());
      toast.success("Advetisement is created", {
        position: "top-right",
      });
    });
   
  } catch(error){
    dispatch({
      type: actionTypes.ADD_ADVERTISEMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateAdvertisement = (id, advertisement) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.UPDATE_ADVERTISEMENT,
      payload: {
        name: advertisement.name,
      },
    })
    Api.putRequest(`/api/advertisements/${id}`, advertisement)
    .then(response => {
      dispatch(getAdvertisements());
      toast.success("Advertisement is updated", {
        position: "top-right",
      });
    });
  } catch(error) {
    dispatch({
      type: actionTypes.UPDATE_ADVERTISEMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAdvertisements = () => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ADVERTISEMENTS_REQUEST})    
    const {data} = await Api.getRequest('/api/advertisements')
    dispatch({
      type: actionTypes.GET_ADVERTISEMENTS_SUCCESS,
      payload: JSON.parse(data).advertisements,
    })
  } catch (error) {
      dispatch({
        type: actionTypes.GET_ADVERTISEMENTS_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAdvertisementDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ADVERTISEMENT_DETAILS_REQUEST})

    const {data} = await Api.getRequest(`/api/advertisements/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_ADVERTISEMENT_DETAILS_SUCCESS,
      payload: {
        ...p,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ADVERTISEMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeAdvertisementDetails = (id) => async dispatch => {
  try {
    Api.DeleteRequest(`/api/advertisements/${id._id}`)
    .then(response => {
      dispatch(getAdvertisements());
      toast.success("Advetisement is deleted", {
        position: "top-right",
      });
    });
  } catch (error) {
    console.log(error)
  }
}
