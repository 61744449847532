import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress } from '../../redux/actions/adressActions'
import { State }  from 'country-state-city';

function NewAddress() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [phone, setPhone] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [addressType, setAddressType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addAddress({
        userId: user.userInfo.details._id,
        firstName,
        lastName,
        street,
        state,
        city,
        email,
        pin,
        phone,
        houseNumber,
        addressType,
        defaultAddress: false
      })
    );
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Button variant="primary" onClick={handleShow}>
      Add New Address
    </Button>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h5>Address</h5>
          </div>
          <div className="card-body">
            <form method="post" name="enq">
              <div className="row">
                <div className="form-group col-md-6">
                  <label>First Name <span className="required">*</span></label>
                  <input
                    value={firstName}
                    required=""
                    className="form-control"
                    name="First name"
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Last Name <span className="required">*</span></label>
                  <input
                    value={lastName}
                    required=""
                    className="form-control"
                    name="Last name"
                    type="text"
                    onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Street <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="email"
                    type="text"
                    onChange={(e) => setStreet(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>House Number<span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="password"
                    type="text" 
                    onChange={(e) => setHouseNumber(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                  <label>State <span className="required">*</span></label>
                  <select value={state} onChange={(e) => setState(e.target.value)} required className="form-control">
                    <option value="">Select state</option>
                    { State.getStatesOfCountry('IN').map(item => (
                      <option value={item.name} key={item.isoCode}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label>City <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="city"
                    type="text"
                    onChange={(e) => setCity(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Pin <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="pin"
                    type="text"
                    onChange={(e) => setPin(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Phone <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="phone"
                    type="text"
                    onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Email <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label>Address Type <span className="required">*</span></label>
                  <input
                    required=""
                    className="form-control"
                    name="address_type"
                    type="text"
                    onChange={(e) => setAddressType(e.target.value)} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}
  
export default NewAddress;