import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../redux/actions/productActions';
import { getCategories as listCategories } from '../../redux/actions/categoryActions';
import TextEditor from '../../admin/TextEditor';
import { useHistory } from "react-router-dom";
import './Admin.css';
import ImageUpload from './ImageUpload';
import SkuInput from './SkuInput';

function NewProduct() {
  const dispatch = useDispatch();
  const getCategories = useSelector(state => state.getCategories);
  const {categories } = getCategories;
  const navigate = useHistory();
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  // const [imageUrl, setImageUrl] = useState("");
  const [uspImages, setUspImages] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [unit, setUnit] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [size, setSize] = useState("");
  const [weight, setWeight] = useState("");
  const [color, setColor] = useState("");
  const [discount, setDiscount] = useState("");
  const [sku, setSku] = useState([]);

  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  // const handleProductImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   TransformFileData(file);
  //   TransformFilesData(e.target.files);
  // };

  // const TransformFilesData = (files) => {
  //   const imageFiles = [];
  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     console.log(files[i]);
  //     reader.readAsDataURL(files[i]);
  //     reader.onloadend = () => {
  //       console.log(reader.result)
  //       imageFiles.push(reader.result);
  //     }
  //   }
  //   setImages(imageFiles);
  // }

  // const TransformFileData = (file) => {
  //   const reader = new FileReader();

  //   if (file) {
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       setImageUrl(reader.result);
  //     };
  //   } else {
  //     setImageUrl("");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addProduct({
        name,
        categoryId,
        price,
        shortDescription,
        longDescription,
        additionalInfo,
        size,
        weight,
        color,
        imageUrl: images.length > 0 ? images[0] : '',
        images: images,
        uspImages,
        countInStock,
        unit,
        discount,
        sku
      })
    );
    handleClose();
    navigate.push("/admin/#products");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeImage = (index) => {
    const newAssets = images.filter((item, i) => i !== index);
    setImages(newAssets);
  }

  const removeUspImage = (index) => {
    const newAssets = uspImages.filter((item, i) => i !== index);
    setUspImages(newAssets);
  }

  const addSkuRows = (e) => {
    e.preventDefault();
    const rowsInput = {
      unit:'',
      value:'',
      defaultSku: false
    } 
    setSku([...sku, rowsInput])
  }

  const deleteSkuRows = (index) => {
    const rows = [...sku];
    rows.splice(index, 1);
    setSku(rows);
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...sku];
    rowsInput[index][name] = value;
    setSku(rowsInput);
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add New Product
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Product</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-20">
                  <div className="form-group col-md-6">
                    <div className='row'>
                      { images && images.length > 0 && images.map((image, index) => (
                        <div className='col-3' key={index}>
                          <span className="close"
                            style={{cursor: "pointer"}}
                            onClick={() => removeImage(index)}>&times;</span>
                          <img src={ image } className="thumbnail" alt="error!" />
                        </div>
                      ))}
                    </div>
                    <label>Images <span className="required">*</span></label>
                    <ImageUpload
                      images={images}
                      setImages={setImages}/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label>Preview <span className="required">*</span></label>
                    <div className='image-preview'>
                      {images.length > 0 ? (
                        <>
                          <img src={images[images.length - 1]} alt="error!" />
                        </>
                      ) : (
                        <p>Product image upload preview will appear here!</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Category <span className="required">*</span></label>
                    <select onChange={(e) => setCategoryId(e.target.value)} required className="form-control">
                      <option value="">Select Category</option>
                      {categories.map(category => (
                        <option value={category._id} key={category._id}>{category.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Name <span className="required">*</span></label>
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Price <span className="required">*</span></label>
                    <input
                      type="number"
                      placeholder="Price"
                      className="form-control"
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Count In Stock <span className="required">*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Count in Stock"
                      onChange={(e) => setCountInStock(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Weight Type <span className="required">*</span></label>
                    <select onChange={(e) => setUnit(e.target.value)} required className="form-control">
                      <option value="">Select weight type</option>
                      {["weightLiquid", "weightSolid"].map(unit => (
                        <option value={unit} key={unit}>{unit}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label>Short Description <span className="required">*</span></label>
                    <TextEditor name={"shortDescription"} value={shortDescription} onChange={setShortDescription} />
                  </div>
                  <div className="form-group col-md-12 mt-40">
                    <label>Long Description <span className="required">*</span></label>
                    <TextEditor name={"longDescription"} value={longDescription} onChange={setLongDescription} />
                  </div>
                  <div className="form-group col-md-12 mt-40">
                    <label>Additional Information <span className="required">*</span></label>
                    <TextEditor name={"AdditionalInfo"} value={additionalInfo} onChange={setAdditionalInfo} />
                  </div>
                  <div className="row mt-40">
                    <label>Product Info <span className="required">*</span></label>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td colSpan={4}>Size</td>
                          <td colSpan={4}>
                            <input
                              type="text"
                              placeholder=""
                              className="form-control"
                              onChange={(e) => setSize(e.target.value)}
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>Weight</td>
                          <td colSpan={4}>
                            <input
                              type="text"
                              placeholder=""
                              className="form-control"
                              onChange={(e) => setWeight(e.target.value)}
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>Color</td>
                          <td colSpan={4}>
                            <input
                              type="text"
                              placeholder=""
                              className="form-control"
                              onChange={(e) => setColor(e.target.value)}
                              required
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Discount <span className="required">%</span></label>
                    <input
                      type="number"
                      placeholder="Discount"
                      className="form-control"
                      onChange={(e) => setDiscount(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">&nbsp;</div>
                  <div className="form-group col-md-6">
                    <div className='row'>
                      { uspImages && uspImages.length > 0 && uspImages.map((image, index) => (
                        <div className='col-3' key={index}>
                          <span className="close"
                            style={{cursor: "pointer"}}
                            onClick={() => removeUspImage(index)}>&times;</span>
                          <img src={ image } className="thumbnail" alt="error!" />
                        </div>
                      ))}
                    </div>
                    <label>Images <span className="required">*</span></label>
                    <ImageUpload
                      images={uspImages}
                      setImages={setUspImages}/>
                  </div>
                  <div className="form-group col-md-12">
                    <label>Sku </label>
                    <div className='float-end m-2'>
                      <span className="btn btn-outline-success" onClick={addSkuRows} >+</span>

                    </div>
                    <table className="table">
                      {/* <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>
                            <button className="btn btn-outline-success" onClick={addSkuRows} >+</button>
                          </th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <SkuInput
                          sku={sku}
                          setSku={setSku}
                          deleteSkuRows={deleteSkuRows}
                          handleChange={handleChange}/>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewProduct;