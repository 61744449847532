

function AdditionalTab({product}) {
  return (
    <div className="tab-pane fade show" id="Additional-info">
      <div className="">
        <div dangerouslySetInnerHTML={{__html: product.additionalInfo}} />
      </div>
    </div>
  )
}

export default AdditionalTab;