import React from 'react'
import { useMediaQuery } from 'react-responsive'
import   "../css/Main.css"
// import Topheader from "./Topheader"
import Middleheader from "./Middleheader"
import Bottomheader from "./Bottomheader"
import MobileHeader from './MobileHeader'

const Header = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <header className="header-area header-style-1 header-height-2" >
      {/* <Topheader /> */}
      {isDesktopOrLaptop && <Middleheader />}
      {isTabletOrMobile && <Bottomheader />}
      {isTabletOrMobile && <MobileHeader />}
     
    </header>
  )
}

export default Header;