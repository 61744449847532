import * as actionTypes from '../constants/userContants'
export const USER_INITIAL_STATE = {
  userInfo: {
    isLogin: false,
    details: {},
  },
}

export const userReducer = (state = USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {userInfo: {...action.payload}}
    case actionTypes.SET_INITIAL_STATE:
      return USER_INITIAL_STATE

    default:
      return state
  }
}

export const getUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_REQUEST:
      return {
        laoding: true,
        user: {}
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        user: action.payload,
        loading: false,
      };
    case actionTypes.GET_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getUsersReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
      return {
        loading: true,
        users: [],
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        users: action.payload,
        loading: false,
      };
    case actionTypes.GET_USERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
    }
  };
  
  export const getUserDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
    case actionTypes.GET_USER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case actionTypes.GET_USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_DETAILS_RESET:
      return {
        user: {},
      };
    default:
      return state;
    }
  };
  