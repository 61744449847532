// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/theme/icons/icon-plane.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
    font-weight: 400;
    color: #7E7E7E;
}

.slider-content p {
    font-size: 30px;
}

.mb-65 {
    margin-bottom: 65px !important;
}

.slider-content form {
    margin: 0 auto;
    background-color: #fff;
    max-width: 450px;
    border-radius: 50px;
}



.slider-content form input {
    border: 0;
    border-radius: 50px 0 0 50px;
    border-left: 48px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 25px center;
}

.slider-content form button {
    border: 0;
    border-radius: 50px;
}

form input {
    padding-left: 58px;
    color: black;
    font-size: 16;
 }`, "",{"version":3,"sources":["webpack://./src/components/css/Homeslider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;;;AAIA;IACI,SAAS;IACT,4BAA4B;IAC5B,iBAAiB;IACjB,yEAA0E;AAC9E;;AAEA;IACI,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;CAChB","sourcesContent":["p {\n    font-weight: 400;\n    color: #7E7E7E;\n}\n\n.slider-content p {\n    font-size: 30px;\n}\n\n.mb-65 {\n    margin-bottom: 65px !important;\n}\n\n.slider-content form {\n    margin: 0 auto;\n    background-color: #fff;\n    max-width: 450px;\n    border-radius: 50px;\n}\n\n\n\n.slider-content form input {\n    border: 0;\n    border-radius: 50px 0 0 50px;\n    border-left: 48px;\n    background: url(./images/theme/icons/icon-plane.png) no-repeat 25px center;\n}\n\n.slider-content form button {\n    border: 0;\n    border-radius: 50px;\n}\n\nform input {\n    padding-left: 58px;\n    color: black;\n    font-size: 16;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
