import * as actionTypes from '../constants/wishlistConstants'
import {Api} from '../../utils/Api'
import {convertToWishlistData} from '../../utils/utils.function'

export const addToWishlist = (id, qty, unit) => async dispatch => {
    const {data} = await Api.getRequest(`/api/products/${id}`)
    const product = JSON.parse(data)
    dispatch({
      type: actionTypes.ADD_TO_WISHLIST,
      payload: {
        product: product._id,
        name: product.name,
        imageUrl: product.imageUrl,
        price: product.price,
        countInStock: product.countInStock,
        qty,
        unit
      },
    })
  
    Api.postRequest('/api/wishlist', {productId: id, count: qty, unit: unit})
  }
  
  export const removeFromWishlist =
    ({pId, _id}) =>
    dispatch => {
      dispatch({
        type: actionTypes.REMOVE_FROM_WISHLIST,
        payload: pId,
      })
      Api.DeleteRequest('/api/wishlist/' + _id)
    }
  
  export const fetchWishlist = () => async dispatch => {
    try {
      const {data: strigifyData} = await Api.getRequest(`/api/wishlist/`)
      const {wishlists} = JSON.parse(strigifyData)
  
      dispatch({
        type: actionTypes.FETCH_MY_WISHLIST,
        payload: {
          wishlists: convertToWishlistData(wishlists),
        },
      })
    } catch (e) {
      console.log('EROROR :  ', e)
    }
  }