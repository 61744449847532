import '../css/Main.css'
import Productitem from './ProductItem'
import {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getProductsByCategoryId as listProducts} from '../../redux/actions/productActions'
import 'react-multi-carousel/lib/styles.css';
import { useLocation,  } from 'react-router-dom'
import Popularproduct from './Popularproducts'
import { Code } from 'react-content-loader'


const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function Homproduct() {
  const MyCodeLoader = () => <Code />
  const dispatch = useDispatch()
  const getProducts = useSelector(state => state.getProducts)
  const { products, loading, error } = getProducts;
  const searchParams = useQuery();
  const categoryId = searchParams.get('categoryId');

  useEffect(() => {
    dispatch(listProducts(categoryId))
  }, [dispatch, categoryId])

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <Popularproduct />
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
            <div className="row product-grid-4">
              {loading ? (
                <MyCodeLoader />
              ) : error ? (
                <h2>{error}</h2>
              ) : (
                products.map(product => (
                  <div className="col-lg-6-5 col-md-4 col-12 col-sm-6" key={product._id}>
                    <Productitem
                      key={product._id}
                      name={product.name}
                      category={product.categoryId}
                      price={product.price}
                      imageUrl={product.assets && product.assets.length > 0 ? product.assets[0]: '../logo192.png'}
                      productId={product._id}
                      unit={product.uint}
                      countInStock={product.countInStock}
                      sku={product.sku}
                    />
                  </div>
                ))
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Homproduct