export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";

export const GET_REVIEWS_BY_PRODUCT_ID_REQUEST = "GET_REVIEWS_BY_PRODUCT_ID_REQUEST";
export const GET_REVIEWS_BY_PRODUCT_ID_SUCCESS = "GET_REVIEWS_BY_PRODUCT_ID_SUCCESS";
export const GET_REVIEWS_BY_PRODUCT_ID_FAIL = "GET_REVIEWS_BY_PRODUCT_ID_FAIL";

export const GET_REVIEW_DETAILS_REQUEST = "GET_REVIEW_DETAILS_REQUEST";
export const GET_REVIEW_DETAILS_SUCCESS = "GET_REVIEW_DETAILS_SUCCESS";
export const GET_REVIEW_DETAILS_FAIL = "GET_REVIEW_DETAILS_FAIL";
export const GET_REVIEW_DETAILS_RESET = "GET_REVIEW_DETAILS_RESET";

export const ADD_REVIEW = 'ADD_REVIEW'
export const UPDATE_REVIEW = 'UPDATE_REVIEW'
