import './Advertisements.css'
import SideNav from '../SideNav'
import { useDispatch } from "react-redux";
import { addAdvertisement } from "../../redux/actions/advertisementActions";
import { useState } from "react";

function AdvertisementNew() {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [screenType, setScreenType] = useState("");

  const ScreenTypes = [
    {value: 'homeslider', text: 'HomeSlider'},
    {value: 'homebanner', text: 'HomeBanner'},
  ];

  
  const handleScreenImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addAdvertisement({
        description,
        imageUrl: imageUrl,
        screenType,
      })
    );
  };

  return (
    <div className='styled-dashboard'>
      <SideNav/>
      <div className='content'>
        <form onSubmit={handleSubmit}>
          <input
            id="imgUpload"
            name='file'
            accept="image/*"
            type="file"
            className="button-upload"
            onChange={handleScreenImageUpload}
            required
          />
          <input
            type="text"
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <select onChange={(e) => setScreenType(e.target.value)} required>
            <option value="">Select Screen type</option>
            {ScreenTypes.map(item => (
              <option value={item.value} key={item.value}>{item.text}</option>
            ))}
          </select>
          <button type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default AdvertisementNew;