import { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct } from '../../redux/actions/productActions';
import { getCategories as listCategories } from '../../redux/actions/categoryActions';
import TextEditor from '../../admin/TextEditor';
// import { useHistory } from 'react-router-dom'
import ImageUpload from './ImageUpload';
import SkuInput from './SkuInput';
import './Admin.css';

function EditProductForm({product, history}) {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [images, setImages] = useState(product.assets);
  const [uspImages, setUspImages] = useState(product.uspAssets);
  const [categoryId, setCategoryId] = useState(product.categoryId ? product.categoryId._id : product.categoryId);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [shortDescription, setShortDescription] = useState(product.descriptionShort);
  const [longDescription, setLongDescription] = useState(product.descriptionLong);
  const [countInStock, setCountInStock] = useState(product.countInStock);
  const [unit, setUnit] = useState(product.unit);
  const [additionalInfo, setAdditionalInfo] = useState(product.additionalInfo);
  const [size, setSize] = useState(product.size);
  const [weight, setWeight] = useState(product.weight);
  const [color, setColor] = useState(product.color);
  const [discount, setDiscount] = useState(product.discount);
  const [sku, setSku] = useState(product.sku || []);
  // const [assets, setAssets] = useState(product.assets);
  const getCategories = useSelector(state => state.getCategories);
  const { categories } = getCategories;

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch])

  const cancelPage = () => {
    history.goBack();
  }

  // const handleProductImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   TransformFileData(file);
  //   TransformFilesData(e.target.files);
  // };

  // const TransformFilesData = (files) => {
  //   const imageFiles = [];
  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(files[i]);
  //     reader.onloadend = () => {
  //       imageFiles.push(reader.result);
  //     }
  //   }
  //   setImages(imageFiles);
  // }

  // const TransformFileData = (file) => {
  //   const reader = new FileReader();

  //   if (file) {
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       setImageUrl(reader.result);
  //     };
  //   } else {
  //     setImageUrl("");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('categoryId', categoryId);
    dispatch(
      updateProduct(product._id, {
        name,
        categoryId,
        assets: product.assets,
        uspImages: uspImages,
        price,
        shortDescription,
        longDescription,
        additionalInfo,
        size,
        weight,
        color,
        imageUrl: images[0],
        images: images,
        countInStock,
        unit,
        discount,
        sku
      })
    );
    navigate.goBack();
  };

  const removeImage = (index) => {
    const newAssets = images.filter((item, i) => i !== index);
    setImages(newAssets);
  }

  const removeUspImage = (index) => {
    const newAssets = uspImages.filter((item, i) => i !== index);
    setUspImages(newAssets);
  }

  const addSkuRows = (e) => {
    e.preventDefault();
    const rowsInput = {
      unit:'',
      value:'',
      defaultSku: false
    } 
    setSku([...sku, rowsInput])
  }

  const deleteSkuRows = (index) => {
    const rows = [...sku];
    rows.splice(index, 1);
    setSku(rows);
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...sku];
    rowsInput[index][name] = value;
    setSku(rowsInput);
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>Product</h5>
        </div>
        <div className="card-body">
          <form method="post" name="enq" onSubmit={handleSubmit}>
            <div className="row pb-20">
              <div className="form-group col-md-6">
                <div className='row'>
                  { images && images.length > 0 && images.map((image, index) => (
                    <div className='col-3' key={index}>
                      <span className="close"
                        style={{cursor: "pointer"}}
                        onClick={() => removeImage(index)}>&times;</span>
                      <img src={ image } className="thumbnail" alt="error!" />
                    </div>
                  ))}
                </div>
                <label className='w-bold'>Images <span className="required">*</span></label>
                <ImageUpload
                  images={images}
                  setImages={setImages}/>
                {/* <input
                  id="imgUpload"
                  className="form-control"
                  name='file'
                  multiple
                  accept="image/*"
                  type="file"
                  onChange={handleProductImageUpload}
                /> */}
                {/* <div className='row'>
                  { assets && assets.length > 0 && assets.map((image, index) => (
                    <div className='col-3' key={index}>
                      <span className="close"
                        style={{cursor: "pointer"}}
                        onClick={() => removeImage(index)}>&times;</span>
                      <img src={ image } className="thumbnail" alt="error!" />
                    </div>
                  ))}
                </div> */}
              </div>
              <div className='form-group col-md-6'>
                <label>Preview <span className="required">*</span></label>
                <div className='image-preview'>
                  {images && images.length > 0 ? (
                    <>
                      <img src={ images[0]} alt="error!" />
                    </>
                  ) : (
                    <p>Product image upload preview will appear here!</p>
                  )}
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Category <span className="required">*</span></label>
                <select value={categoryId} onChange={(e) => setCategoryId(e.target.value)} required className="form-control">
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option value={category._id} key={category._id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Name <span className="required">*</span></label>
                <input
                  value={name}
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Price <span className="required">*</span></label>
                <input
                  value={price}
                  type="number"
                  placeholder="Price"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Count In Stock <span className="required">*</span></label>
                <input
                  value={countInStock}
                  type="number"
                  className="form-control"
                  placeholder="Count in Stock"
                  onChange={(e) => setCountInStock(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Unit <span className="required">*</span></label>
                <select value={unit} onChange={(e) => setUnit(e.target.value)} required className="form-control">
                  <option value="">Select Category</option>
                  {["weightLiquid", "weightSolid"].map(unit => (
                    <option value={unit} key={unit}>{unit}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-12">
                <label>Short Description <span className="required">*</span></label>
                <TextEditor name={"shortDescription"} value={shortDescription} onChange={setShortDescription} />
              </div>
              <div className="form-group col-md-12 mt-40">
                <label>Long Description <span className="required">*</span></label>
                <TextEditor name={"longDescription"} value={longDescription} onChange={setLongDescription} />
              </div>
              <div className="form-group col-md-12 mt-40">
                <label>Additional Information <span className="required">*</span></label>
                <TextEditor name={"AdditionalInfo"} value={additionalInfo} onChange={setAdditionalInfo} />
              </div>
              <div className="col-6 mt-40">
                <label>Product Info <span className="required">*</span></label>
                <div className='row'>
                  <div className='col-md-2 text-end pt-3'>Size</div>
                  <div className='col-3'>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-2 text-end pt-3'>Weight</div>
                  <div className='col-3'>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-2 text-end pt-3'>Color</div>
                  <div className='col-3'>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-4 mt-40">
                <label>Discount %</label>
                <input
                  value={discount || 0}
                  type="number"
                  placeholder="Discount"
                  className="form-control"
                  onChange={(e) => setDiscount(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <div className='row'>
                  { uspImages && uspImages.length > 0 && uspImages.map((image, index) => (
                    <div className='col-3' key={index}>
                      <span className="close"
                        style={{cursor: "pointer"}}
                        onClick={() => removeUspImage(index)}>&times;</span>
                      <img src={ image } className="thumbnail" alt="error!" />
                    </div>
                  ))}
                </div>
                <label>Usp Images <span className="required">*</span></label>
                <ImageUpload
                  images={uspImages}
                  setImages={setUspImages}/>
              </div>
              <div className="form-group col-md-6">
                <label>Sku </label>
                <div className='float-end m-2'>
                  <span className="btn btn-outline-success" onClick={addSkuRows} >+</span>
                </div>
                <table className="table">
                  <tbody>
                    <SkuInput
                      sku={sku}
                      setSku={setSku}
                      deleteSkuRows={deleteSkuRows}
                      handleChange={handleChange}/>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-3 my-4'>
              <button type="submit">
                Submit
              </button>
              <Link className="btn mx-2 py-3" onClick={cancelPage}>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditProductForm;