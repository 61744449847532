import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchOrder as listOrders } from '../../redux/actions/orderActions'
import OrderList from './orderList';

function MyOrders() {
  const dispatch = useDispatch()
  const getOrders = useSelector(state => state.getOrders)
  const { orders } = getOrders;

  useEffect(() => {
    dispatch(listOrders())
  }, [dispatch])

  return (
    <div className="tab-pane" id="orders" role="tabpanel" aria-labelledby="orders-tab">
      <div className="card">
        <div className="card-header">
          <h3 className="mb-0">My Orders</h3>
        </div>
        <div className="card-body">
        { orders.length > 0 &&
          <OrderList orders={orders} />
        }
        </div>
      </div>
    </div>
  )
}

export default MyOrders;