import { useState } from 'react';
import EditAddress from './EditAddress';

function AddressList({addresses, selectedAddressId}) {
  const selectedDefaultAddress = addresses.filter(item => item.defaultAddress)
  const selectedAddress = selectedDefaultAddress.length > 0 ? selectedDefaultAddress[0] : addresses[0];
  const [defaultId, setDefaultId] = useState(selectedAddress._id)

  return (
    <>
      <div className="row col">
        {addresses.map((a, index) => (
          <div className="col-lg-6" key={index}>
            <div className="card mb-3 mb-lg-0">
              <div className="card-header">
                <h3 className="mb-0">{a.addressType}</h3>
                <div className='float-right'>
                  <input type="radio" value={a._id} name='defaultAddress'
                    checked={a._id === defaultId}
                    onChange={(e) => { setDefaultId(e.target.value); selectedAddressId(e.target.value)}} />
                </div>
              </div>
              <div className="card-body">
                <address>
                  {a.name}<br />
                  {a.street}, {a.houseNumber}<br />
                  {a.city} - {a.pin}<br />Phone: {a.phone}<br />
                  Email: {a.email}
                </address>
                <EditAddress address={a} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default AddressList