import { useState } from "react";

function ImageUpload({images, setImages }) {
  const [imageUrl, setImageUrl] = useState("")
  const handleProductImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
    e.target.value = null;
    handleAdd();
  };
    
  const TransformFileData = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  function handleAdd() {
    const newImages = [...images, imageUrl];
    setImages(newImages);
  }

  return (
    <>
    <input
      id="imgUpload"
      className="form-control"
      name='file'
      accept="image/*"
      type="file"
      onChange={handleProductImageUpload} />
    </>
  )
}

export default ImageUpload;