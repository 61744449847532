export const token_key = 'E_COMMERCE_TOKEN'

export const setToken = token => {
  window.localStorage.setItem(token_key, token)
}

export const setShippingCost = cost => {
  window.localStorage.setItem("shippingCost", cost)
}

export const getShippingCost = () => {
  let cost = window.localStorage.getItem("shippingCost")
  if (!!cost) return cost
  return ''
}

export const setZipcode = zipcode => {
  window.localStorage.setItem("zipcode", zipcode)
}

export const getZipcode= () => {
  let zipcode = window.localStorage.getItem("zipcode")
  if (!!zipcode) return zipcode
  return 700105
}

export const getToken = () => {
  let token = window.localStorage.getItem(token_key)
  if (!!token) return token
  return false
}

export const isLogin = () => {
  if (!!getToken()) {
    return true
  }
  return false
}

export const logout = () => {
  window.localStorage.clear()
}
