import Card from "react-bootstrap/Card"; 
import React from "react"; 

function AdminDashboard() {
  return (
    <> 
      <div className="row">
        <div className="col-3 m-4">
          <Card style={{ width: "22rem" }}> 
          <Card.Body> 
            <Card.Title style={{ color: "green" }}>Total Sales</Card.Title> 
            <Card.Text> 
              &#8377;30000.00
            </Card.Text> 
            <Card.Link href="#"> Details</Card.Link> 
          </Card.Body> 
          </Card>
        </div>
        <div className="col-3  m-4">
          <Card style={{ width: "22rem" }}> 
          <Card.Body> 
            <Card.Title style={{ color: "green" }}>Total Orders</Card.Title> 
            <Card.Text> 
              320
            </Card.Text> 
            <Card.Link href="#"> Details</Card.Link> 
          </Card.Body> 
          </Card>
        </div>
        <div className="col-3  m-4">
          <Card style={{ width: "22rem" }}> 
          <Card.Body> 
            <Card.Title style={{ color: "green" }}>Total Products</Card.Title> 
            <Card.Text> 
              156
            </Card.Text> 
            <Card.Link href="#"> Details</Card.Link> 
          </Card.Body> 
          </Card>
        </div>
        <div className="col-3  m-4">
          <Card style={{ width: "22rem" }}> 
          <Card.Body> 
            <Card.Title style={{ color: "green" }}>Monthly Earning</Card.Title> 
            <Card.Text> 
              &#8377;2400.00
            </Card.Text> 
            <Card.Link href="#"> Details</Card.Link> 
          </Card.Body> 
          </Card>
        </div>
        <div className="col-3  m-4">
          <Card style={{ width: "22rem" }}> 
          <Card.Body> 
            <Card.Title style={{ color: "green" }}>Total Customers</Card.Title> 
            <Card.Text> 
               30
            </Card.Text> 
            <Card.Link href="#"> Details</Card.Link> 
          </Card.Body> 
          </Card>
        </div>
      </div> 
    </> 
  )
}

export default AdminDashboard;