import React, { useState } from "react";
import Slider from "react-slick";
import "./slick-theme.css";
import "slick-carousel/slick/slick.css"; 
import "./slick.css";
import "./Products.css";

function CustomPaging({assets}) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const minSlideToShow = () => {
    return assets.length > 3 ? 3 : assets.length;
  }
  return (
    <>
      <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        {assets.map((item, index) => (
          <img
            src={item}
            alt={`product-img-${index}`}
            key={index} />
        ))}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={minSlideToShow()}
        swipeToSlide={true}
        focusOnSelect={true}
      >
        {assets.map((item, index) => (
          <img
            src={item}
            alt={`product-img-${index}`}
            key={index} width={150} height={150} style={{ marginTop: "10px"}}/>
        ))}
      </Slider>
    </>
  );
}

export default CustomPaging;