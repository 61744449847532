import Pagination from '../../pagination/Pagination';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useMemo, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import EditAdvertisement from "../../screens/Admin/EditAdvertisement"

// Actions
import { removeAdvertisementDetails } from "../../redux/actions/advertisementActions";
// import useLogin from "../../utils/hooks/useLogin";

function List({advertisements}) {
  const dispatch = useDispatch();
  // const navigate = useHistory();
  // const location = useLocation()
  // const { loginInfo } = useLogin();
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return advertisements.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, advertisements]);

  const removeAdvertisement = (item) => {
    dispatch(removeAdvertisementDetails({ _id: item._id }));
  };

  return (
    <>
    {/* <div className='co-3 float-start'>
      <NewAdvertisement />
    </div> */}
    <Table striped bordered hover>
      <thead>
        <tr>
          <th colSpan="4" className='text-center'>Description</th>
          <th>Type</th>
          <th colSpan="3">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {advertisements.length > 0 && currentTableData.length > 0 && currentTableData.map((advertisement, index) => (
          <tr key={index} >
            <td colSpan="4">
              <Row>
                <Col xs={6} md={4}>
                  <Image src={advertisement.imageUrl} style={{width: "100px", height: "90px"}} />
                </Col>
                <Col className='align-middle mt-4'>{advertisement.description}</Col>
              </Row>
            </td>
            <td>
              {advertisement.screenType}
            </td>
            <td>
              {/* <Link to={`/admin/advertisements/edit/${advertisement._id}`}>
                <Button variant="outline-light" size="lg">
                  Edit
                </Button>
              </Link> */}
              <EditAdvertisement advertisement={advertisement} />
            </td>
            <td>
              <Link to={`/admin/advertisements/${advertisement._id}`}>
                <Button variant="outline-light" size="lg">
                  View
                </Button>
              </Link>
            </td>
            <td>
              {/* <Link to={`/admin/advertisements/${advertisement._id}`}> */}
                <Button variant="warning" size="lg" onClick={() => removeAdvertisement(advertisement)}>
                  Delete
                </Button>
              {/* </Link> */}
            </td>
          </tr>
        ))}
      </tbody>
    </Table><Pagination
        key={Date.now()}
        id={Date.now()}
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={advertisements.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)} /></>
  )
}

export default List;