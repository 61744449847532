import '../css/Main.css'
import Homeslider from "./Homeslider"
import Homebanner from "./Homebanner"
import Homeproduct from "./Homeproduct"
import ScrollToTop from 'react-scroll-up';

function Main() {
  const scrollStyle =  {
    width: "32px",
    height: "32px",
    color: "#253D4E",
    right: "30px",
    bottom: "30px",
    borderRadius: "30px",
    textAlign: "center",
    overflow: "hidden",
    zIndex: "999 !important",
    border: "2px solid #253D4E",
    backgroundColor: "#fff"
  }
  return (
    <>
      <Homeslider />
      <Homebanner />
      <Homeproduct />
      <ScrollToTop showUnder={160} style={scrollStyle} duration={1}>
        <i className="fi-rs-arrow-small-up"></i>
      </ScrollToTop>
    </>
  )
}

export default Main;