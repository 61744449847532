import Pagination from '../../pagination/Pagination';
import {useMemo, useState} from 'react'
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';

function OrderList({orders}) {
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return JSON.parse(JSON.stringify(orders)).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, orders]);

  return (
    <>
    <div className='row'>
      <div className='col-3 my-3'>
        <Link to={`/admin/users/download`}>
          <Button variant="outline-light" size="lg">
            Download
          </Button>
        </Link>
      </div>
    </div>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Order Date</th>
          <th rowSpan={2} className='text-center'>Product Name</th>
          <th>Customer Name</th>
          <th>Order value</th>
          <th>Quantity</th>
          <th>Delivery Status</th>
          <th colSpan="3">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {orders.length > 0 && currentTableData.length > 0 && currentTableData.map((order, index) => (
          <tr key={index} >
            <td>
              {order.id}
            </td>
            <td>
              <Moment format='MMMM Do YYYY, h:mm:ss a'>{order.createdAt}</Moment>
            </td>
            <td>
              <Row>
                <Col xs={6} md={4}>
                  <Image src={order.productImageUrl} style={{width: "100px", height: "90px"}} />
                </Col>
                <Col className='align-middle mt-4'>{order.productName}</Col>
              </Row>
            </td>
            <td>{order.userId.fullName}</td>
            <td>{order.productPrice}</td>
            <td>{order.productQuantity}</td>
            <td>{order.delivery_status}</td>
            <td>
              <Link to={`/orders/${order.id}`}>
                <Button variant="outline-light" size="lg">
                  View
                </Button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Pagination
        key={Date.now()}
        id={Date.now()}
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={orders.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)} />
        </>
  )
}

export default OrderList;