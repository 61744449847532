import React from 'react'
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../utils/localstorage'
import { setInitialState } from '../../redux/actions/userAction'
import { toast } from "react-toastify";

function Headeractions({click}) {
  const cart = useSelector(state => state.cart)
  // const wishlist = useSelector(state => state.wishlist)
  // const history = useHistory()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const {cartItems} = cart
  // const {wishlistItems} = wishlist

  // const getWishlistCount = () => {
  //   return wishlistItems.reduce((qty, item) => Number(item.qty) + qty, 0)
  // }

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0)
  }

  const _handleLogout = () => {
    dispatch(setInitialState())
    logout()
    // history.replace('/')
    toast.success("Logout is successful", {
      position: "top-right",
    });
  }

  return (
    <div className="header-action-right">
      <div className="header-action-2">
        {/* <div className="header-action-icon-2">
          <a href="/wishlist">
            <Image src="../images/theme/icons/icon-heart.svg" className="svgInject" rounded/>
            <span className="pro-count blue">{getWishlistCount()}</span>
          </a>
        </div> */}
        <div className="header-action-icon-2">
          <a className="mini-cart-icon" href="/cart">
            <Image src="/images/theme/icons/icon-cart.svg" rounded/>
            <span className="pro-count blue">{getCartCount()}</span>
          </a>
        </div>
        <div className="header-action-icon-2">
          <Image src="/images/theme/icons/icon-user.svg" className="svgInject" rounded style={{cursor: 'pointer'}}/>
          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
            {!user.userInfo.isLogin ? (
            <ul>
              <li><Link to="/signin">Login</Link></li>
            </ul>
            ) : (
              <ul>
                <li><a href={`/my-account/${user.userInfo.details._id}`}><i className="fi fi-rs-user mr-10"></i>My Account</a></li>
                {user.userInfo.details.isAdmin &&
                  <li><a href="/admin/#dashboard"><i className="fi fi-rs-user mr-10"></i>Admin</a></li>
                }
                {/* <li><a href="/orders"><i className="fi fi-rs-location-alt mr-10"></i>Order Tracking</a></li> */}
                {/* <li><a href="/voucher"><i className="fi fi-rs-label mr-10"></i>My Voucher</a></li> */}
                {/* <li><a href="/wishlist"><i className="fi fi-rs-heart mr-10"></i>My Wishlist</a></li> */}
                <li><a href="/settings"><i className="fi fi-rs-settings-sliders mr-10"></i>Setting</a></li>
                <li><a href="/" onClick={_handleLogout}><i className="fi fi-rs-sign-out mr-10"></i>Sign out</a></li>
              </ul>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Headeractions;