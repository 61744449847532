import * as actionTypes from "../constants/orderProductConstants";
import { Api } from '../../utils/Api';
import { toast } from "react-toastify";

export const fetchOrderProduct = () => async dispatch => {
  try {
    const {data: strigifyData} = await Api.getRequest(`/api/orderProducts/`)
    const orderProducts  = JSON.parse(strigifyData)
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCTS_SUCCESS,
      payload: orderProducts,
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
}

export const addOrderProduct = (orderProduct) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_ORDER_PRODUCT,
    payload: {
      orderId: orderProduct.orderId,
      productId: orderProduct.productId,
      unit: orderProduct.unit,
      productPrice: orderProduct.price,
      productQuantity: orderProduct.qty
    },
  })

  Api.postRequest('/api/orderProducts', orderProduct);
  toast.success("Order is created", {
    position: "top-right",
  });
}

export const updateOrderProduct = (id, orderProduct) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.UPDATE_ORDER_PRODUCT,
      payload: {
        orderId: orderProduct.orderId,
        productId: orderProduct.productId,
        unit: orderProduct.unit,
        productPrice: orderProduct.price,
        productQuantity: orderProduct.qty
      },
    })
    Api.putRequest(`/api/orderProducts/${id}`, orderProduct)
    .then(response => {
      dispatch(getOrderProductsWithOrderId());
      toast.success("Order item is updated", {
        position: "top-right",
      });
    });
  } catch(error) {
    dispatch({
      type: actionTypes.UPDATE_ORDER_PRODUCT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getOrderProductDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ORDER_PRODUCT_DETAILS_REQUEST})

    const {data} = await Api.getRequest(`/api/orderProducts/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCT_DETAILS_SUCCESS,
      payload: {
        ...p,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderProductsWithOrderId = (orderId) => async dispatch => {
  try {
    const { data: strigifyData} = await Api.getRequest(`/api/orderProducts/orderProductsWithOrderId/${orderId}`);
    const orderProducts  = JSON.parse(strigifyData);
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCTS_WITH_ORDER_SUCCESS,
      payload: orderProducts,
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
}