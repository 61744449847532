import * as actionTypes from '../constants/productConstants'
import {Api} from '../../utils/Api'
import { toast } from "react-toastify";

export const addProduct = (product) => async dispatch => {
  Api.postRequest('/api/products', product).then(() => {
    dispatch(getProducts());
  });
  toast.success("Product is created", {
    position: "top-right",
  });
}

export const updateProduct = (id, product) => async dispatch => {
  Api.putRequest(`/api/products/${id}`, product).then(() => {
    dispatch(getProducts());
  });
  toast.success("Product is updated", {
    position: "top-right",
  });
}

export const getProducts = () => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_PRODUCTS_REQUEST});
    const {data} = await Api.getRequest('/api/products');
    dispatch({
      type: actionTypes.GET_PRODUCTS_SUCCESS,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductsByCategoryId = (categoryId) => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_PRODUCTS_REQUEST_WITH_CATEGORY});
    const { data } = await Api.getRequest('/api/products?categoryId=' + categoryId);
    dispatch({
      type: actionTypes.GET_PRODUCTS_SUCCESS_WITH_CATEGORY,
      payload: JSON.parse(data),
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_PRODUCTS_FAIL_WITH_CATEGORY,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_PRODUCT_DETAILS_REQUEST})

    const {data} = await Api.getRequest(`/api/products/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS,
      payload: {
        ...p,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeProductDetails = (id) => async dispatch => {
  try {
    await Api.DeleteRequest(`/api/products/${id._id}`)
    .then((response) => {
      dispatch(getProducts());
      toast.success("Product is deleted", {
        position: "bottom-left",
      });
    });
  } catch (error) {
    console.log(error)
  }
}
