import List from '../../admin/users/list'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUsers as listUsers} from '../../redux/actions/userAction'

function AdminCustomer() {
  const dispatch = useDispatch()
  const getUsers = useSelector(state => state.getUsers)
  const { users } = getUsers;
  
  useEffect(() => {
    dispatch(listUsers())
  }, [dispatch])

  return (
    <div className="tab-pane" id="customer" role="tabpanel" aria-labelledby="customer-tab">
      <div className="row">
        { users && users.length > 0 &&
          <List users={users} />
        }
      </div>
    </div>
  )
}

export default AdminCustomer;