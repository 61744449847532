import React, { Suspense } from 'react';
import Main from '../components/js/Main'
import { Code } from 'react-content-loader'

const HomeScreen = () => {
  const MyCodeLoader = () => <Code />
  return (
    <Suspense fallback={<MyCodeLoader/>}>
      <Main />
    </Suspense>
  )
}

export default HomeScreen
