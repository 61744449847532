import '../css/Main.css'
import Image from 'react-bootstrap/Image';
import Headeractions from './Headeractions';
import { getCategories as listCategories } from '../../redux/actions/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// import Dropdown from 'react-bootstrap/Dropdown';
import Search  from './Search';
// import useZipcode from '../../utils/hooks/useZipcode'; 


function Middleheader() {
  const dispatch = useDispatch();
  const getCategories = useSelector(state => state.getCategories)
  const { categories } = getCategories;
  // const { zipcode } = useZipcode();

  useEffect(() =>{
    dispatch(listCategories())
  }, [dispatch])

  return (
    <div className="header-middle header-middle-ptb-1">
      <div className="container">
        <div className="header-wrap">
          <div className="logo logo-width-1">
            <a href="/"><Image src="/images/theme/logo.svg" rounded /></a>
          </div>
          <div className="header-right">
            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
              <nav>
                <ul>
                  {/* <li className="hot-deals">
                    <a href="shop-grid-right.html">
                      <div className="row">
                        <div className="col-3 float-right m-0 p-0">
                          <Image src="../images/theme/icons/icon-hot.svg" />
                        </div>
                        <div className="col-4 float-left m-0 pl-0">
                          Deals
                        </div>
                      </div>
                    </a>
                  </li> */}
                  <li className="position-static">
                    <span style={{cursor: 'pointer', fontSize: '16px'}}>Shop By Category<i className="fi-rs-angle-down"></i></span>
                    <ul className="mega-menu">
                      { categories.filter(category => category.type === 'category').map(category => (
                        <li className="sub-mega-menu sub-mega-menu-width-50" key={category._id}>
                          <a className="menu-title" href={`/products?categoryId=${category._id}`}>
                            <div className='row'>
                              <div className='col-3 float-end'>
                                <img
                                  src="/images/theme/icons/category-1.svg"
                                  height="24px"
                                  width="24px"
                                  style={{ verticalAlign: "middle"}} alt="" />
                              </div>
                              <div className='col-9'>
                                &nbsp;&nbsp;
                                {category.name}
                              </div>
                            </div>
                          </a>
                        </li>
                      )
                      )}
                    </ul>
                  </li>
                  {/* <li className="position-static">
                    <span style={{cursor: 'pointer', fontSize: '16px'}}>Shop by Solution <i className="fi-rs-angle-down"></i></span>
                    <ul className="mega-menu">
                      { categories.filter(category => category.type === 'solution').map(category => (
                        <li className="sub-mega-menu sub-mega-menu-width-50" key={category._id}>
                          <a className="menu-title" href={`/products?categoryId=${category._id}`}>
                            <div className='row'>
                              <div className='col-3 float-end'>
                                <img
                                  src="../images/theme/icons/category-11.svg"
                                  height="24px"
                                  width="24px"
                                  style={{ verticalAlign: "middle"}} alt="" />
                              </div>
                              <div className='col-9'>
                                &nbsp;&nbsp;
                                {category.name}
                              </div>
                            </div>
                          </a>
                        </li>
                      )
                      )}
                    </ul>
                  </li> */}
                  <li>
                    <a href='/know-your-oil'>Know Your Oil</a>
                  </li>
                  <li>
                    <a href="/our-story">Our Story</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div style={{zIndex: '999'}} >
              <Search />
            </div>
            {/* { !zipcode.loading && (
              <div style={{cursor: "pointer", fontWeight: 'bold', color: '#253D4E'}}>
                Diliver to <i className="fi-rs-angle-down"></i>
                <br/>
                <small style={{fontSize: "12px"}}>
                  { zipcode.zipCode.firstName && (
                    <span>
                      {zipcode.zipCode.firstName}
                    </span>
                    )
                  }
                  {zipcode.zipCode.City}
                </small>
              </div>
            )} */}
            <Headeractions />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Middleheader;