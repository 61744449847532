import './Cart.css'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getReviewsByProductId } from "../../redux/actions/reviewActions"
import { convertToTotalRating } from "../../utils/utils.function";

const CartItem = ({ item, itemInfo, selectedCartItems, qtyChangeHandler, removeHandler, handleChange }) => {
  const dispatch = useDispatch();
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const { reviews } = getReviews;
  const totalRating = convertToTotalRating(reviews);
  const [qty, setQty] = useState(0);
  const getCartSubTotal = () => { return item.price * Number(qty).toFixed(2); };

  useEffect(() => {
    setQty(item.qty);
    dispatch(getReviewsByProductId(item.productId))
  }, [dispatch, item]);

  const isChecked = (item) => {
    return selectedCartItems.includes(item)
  }

  return (
    <tr className="pt-30">
      <td className="custome-checkbox pl-30">
        <input
          className="form-check-input"
          type="checkbox"
          name="items"
          checked={isChecked(item)}
          id={item.productId}
          value={item.productId}
          onChange={(e) => handleChange(e) } />
        <label className="form-check-label" htmlFor={item.productId}></label>
      </td>
      <td className="image product-thumbnail pt-40">
        <Link to={`/product/${item.productId}`} className="product-name mb-10 text-heading">
          <img src={item.imageUrl} alt={item.name} />
        </Link>
      </td>
      <td className="product-des product-name">
        <h6 className="mb-5">
          <Link to={`/product/${item.productId}`} className="product-name mb-10 text-heading">
            {item.productName}
          </Link>
        </h6>
        <div className="product-rate-cover">
          <div className="product-rate d-inline-block">
            <div className="product-rating" style={{ width: `${totalRating/5 * 100}%` }}></div>
          </div>
          <span className="font-small ml-5 text-muted"> ({totalRating})</span>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h4 className="text-body">&#8377; {item.price}</h4>
      </td>
      <td className="text-center detail-info" data-title="Stock">
        <div className="radius" style={{width: "90px", border: "none"}}>
          <select
            value={qty}
            onChange={(e) => [qtyChangeHandler(item, e.target.value, item.unit, item.price), setQty(e.target.value)]}
            className="cartItem__select">
            {[...Array(item.countInStock).keys()].map((x) => (
              (x < 6) && (
                <option key={x + 1} value={x + 1}>
                  {x + 1}
                </option>
              )
            ))}
          </select>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h4 className="text-brand">&#8377;{getCartSubTotal()} </h4>
      </td>
      <td className="action text-center" data-title="Remove">
        <span
          className="text-body"
          onClick={() => removeHandler(item)}
          style={{cursor: 'pointer'}}>
          <i className="fi-rs-trash"></i>
        </span>
      </td>
    </tr>
  );
};

export default CartItem;
