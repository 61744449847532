import { combineReducers } from 'redux'
import { configureStore } from "@reduxjs/toolkit";
// import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'

// Reducers
import { cartReducer } from './reducers/cartReducers'
import { wishlistReducer } from './reducers/wishlistReducers'
import {
  getProductsReducer,
  getProductsWithCategoryIdReducer,
  getProductDetailsReducer,
} from './reducers/productReducers'
import { userReducer, getUsersReducer, getUserDetailsReducer, getUserReducer } from './reducers/userReducer';
import { getCategoriesReducer, getCategoryDetailsReducer } from './reducers/categoryReducers';
import { getOrderDetailsReducer, getOrdersReducer } from './reducers/orderReducers';
import { getOrderProductsReducer, getOrderProductDetailsReducer, getOrderProductsWithOrderIdReducer} from './reducers/orderProductReducers';
import { getAdvertisementsReducer, getAdvertisementDetailsReducer } from './reducers/advertisementReducers';
import { getReviewsReducer, getReviewDetailsReducer, getReviewsByProductIdReducer } from './reducers/reviewReducers'
import { getSubscriptionReducer } from './reducers/subscriptionReducers';
import { getZipcodeReducer, getShippingRocketCostReducer, getShippingRocketTokenReducer } from './reducers/zipcodeReducers';
import { getAddressDetailsReducer, getAddressesReducer } from './reducers/addressReducers'
import { getOrderTrackersReducer, getOrderTrackerDetailsReducer, getOrderTrackersWithOrderIdReducer } from './reducers/orderTrackerReducers';

const reducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  getUsers: getUsersReducer,
  getUserDetails: getUserDetailsReducer,
  getUser: getUserReducer,
  wishlist: wishlistReducer,
  getProducts: getProductsReducer,
  getProductsWithCategoryId: getProductsWithCategoryIdReducer,
  getProductDetails: getProductDetailsReducer,
  getCategories: getCategoriesReducer,
  getCategoryDetails: getCategoryDetailsReducer,
  getOrders: getOrdersReducer,
  getOrderProductsWithOrderId: getOrderProductsWithOrderIdReducer,
  getOrderDetails: getOrderDetailsReducer,
  getOrderProducts: getOrderProductsReducer,
  getOrderProductDetails: getOrderProductDetailsReducer,
  getAdvertisements: getAdvertisementsReducer,
  getAdvertisementDetails: getAdvertisementDetailsReducer,
  getReviews: getReviewsReducer,
  getReview: getReviewDetailsReducer,
  getReviewsByProductId: getReviewsByProductIdReducer,
  getSubscriptions: getSubscriptionReducer,
  getZipcode: getZipcodeReducer,
  getgetShippingRocketToken: getShippingRocketTokenReducer,
  getShippingRocketCost: getShippingRocketCostReducer,
  getAddresses: getAddressesReducer,
  getAddressDetails: getAddressDetailsReducer,
  getOrderTrackers: getOrderTrackersReducer,
  getOrderTackerDetails: getOrderTrackerDetailsReducer,
  getOrderTrackerWithOrderId: getOrderTrackersWithOrderIdReducer
})

// const middleware = [thunk]

const cartItemsInLocalStorage = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : []

const wishlistInLocalStorage = localStorage.getItem('wishlist')
  ? JSON.parse(localStorage.getItem('wishlist')) : []

const INITIAL_STATE = {
  cart: {
    cartItems: cartItemsInLocalStorage,
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
  },
  wishlist: {
    wishlistItems: wishlistInLocalStorage,
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
  },
}

const store = configureStore({
  reducer,
  INITIAL_STATE,
})

export default store
