import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Code } from 'react-content-loader';

// // Components
import CartItem from "./CartItem";
import CheckoutButton from "./CheckoutButton";

// Actions
import { addToCart, removeFromCart, clearCart, fetchCart } from "../../redux/actions/cartActions";

function CartPage() {
  const MyCodeLoader = () => <Code />;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems, loading, error } = cart;
  const [selectAll, setSelectAll] = useState(true);
  const [selectedCartItems, setSelectedCartItems] = useState(cartItems);

  useEffect(() => {
    dispatch(fetchCart())
  }, [dispatch])

  const [itemInfo, setItemInfo] = useState({
    items: cartItems.map((item) => item.productId),
  });

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectAll(true);
      setItemInfo({
        items: cartItems.map((item) => item.productId),
      });
    } else {
      setSelectAll(false);
      setItemInfo({
        items: [],
      });
    }
  };
  
  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { items } = itemInfo;
    if (checked) {
      setItemInfo({
        items: [...items, value],
      });
    }
    else {
      setItemInfo({
        items: [...items.filter((e) => e !== value)],
      });
    }
  };
  
  useEffect(() => {
    if (itemInfo.items.length === cartItems.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    const currentItems = cartItems.filter((item) => itemInfo.items.includes(item.productId));
    setSelectedCartItems([...currentItems]);
  }, [itemInfo, setSelectAll, cartItems]);

  const qtyChangeHandler = (item, qty, unit, price) => {
    dispatch(addToCart(item, qty, unit, price));
  };

  const removeFromCartHandler = (item) => {
    dispatch(removeFromCart({ pId: item.productId, _id: item._id }));
  };

  const getCartCount = () => {
    return selectedCartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const getTotal = () => {
    return selectedCartItems
      .reduce((price, item) => (price + item.price * item.qty), 0)
      .toFixed(2);
  }

  return (
    <>
    {loading ? (
      <MyCodeLoader />
    ) : error ? (
      <h2>{error}</h2>
    ) : (
      <>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="/" rel="nofollow"><i className="fi-rs-home mr-5"></i>Home</a>
              <span></span> <a href="/products">Shop</a>
              <span></span> Cart
            </div>
          </div>
        </div>
        <div className="container mb-80 mt-50">
          <div className="row">
            <div className="col-lg-8 mb-40">
              <h1 className="heading-2 mb-10">Your Cart</h1>
              {cartItems.length === 0 ? (<div></div>) : (
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">There are <span className="text-brand">{getCartCount()}</span> products in your cart</h6>
                  <h6 className="text-body">
                    <span className="text-muted" onClick={() => handleClearCart()} style={{ cursor: 'pointer' }}>
                      <i className="fi-rs-trash mr-5"></i>Clear Cart
                    </span>
                  </h6>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="table-responsive shopping-summery">
                {cartItems.length === 0 ? (
                  <div>
                    Your Cart Is Empty <Link to="/">Go Back</Link>
                  </div>
                ) : (
                  <table className="table table-wishlist">
                    <thead>
                      <tr className="main-heading">
                        <th className="custome-checkbox start pl-30">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="selectAll"
                            id="exampleCheckbox11"
                            value="All"
                            checked={selectAll}
                            onChange={handleSelectAll} />
                          <label className="form-check-label" htmlFor="exampleCheckbox11"></label>
                        </th>
                        <th scope="col" colSpan="2">Product</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col" className="end">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCartItems && cartItems.map((item, index) => (
                        <CartItem
                          key={index}
                          item={item}
                          selectedCartItems={selectedCartItems}
                          itemInfo={itemInfo}
                          qtyChangeHandler={qtyChangeHandler}
                          removeHandler={() => removeFromCartHandler(item)}
                          handleChange={handleChange} />
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              {cartItems.length !== 0 &&
                <>
                  <div className="divider-2 mb-30"></div><div className="cart-action d-flex justify-content-between">
                    <a className="btn " href="/products"><i className="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
                    <span className="btn  mr-10 mb-sm-15"><i className="fi-rs-refresh mr-10"></i>Update Cart</span>
                  </div>
                </>}
            </div>
            {cartItems.length !== 0 &&
              <div className="col-lg-4">
                <div className="border p-md-4 cart-totals ml-30">
                  <div className="table-responsive">
                    <table className="table no-border">
                      <tbody>
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="text-muted">Total</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h4 className="text-brand text-end">&#8377;{getTotal()}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CheckoutButton cartItems={selectedCartItems} total={getTotal()} />
                </div>
              </div>}
          </div>
        </div>
      </>
    )}
    </>
  )
}

export default CartPage;