import { useState } from 'react';
import {useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addAdvertisement } from '../../redux/actions/advertisementActions'
import { getAdvertisements as listAdvertisements } from '../../redux/actions/advertisementActions'
import { useHistory } from "react-router-dom";

function NewAdvertisement() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [screenType, setScreenType] = useState("");
  const navigate = useHistory();

  const ScreenTypes = [
    {value: 'homeslider', text: 'HomeSlider'},
    {value: 'homebanner', text: 'HomeBanner'},
  ];

  const handleScreenImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addAdvertisement({
        description,
        imageUrl: imageUrl,
        screenType,
      })
    );
    handleClose();
    dispatch(listAdvertisements());
    navigate.push("/");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add New Advertisement
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Advertisement</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Upload files <span className="required">*</span></label>
                    <input
                      id="imgUpload"
                      name='file'
                      accept="image/*"
                      type="file"
                      className="button-upload"
                      onChange={handleScreenImageUpload}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Screen Type <span className="required">*</span></label>
                    <select onChange={(e) => setScreenType(e.target.value)} required>
                      <option value="">Select Screen type</option>
                      {ScreenTypes.map(item => (
                        <option value={item.value} key={item.value}>{item.text}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label>Description <span className="required">*</span></label>
                    <input
                      type="text"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </> 
  )
}

export default NewAdvertisement;