// import axios from "axios";
// import { config } from "../../utils/config";
// import {createCheckoutSession} from "../redux/actions/stripeActions"
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


function CheckoutButton({cartItems, total}) {
  const navigate = useHistory();
  // const cart = useSelector((state) => state.cart);
  // const user = useSelector((state) => state.user);
  // const { cartItems } = cart;
    
  // const handleProceedBtn = async () => {
  //   axios
  //   .post(`${config.baseURL}/api/stripe/create-checkout-session`, {
  //     userId: user.userInfo.details._id,
  //     cartItems,
  //   })
  //   .then((response) => {
  //     if (response.data.url) {
  //       window.location.href = response.data.url;
  //     }
  //   })
  //   .catch((err) => console.log(err.message));
  // }

  return (
    <div>
      <button
        className="btn mb-20 w-100"
        title="Functionality need to be add."
        // onClick={handleProceedBtn}
        disabled={(cartItems && cartItems.length === 0) || (Number(total) < 500)}
        onClick={() => navigate.push(`/checkout`)}
      >
        Proceed To Checkout<i className="fi-rs-sign-out ml-15"></i>
      </button>
      { Number(total) < 500 ? (
        <h6 style={{margin: "0 0 40px 40px", color: 'red'}}>Min order value Rs. 500.00</h6>
      ) : (<></>)}
    </div>
  )
}

export default CheckoutButton;