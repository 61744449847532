import * as actionTypes from "../constants/orderTrackerConstants";
import { Api } from '../../utils/Api';
import { toast } from "react-toastify";

export const fetchOrderTracker = () => async dispatch => {
  try {
    const {data: strigifyData} = await Api.getRequest(`/api/orderTrackers/`)
    const orderTrackers  = JSON.parse(strigifyData)
    dispatch({
      type: actionTypes.GET_ORDER_TRACKERS_SUCCESS,
      payload: orderTrackers,
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
};
  
export const addOrderTracker = (orderTracker) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_ORDER_TRACKER,
    payload: {
      orderId: orderTracker.orderId,
      status: orderTracker.status
    },
  })

  Api.postRequest('/api/orderTrackers', orderTracker);
  toast.success("Order tracker is updated", {
    position: "top-right",
  });
}

export const updateOrderTracker = (id, orderTracker) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.UPDATE_ORDER_TRACKER,
      payload: {
        orderId: orderTracker.orderId,
        status: orderTracker.status,
      },
    })
    Api.putRequest(`/api/orderProducts/${id}`, orderTracker)
    .then(response => {
      dispatch(getOrderTrackersWithOrderId());
      toast.success("Order item is updated", {
        position: "top-right",
      });
    });
  } catch(error) {
    dispatch({
      type: actionTypes.UPDATE_ORDER_TRACKER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getOrderTrackerDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ORDER_TRACKER_DETAILS_REQUEST})

    const {data} = await Api.getRequest(`/api/orderTrackers/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_ORDER_TRACKER_DETAILS_SUCCESS,
      payload: {
        ...p,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDER_TRACKER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderTrackersWithOrderId = (orderId) => async dispatch => {
  try {
    const { data: strigifyData} = await Api.getRequest(`/api/orderTrackers/order/${orderId}`);
    const orderTrackers  = JSON.parse(strigifyData);
    dispatch({
      type: actionTypes.GET_ORDER_TRACKERS_WITH_ORDER_SUCCESS,
      payload: orderTrackers,
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
}