import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import './Admin.css';
import Tracker from './Tracker'
import { addOrderTracker, getOrderTrackersWithOrderId } from '../../redux/actions/orderTrackerActions';

const Statuses = [
  {value: 'placed', text: 'Placed'},
  {value: 'processed', text: 'Processed'},
  {value: 'shipped', text: 'Shipped'},
  {value: 'delivered', text: 'Delivered'},
];

function Updatetatus({order}) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch()
  const navigate = useHistory();
  const getOrderTrackers = useSelector(state => state.getOrderTrackerWithOrderId);
  const { orderTrackers } = getOrderTrackers;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('');
  const completedSteps = () => {
    return orderTrackers ? orderTrackers.map((tracker) => tracker.status ) : [] ;
  }
  const orderStatuses = completedSteps() ? Statuses.filter((status) => !completedSteps().includes(status.value)) : [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addOrderTracker({
        orderId: order._id,
        status: status
      })
    );
    handleClose();
    navigate.push("/admin/#orders");
  };

  useEffect(() => {
    dispatch(getOrderTrackersWithOrderId(order._id));
  }, [dispatch, order]);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className='btn btn-secondary btn-sm print ms-2'>
        Update Status
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Order Status</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-60">
                  <div className='row '>
                    { completedSteps && <Tracker statuses={Statuses} steps={(completedSteps())} />}
                  </div>
                  <div className="form-group col-md-12 mt51">
                    {/* <label>Status <span className="required">*</span></label> */}
                    <select onChange={(e) => setStatus(e.target.value)} required className="form-control">
                      <option value="">Select Status</option>
                      {orderStatuses.map((status, index) => (
                        <option value={status.value} key={status.value} disabled={index !== 0}>{status.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Updatetatus;