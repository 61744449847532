export const GET_ZIPCODE_DETAILS_REQUEST = "GET_ZIPCODE_DETAILS_REQUEST";
export const GET_ZIPCODE_DETAILS_SUCCESS = "GET_ZIPCODE_DETAILS_SUCCESS";
export const GET_ZIPCODE_DETAILS_FAIL = "GET_ZIPCODE_DETAILS_FAIL";
export const GET_ZIPCODE_DETAILS_RESET = "GET_ZIPCODE_DETAILS_RESET";

export const GET_SHIPPING_ROCKET_TOKEN_REQUEST = "GET_SHIPPING_ROCKET_TOKEN_REQUEST";
export const GET_SHIPPING_ROCKET_TOKEN_SUCCESS = "GET_SHIPPING_ROCKET_TOKEN_SUCCESS";
export const GET_SHIPPING_ROCKET_TOKEN_FAIL = "GET_SHIPPING_ROCKET_TOKEN_FAIL";
export const GET_SHIPPING_ROCKET_TOKEN_RESET = "GET_SHIPPING_ROCKET_TOKEN_RESET";

export const GET_SHIPPING_ROCKET_COST_REQUEST = "GET_SHIPPING_ROCKET_COST_REQUEST";
export const GET_SHIPPING_ROCKET_COST_SUCCESS = "GET_SHIPPING_ROCKET_COST_SUCCESS";
export const GET_SHIPPING_ROCKET_COST_FAIL = "GET_SHIPPING_ROCKET_COST_FAIL";
export const GET_SHIPPING_ROCKET_COST_RESET = "GET_SHIPPING_ROCKET_COST_RESET";


