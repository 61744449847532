import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../redux/actions/cartActions';
import { getReviewsByProductId } from "../../redux/actions/reviewActions";
import { convertToTotalRating } from "../../utils/utils.function";
import Image from 'react-bootstrap/Image';
import { toast } from "react-toastify";

function ProductDetails({ imageUrl, categoryName, categoryId, price, name, productId, countInStock, sku }) {
  const dispatch = useDispatch();
  const getReviews = useSelector(state => state.getReviewsByProductId);
  const { reviews } = getReviews;
  const totalRating = convertToTotalRating(reviews);
  const defaultSku = sku.filter((item)=> item.defaultSku);
  const unit = defaultSku[0] ? `${defaultSku[0].value} ${defaultSku[0].unit}` : ''
  const qty = 1;

  imageUrl = imageUrl ? imageUrl : '../logo192.png'

  const addToCartHandler = () => {
    if (Number(countInStock) === 0) {
      toast.success("Product is out of stock now", {
        position: "top-right",
      });
    } else {
      const cart = {
        productId: productId,
        productName: name,
        imageUrl: imageUrl,
        price: price,
        countInStock: countInStock,
        qty: qty,
        unit: unit,
      }
      dispatch(addToCart(cart, qty, unit, price))
      return;
    }
  }

  useEffect(() => {
    dispatch(getReviewsByProductId(productId))
  }, [dispatch, productId]);


  return (
    <div className="col-lg-6-5 col-md-4 col-12 col-sm-6">
      <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <a href={`/product/${productId}`}>
              <Image src={imageUrl} className="default-img" />
              <Image src={imageUrl} className="hover-img" />
            </a>
          </div>
          <div className="product-action-1">
            <a href={`/product/${productId}`}
              aria-label="Quick view"
              className="action-btn"
              data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></a>
          </div>
          <div className="product-badges product-badges-position product-badges-mrg">
          </div>
        </div>
        <div className="product-content-wrap">
          <div className="product-category">
            <a href={`/products?categoryId=${categoryId._id}`}>{categoryName}</a>
          </div>
          <h2><a href={`/product/${productId}`}>{name}</a></h2>
          <div className="product-rate-cover">
            <div className="product-rate d-inline-block">
              <div className="product-rating" style={{ width: `${totalRating/5 * 100}%` }}></div>
            </div>
            <span className="font-small ml-5 text-muted"> ({totalRating})</span>
          </div>
          <div className="product-card-bottom">
            <div className="product-price">
              <span style={{fontSize: "16px"}}>M.R.P: &#8377; {price}</span>
            </div>
            <div className="add-cart">
              <span className="add" onClick={addToCartHandler} style={{ cursor: 'pointer' }}>
                <i className="fi-rs-shopping-cart mr-5"></i>Add
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetails;