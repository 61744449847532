import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import './Admin.css';

import { updateOrder } from '../../redux/actions/orderActions';

function UploadInvoice({order, history}) {
  const dispatch = useDispatch()
  const navigate = useHistory();
  const [show, setShow] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");

  const handleInvoiceUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setInvoiceUrl(reader.result);
      };
    } else {
      setInvoiceUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateOrder(order._id, {
        invoiceUrl
      })
    );
    handleClose();
    navigate.push("/admin/#orders");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className='btn btn-secondary btn-sm print ms-2'>
        Upload Invoice
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Invoice</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-20">
                  <div className="form-group col-md-12">
                    <label>Upload files <span className="required">*</span></label>
                    <input
                      id="imgUpload"
                      name='file'
                      accept="pdf/*"
                      type="file"
                      className="button-upload"
                      onChange={handleInvoiceUpload}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UploadInvoice;