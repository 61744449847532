import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/actions/cartActions";

const OrderPlaced = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);

  return (
    <div className="container" style={{minHeight: "300px", margin: "200px 0 0 0"}}>
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <div className="alert alert-success text-center">
            <h4 className="alert-heading">Order successfully placed</h4>
          </div>
          <a href='/'>Back to Home</a>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced
