import './Products.css'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getProducts as listProducts } from '../../redux/actions/productActions'
import { getProductsByCategoryId as listProductsByCategoryId } from '../../redux/actions/productActions'
import { getCategories as listCategories, getCategoryDetails } from '../../redux/actions/categoryActions'
import ProductList from './list'
import { Code } from 'react-content-loader';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
const pageItems = [
  "50",
  "100",
  "150",
  "200",
  "All"
];
const pageSorts = [
  "Featured",
  "Price: Low to High",
  "Price: High to Low",
  "Release Date",
  "Avg. Rating"
]

function Products() {
  const MyCodeLoader = () => <Code />;
  const dispatch = useDispatch();
  const searchParams = useQuery();
  const categoryId = searchParams.get('categoryId');

  const getProducts = useSelector(state => state.getProducts);
  const getProductsWithCategoryId = useSelector(state => state.getProductsWithCategoryId);
  const { products, loading, error } = categoryId ? getProductsWithCategoryId : getProducts;

  const getCategories = useSelector(state => state.getCategories)
  const { categories } = getCategories;

  const categoryDetails = useSelector(state => state.getCategoryDetails)
  const category = categoryDetails.category;

  const [itemCount, setItemCount] = useState("50");
  const [itemSort, setItemSort] = useState("Featured");

  console.log(itemSort);

  useEffect(() => {
    if (categoryId) {
      dispatch(listProductsByCategoryId(categoryId))
    } else {
      dispatch(listProducts())
    }
  }, [dispatch, categoryId])

  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategoryDetails(categoryId))
    }
  }, [dispatch, categoryId])

  return (
    <div>
      { category && category.imageUrl &&
        <div className="container">
          <div
            className="archive-header"
            style={{backgroundImage: `url(${category.imageUrl})`, height: "300px"}}>
          </div>
        </div>
       }
      <div className="container mt-30">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mt-10 mb-10">{ categoryId && category ? category.name : '' }</h2>
            <div className="shop-product-fillter">
              <div className="totall-product">
                <p>We found <strong className="text-brand">{products.length}</strong> items for you!</p>
              </div>
              <div className="sort-by-product-area">
                <div className="sort-by-cover mr-10">
                  <div className="sort-by-product-wrap">
                    <div className="sort-by">
                      <span><i className="fi-rs-apps"></i>Show:</span>
                    </div>
                    <select onChange={(e) => setItemCount(e.target.value)} required style={{ padding: "0", border: "none", fontSize: "14px", marginTop: "6px", marginBottom: "7px"}}>
                      {pageItems.map(item => (
                        <option value={item} key={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sort-by-cover">
                  <div className="sort-by-product-wrap">
                    <div className="sort-by" style={{width: "100px"}}>
                      <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                    </div>
                    <select onChange={(e) => setItemSort(e.target.value)} required style={{ padding: "0", border: "none", fontSize: "14px", marginTop: "6px", marginBottom: "7px"}}>
                      {pageSorts.map(item => (
                        <option value={item} key={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <MyCodeLoader />
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <div className="row product-grid">
                { products.length > 0 && categories.length > 0 &&
                  <ProductList products={products} categories={categories} itemcount={itemCount} />
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products;