import './Tracker.css'

function Tracker({statuses, steps}) {

  const isComplete = (status) => {
    return steps.includes(status.value)
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 hh-grayBox pt45 pb20">
            <div className="row justify-content-between">
              { statuses.map((status, index) =>
                <div key={index} className={isComplete(status) ? "order-tracking completed" : "order-tracking" }>
                  <span className="is-complete"></span>
                  <p>{status.text}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tracker;