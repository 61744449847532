import * as actionTypes from '../constants/cartConstants'
import { Api } from '../../utils/Api'
import { toast } from "react-toastify";
import { convertToCartData } from '../../utils/utils.function';

export const addToCart = (item, qty, unit, price) => async dispatch => {
  const cart = {
    productId: item.productId,
    productName: item.productName,
    imageUrl: item.imageUrl,
    price: price,
    countInStock: item.countInStock,
    qty: qty,
    unit: unit,
  }

  dispatch({type: actionTypes.ADD_TO_CART, payload: cart});
  toast.success(`${item.productName} is added to cart`, {
    position: "top-center",
  });
 await Api.postRequest('/api/cart', { productId: cart.productId, count: qty, unit: unit }).then(() =>{
    dispatch(fetchCart());
  })
}

export const removeFromCart =
  ({pId, _id}) =>
  dispatch => {
    dispatch({
      type: actionTypes.REMOVE_FROM_CART,
      payload: pId,
    })
    if (_id) {
      Api.DeleteRequest('/api/cart/' + _id).then(() =>{
        dispatch(fetchCart());
      })
    }
  }

export const fetchCart = () => async dispatch => {
  try {
    const {data: strigifyData} = await Api.getRequest(`/api/cart/`)
    const {carts} = JSON.parse(strigifyData);
    carts.length > 0 && dispatch({
      type: actionTypes.FETCH_MY_CART,
      payload: {
        carts: convertToCartData(carts),
      },
    })
  } catch (e) {
    console.log('EROROR :  ', e)
  }
}

export const clearCart = () => async dispatch => {
  dispatch({
    type: actionTypes.CLEAR_MY_CART,
    payload: [],
  })
  await Api.DeleteRequest(`/api/cart/`)
  fetchCart();
}