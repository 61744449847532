import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AdminDashboard from './dashboard';
import AdminCustomer from './customer';
import AdminCategories from './categories'
import AdminOrders from './orders';
import AdminProducts from './products';
import AdminTransactions from './transactions';
import AdminSales from './sales';
import AdminFulfilment from './fulfilment'
import AdminAdvertisement from "./advertisement"
import './Admin.css'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function AdminHome() {
  const location = useLocation()
  const { hash } = location;
  return (
    <section className="newsletter mb-15 mt-40">
      <div className="container">
        <div className="container">
        <div className="row">
        <div className="col-lg-12 m-auto">
          <Tab.Container id="left-tabs-example" defaultActiveKey={hash}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#dashboard" eventKey="#dashboard"><i className="fi-rs-settings-sliders mr-10"></i>Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#customers" eventKey="#customers"><i className="fi-rs-shopping-bag mr-10"></i>Customers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#categories" eventKey="#categories"><i className="fi-rs-shopping-bag mr-10"></i>Categories</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#orders" eventKey="#orders"><i className="fi-rs-marker mr-10"></i>Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#products" eventKey="#products"><i className="fi-rs-user mr-10"></i>Products</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#transactions" eventKey="#transactions"><i className="fi-rs-user mr-10"></i>Transactions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#sales" eventKey="#sales"><i className="fi-rs-user mr-10"></i>Sales</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#fulfilments" eventKey="#fulfilments"><i className="fi-rs-user mr-10"></i>Fulfilments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/admin/#advertisements" eventKey="#advertisements"><i className="fi-rs-user mr-10"></i>Advertisements</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="ninth"><i className="fi-rs-sign-out mr-10"></i>Logout</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="#dashboard"><AdminDashboard /></Tab.Pane>
                <Tab.Pane eventKey="#customers"><AdminCustomer /></Tab.Pane>
                <Tab.Pane eventKey="#categories"><AdminCategories /></Tab.Pane>
                <Tab.Pane eventKey="#orders"><AdminOrders /></Tab.Pane>
                <Tab.Pane eventKey="#products"><AdminProducts /></Tab.Pane>
                <Tab.Pane eventKey="#transactions"><AdminTransactions /></Tab.Pane>
                <Tab.Pane eventKey="#sales"><AdminSales /></Tab.Pane>
                <Tab.Pane eventKey="#fulfilments"><AdminFulfilment /></Tab.Pane>
                <Tab.Pane eventKey="#advertisements"><AdminAdvertisement /></Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        </div></div>
        </div>
      </div>
    </section>
  )
}

export default AdminHome;