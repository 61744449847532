// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-header {
  display: flex;
  justify-content: space-between;
}

.primary-button {
  padding: 9px 12px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/admin/CommonStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".admin-header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.primary-button {\n  padding: 9px 12px;\n  border-radius: 5px;\n  font-weight: 400;\n  letter-spacing: 1.15px;\n  background-color: #4b70e2;\n  color: #f9f9f9;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  margin: 0.5rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
