
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

//Actions
import {getProducts as listProducts} from '../../redux/actions/productActions'

import SideNav from '../SideNav';
import Listproducts from './listproducts';

const Products = () => {
  const dispatch = useDispatch()
  const getProducts = useSelector(state => state.getProducts)
  const {products } = getProducts;
  
  useEffect(() => {
    dispatch(listProducts())
  }, [dispatch])

  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className='content'>
        { products.length > 0 &&
          <Listproducts products={products} />
        }
      </div>
    </div>
  )  
}

export default Products;