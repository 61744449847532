import Image from "react-bootstrap/Image"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAdvertisements as listAdvertisements } from '../../redux/actions/advertisementActions'
import { Code } from 'react-content-loader';

 function Homebanner() {
  const MyCodeLoader = () => <Code />;
  const dispatch = useDispatch();
  const getAdvertisements = useSelector(state => state.getAdvertisements)
  const { advertisements, loading, error } = getAdvertisements;

  useEffect(() =>{
    dispatch(listAdvertisements())
  }, [dispatch])

  return (
    <section className="banners mb-25">
      <div className="container">
        {loading ? (
        // <h2>Loading...</h2>
          <MyCodeLoader />
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <div className="row">
            { advertisements.length > 0 && advertisements.filter(addvertisement => addvertisement.screenType === 'homebanner').map(advertisement => (
              <div className="col-lg-4 col-md-6" key={advertisement._id}>
                <div className="banner-img wow animate__animated animate__fadeInUp animated"
                  data-wow-delay="0">
                  <Image src={`${advertisement.imageUrl}`} />
                  {/* <div className="banner-text">
                    <h4>{advertisement.description}</h4>
                    <a href="/products" className="btn btn-xs">Shop Now <i className="fi-rs-arrow-small-right"></i></a>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
 }

 export default Homebanner