import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
//Actions
import { getAddresses as listAddresses, updateAddress } from '../../redux/actions/adressActions'

import NewAddress from './NewAddress';
import AddressList from './AddressList';

function MyAddress({userId}) {
  const dispatch = useDispatch();
  const getAddresses = useSelector(state => state.getAddresses);
  const { addresses } = getAddresses;
  const [defaultAddressId, setDefaultAddressId] = useState('');

  useEffect(() => {
    dispatch(listAddresses(userId));
  }, [dispatch, userId]);

  const handleDefaultAddress = async (e) => {
    e.preventDefault();
    const address = addresses.filter(item => item._id === defaultAddressId)[0]
    address && dispatch(
      updateAddress(address._id, {
        userId: userId,
        firstName: address.firstName,
        lastName: address.lastName,
        street: address.street,
        state: address.state,
        city: address.city,
        email: address.email,
        pin: address.pin,
        phone: address.phone,
        houseNumber: address.houseNumber,
        addressType: address.addressType,
        defaultAddress: true
      })
    );
  };

  const selectedAddressId = (id) => {
    setDefaultAddressId(id)
  }
  
  return (
    <>
      <div className="tab-pane" id="address" role="tabpanel" aria-labelledby="address-tab">
        <div className="row my-4">
          <div className="col-3">
            <NewAddress />
          </div>
          { addresses.length > 0 &&
            <div className='col-3'>
              <Button variant="primary" onClick={handleDefaultAddress}>
                Set as default
              </Button>
            </div>
          }
        </div>
        { addresses.length > 0 &&
          <AddressList addresses={addresses}
            handleDefaultAddress={handleDefaultAddress}
            selectedAddressId={selectedAddressId} />
        }
      </div>
    </>
  )
}

export default MyAddress;