import * as actionTypes from "../constants/zipcodeConstants"

export const getZipcodeReducer = (state = { zipcode: {} }, action) => {
  switch (action.type) {
  case actionTypes.GET_ZIPCODE_DETAILS_REQUEST:
    return {
      loading: true,
    };
  case actionTypes.GET_ZIPCODE_DETAILS_SUCCESS:
    return {
      loading: false,
      zipcode: action.payload,
    };
  case actionTypes.GET_ZIPCODE_DETAILS_FAIL:
    return {
      loading: false,
      error: action.payload,
    };
  case actionTypes.GET_ZIPCODE_DETAILS_RESET:
    return {
      zipcode: {},
    };
  default:
    return state;
  }
};

export const getShippingRocketTokenReducer = (state = { shippingRocketToken: {} }, action) => {
  switch (action.type) {
  case actionTypes.GET_SHIPPING_ROCKET_TOKEN_REQUEST:
    return {
      loading: true,
    };
  case actionTypes.GET_SHIPPING_ROCKET_TOKEN_SUCCESS:
    return {
      loading: false,
      shippingRocketToken: action.payload,
    };
  case actionTypes.GET_SHIPPING_ROCKET_TOKEN_FAIL:
    return {
      loading: false,
      error: action.payload,
    };
  case actionTypes.GET_SHIPPING_ROCKET_TOKEN_RESET:
    return {
      shippingRocketToken: {},
    };
  default:
    return state;
  }
};


export const getShippingRocketCostReducer = (state = { shippingRocketCost: {} }, action) => {
  switch (action.type) {
  case actionTypes.GET_SHIPPING_ROCKET_COST_REQUEST:
    return {
      loading: true,
    };
  case actionTypes.GET_SHIPPING_ROCKET_COST_SUCCESS:
    return {
      loading: false,
      shippingRocketCost: action.payload,
    };
  case actionTypes.GET_SHIPPING_ROCKET_COST_FAIL:
    return {
      loading: false,
      error: action.payload,
    };
  case actionTypes.GET_SHIPPING_ROCKET_COST_RESET:
    return {
      shippingRocketCost: {},
    };
  default:
    return state;
  }
};