import * as actionTypes from "../constants/reviewConstants"

export const getReviewsReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
      case actionTypes.GET_REVIEWS_REQUEST:
        return {
          loading: true,
          reviews: [],
        };
      case actionTypes.GET_REVIEWS_SUCCESS:
        return {
          reviews: action.payload.reviews,
          loading: false,
        };
      case actionTypes.GET_REVIEWS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
export const getReviewDetailsReducer = (state = { review: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_REVIEW_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_REVIEW_DETAILS_SUCCESS:
      return {
        loading: false,
        review: action.payload,
      };
    case actionTypes.GET_REVIEW_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_REVIEW_DETAILS_RESET:
      return {
        review: {},
      };
    default:
      return state;
  }
};

export const getReviewsByProductIdReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_REVIEWS_BY_PRODUCT_ID_REQUEST:
      return {
        loading: true,
        reviews: [],
      };
    case actionTypes.GET_REVIEWS_BY_PRODUCT_ID_SUCCESS:
      return {
        reviews: action.payload.reviews,
        loading: false,
      };
    case actionTypes.GET_REVIEWS_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
