import * as actionTypes from '../constants/subscriptionConstants'
import {Api} from '../../utils/Api'


export const addSubscribe = (subscription) => async dispatch => {
    dispatch({
      type: actionTypes.ADD_SUBSCRIPTION,
      payload: {
        email: subscription.email,
      },
    })
  
    Api.postRequest('/api/subscriptions', subscription)
  }

  export const getSubscriptions = () => async dispatch => {
    try {
      dispatch({type: actionTypes.GET_SUBSCRIPTIONS_REQUEST})
  
      const {data} = await Api.getRequest('/api/subscriptions')
      console.log('data', JSON.parse(data));
      dispatch({
      type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
      payload: JSON.parse(data),
      })
    } catch (error) {
      dispatch({
      type: actionTypes.GET_SUBSCRIPTIONS_FAIL,
      payload:
          error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      })
    }
  }
    