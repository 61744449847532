import {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getCategoryDetails } from "../../redux/actions/categoryActions";
import EditCategoryForm from "../../screens/Admin/EditCategoryForm";

function EditCategory({match, history}) {
  const dispatch = useDispatch()
  const categoryDetails = useSelector(state => state.getCategoryDetails)
  const { loading, error, category} = categoryDetails;
  useEffect(() => {
      dispatch(getCategoryDetails(match.params.id))
  }, [dispatch, match])

  return (
    <>
      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <EditCategoryForm category={category} history={history}/>
      )}
    </>
  )
}

export default EditCategory;