import React, { useEffect, useState } from 'react';
import { Api } from '../../utils/Api'
import { cashfree } from '../../utils/cashfreeUtil'
import { useParams } from 'react-router-dom';

const Cashfree = ({user, paymentMethod, addresses, cartItems, subtotal, total, billingAddress}) => {
  const params = useParams();
  const isSessionId = params.sessionid
  const [loading, setLoading] = useState('');
  const [sessionId, setSessionId] = useState('');
  const version = cashfree.version();
  const selectedDefaultAddress = addresses.filter(item => item.defaultAddress)
  const selectedAddress = selectedDefaultAddress.length > 0 ? selectedDefaultAddress[0] : addresses[0];

  const getSessionId = async(e) =>{
    e.preventDefault();
    setLoading(true);
    const shippingCost = Number(total).toFixed(2) - Number(subtotal).toFixed(2);
    const cartSubTotal = Number(subtotal).toFixed(2);
    await Api.postRequest('/api/orders', {
      user: user.userInfo.details || 11111111,
      paymentMethod,
      products: cartItems,
      subtotal: cartSubTotal,
      total: total,
      shipping: selectedAddress || billingAddress,
      shippingCost: Number(shippingCost),
      currency: 'INR',
      orderNote: 'Its my first order',
    }).then((res) =>{
      const data = JSON.parse(res.data);
      Api.postRequest('/api/cashfree/payment', {
        user: user.userInfo.details,
        paymentMethod,
        shipping: selectedAddress,
        order: data.record,
        version: version
      }).then((res)=>{
        setLoading(false);
        setSessionId(res.data);
      })
      .catch((err)=>{
        setLoading(false);
        console.log(err);
      })
    })
  }

  const handlePayment = ()=>{
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${process.env.REACT_APP_API_BASE_URL}/api/cashfree/status/{order_id}`,
        
    }   
    cashfree.checkout(checkoutOptions).then(function(result){
      if(result.error){
        alert(result.error.message);
      }
      if(result.redirect){
        console.log("Redirection")
        console.log(result);
      }
    });
  }

  useEffect(()=>{
    setSessionId(isSessionId)
  }, [isSessionId])

  return (
    <>
      <div className='col-6'>
        <form onSubmit={getSessionId}>
          {!loading || sessionId?
            <div className='center'>
              <button
                disabled={!paymentMethod}
                className="btn btn-fill-out btn-block mt-10"
                type="submit">Place an order<i className="fi-rs-sign-out ml-15"></i>
              </button>
            </div>
          :
            <div className='center'>
              <button className='text-center' type="submit">
              <div className="spinner-border" role="status">
                <span className="visually-hidden ">Wait...</span>
              </div>
              </button>
            </div>
          }
        </form>
      </div>
      <div className='col-4 bottom'>
        { sessionId &&
          <div className='center' style={{marginTop: '2.5rem'}}>
            <button type="submit" onClick={handlePayment}>Pay Now</button>
          </div>
          }
      </div>
    </>
  )
}

export default Cashfree
