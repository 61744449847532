// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-menu {
    padding: "0";
    border: "none";
    font-size: "14px";
    margin-top: "6px";
    margin-bottom: "7px";
}


/* .slider-wrapper {
    width: 760px;
    margin: auto;
  }
  .slick-slide {
    text-align: center;
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #777777;
  } */


  .nav-tabs li.nav-item button {
    display: block;
    padding: 13px 24px !important;
    text-align: center;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    text-transform: none;
    font-size: 17px;
    border-radius: 30px;
    border: 1px solid #ececec;
    background: #fff;
    color: #7E7E7E;
}`, "",{"version":3,"sources":["webpack://./src/screens/Products/Products.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;AACxB;;;AAGA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAiCK;;;EAGH;IACE,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,oCAAoC;IACpC,oBAAoB;IACpB,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".sort-menu {\n    padding: \"0\";\n    border: \"none\";\n    font-size: \"14px\";\n    margin-top: \"6px\";\n    margin-bottom: \"7px\";\n}\n\n\n/* .slider-wrapper {\n    width: 760px;\n    margin: auto;\n  }\n  .slick-slide {\n    text-align: center;\n    position: relative;\n  }\n  .slick-slide:focus {\n    outline: none;\n  }\n  .slick-slide-title {\n    text-transform: capitalize;\n  }\n  .slick-slide-image {\n    max-width: 100%;\n    height: auto;\n    border-radius: 8px;\n    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),\n      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);\n  }\n  .slick-slide-label {\n    color: #fff;\n    padding: 10px;\n    position: absolute;\n    left: 0px;\n    font-size: 1.5em;\n    bottom: 0px;\n    width: 100%;\n  }\n  .slick-prev:before,\n  .slick-next:before {\n    color: #777777;\n  } */\n\n\n  .nav-tabs li.nav-item button {\n    display: block;\n    padding: 13px 24px !important;\n    text-align: center;\n    font-weight: 700;\n    font-family: \"Quicksand\", sans-serif;\n    text-transform: none;\n    font-size: 17px;\n    border-radius: 30px;\n    border: 1px solid #ececec;\n    background: #fff;\n    color: #7E7E7E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
