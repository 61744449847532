// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-dashboard {
  display: flex;
  height: 70vh;
}

.side-nav {
  border-right: 1px solid gray;
  height: calc(70vh);
  position: fixed;
  overflow-y: auto;
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.side-nav h3 {
  margin: 0 0 1rem 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 17px;
}

.side-nav a {
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 14px;
}

.content {
  margin-left: 200px;
  padding: 2rem 3rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/admin/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".styled-dashboard {\n  display: flex;\n  height: 70vh;\n}\n\n.side-nav {\n  border-right: 1px solid gray;\n  height: calc(70vh);\n  position: fixed;\n  overflow-y: auto;\n  width: 200px;\n  display: flex;\n  flex-direction: column;\n  padding: 2rem;\n}\n\n.side-nav h3 {\n  margin: 0 0 1rem 0;\n  padding: 0;\n  text-transform: uppercase;\n  font-size: 17px;\n}\n\n.side-nav a {\n  text-decoration: none;\n  margin-bottom: 1rem;\n  font-size: 14px;\n}\n\n.content {\n  margin-left: 200px;\n  padding: 2rem 3rem;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
