
function ProductInfoTab({product}) {
  return (
    <div>
      <table className="font-md">
        <tbody>
          <tr className="stand-up">
              <th>Size</th>
            <td>
              <p>{product.size}</p>
            </td>
          </tr>
          <tr className="weight-wo-wheels">
            <th>Weight</th>
            <td>
              <p>{product.weight}</p>
            </td>
          </tr>
          <tr className="pa_color">
            <th>Color</th>
            <td>
              <p>{product.color}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ProductInfoTab;