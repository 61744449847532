import './Advertisements.css'
import SideNav from '../SideNav'
import Editform from './editform';
import {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getAdvertisementDetails } from "../../redux/actions/advertisementActions";

function EditAdvertisement({match, history}) {
  const dispatch = useDispatch()
  const advertisementDetails = useSelector(state => state.getAdvertisementDetails)
  const {loading, error, advertisement} = advertisementDetails;

  useEffect(() => {
    if (advertisement && match.params.id !== advertisement._id) {
      dispatch(getAdvertisementDetails(match.params.id))
    }
  }, [dispatch, match, advertisement])
  console.log(advertisement);

  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className='styled-created-product content'>
        {loading && !advertisement? (
          <h2>Loading...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <Editform advertisement={advertisement}/>
        )}
      </div>
    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
  )
}

export default EditAdvertisement;