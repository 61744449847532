import './Advertisements.css';
import { useState } from 'react';
import { useDispatch } from "react-redux";

import {updateAdvertisement} from "../../redux/actions/advertisementActions";

function Editform({advertisement}) {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState('');
  const [description, setDescription] = useState(advertisement.description);
  const [screenType, setScreenType] = useState(advertisement.screenType);

  const ScreenTypes = [
    { value: 'homeslider', text: 'HomeSlider' },
    { value: 'homebanner', text: 'HomeBanner' },
  ];

  const handleScreenImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateAdvertisement(advertisement._id, {
        description,
        imageUrl,
        screenType,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        id="imgUpload"
        name='file'
        accept="image/*"
        type="file"
        className="button-upload"
        onChange={handleScreenImageUpload}
        required
      />
      <input
        type="text"
        value={description}
        placeholder="Description"
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <select value={screenType} onChange={(e) => setScreenType(e.target.value)} required>
        <option value="">Select Screen type</option>
        {ScreenTypes.map(item => (
          <option value={item.value} key={item.value}>{item.text}</option>
        ))}
      </select>
      <button type="submit">
        Submit
      </button>
    </form>
  )
}

export default Editform;