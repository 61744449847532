

function AdminTransactions() {
    return (
      <div className="tab-pane" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
        <div className="row">
  
        </div>
      </div>
    )
  }
  
  export default AdminTransactions;