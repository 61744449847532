import * as actionTypes from '../constants/addressConstants';
import { Api } from '../../utils/Api'

export const addAddress = (address) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_ADDRESS,
    payload: {
      userId: address.userId,
      firstName: address.firstName,
      lastName: address.lastName,
      street: address.street,
      houseNumber: address.houseNumber,
      state: address.state,
      city: address.city,
      pin: address.pin,
      phone: address.phone,
      email: address.email,
      addressType: address.addressType
    },
  })
  Api.postRequest('/api/address', address).then(() => {
    dispatch(getAddresses());
  })
}

export const updateAddress = (id, address) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_ADDRESS,
    payload: {
      userId: address.userId,
      firstName: address.firstName,
      lastName: address.lastName,
      street: address.street,
      houseNumber: address.houseNumber,
      state: address.state,
      city: address.city,
      pin: address.pin,
      phone: address.phone,
      email: address.email,
      addressType: address.addressType,
      defaultAddress: address.defaultAddress
    },
  })
  Api.putRequest(`/api/address/${id}`, address).then(() => {
    dispatch(getAddresses());
  })
}

export const getAddresses = (userId) => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ADDRESSES_REQUEST})    
    const {data} = await Api.getRequest(`/api/address/list/${userId}`)
    dispatch({
      type: actionTypes.GET_ADDRESSES_SUCCESS,
      payload: JSON.parse(data).addresses,
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ADDRESSES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAddressDetails = id => async dispatch => {
  try {
    dispatch({type: actionTypes.GET_ADDRESS_DETAILS_REQUEST});
    const {data} = await Api.getRequest(`/api/address/${id}`)
    const p = JSON.parse(data)
    dispatch({
      type: actionTypes.GET_ADDRESS_DETAILS_SUCCESS,
      payload: {
        ...p,
      },
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ADDRESS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}