export const GET_ORDER_PRODUCTS_REQUEST = "GET_ORDER_PRODUCTS_REQUEST";
export const GET_ORDER_PRODUCTS_SUCCESS = "GET_ORDER_PRODUCTS_SUCCESS";
export const GET_ORDER_PRODUCTS_FAIL = "GET_ORDER_PRODUCTS_FAIL";

export const GET_ORDER_PRODUCT_DETAILS_REQUEST = "GET_ORDER_PRODUCT_DETAILS_REQUEST";
export const GET_ORDER_PRODUCT_DETAILS_SUCCESS = "GET_ORDER_PRODUCT_DETAILS_SUCCESS";
export const GET_ORDER_PRODUCT_DETAILS_FAIL = "GET_ORDER_PRODUCT_DETAILS_FAIL";
export const GET_ORDER_PRODUCT_DETAILS_RESET = "GET_ORDER_PRODUCT_DETAILS_RESET";

export const ADD_ORDER_PRODUCT = 'ADD_ORDER_PRODUCT'
export const UPDATE_ORDER_PRODUCT = 'UPDATE_ORDER_PRODUCT'
export const UPDATE_ORDER_PRODUCT_FAIL = 'UPDATE_ORDER_PRODUCT_FAIL';
export const FETCH_MY_ORDER_PRODUCT = 'FETCH_MY_ORDER_PRODUCT'

export const GET_ORDER_PRODUCTS_WITH_ORDER_REQUEST = "GET_ORDER_PRODUCTS_WITH_ORDER_REQUEST";
export const GET_ORDER_PRODUCTS_WITH_ORDER_SUCCESS = "GET_ORDER_PRODUCTS_WITH_ORDER_SUCCESS";
export const GET_ORDER_PRODUCTS_WITH_ORDER_FAIL = "GET_ORDER_PRODUCTS_WITH_ORDER_FAIL";