import Moment from 'react-moment';
import Button from "react-bootstrap/Button";
import { Code } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getOrderDetails } from '../../redux/actions/orderActions';
import { getOrderProductsWithOrderId as listOrderProducts } from '../../redux/actions/orderProductActions';
import CustomerDetails from './CustomerDetails';
import ShippingDetails from './ShippingDetails';
import UploadInvoice from '../Admin/UploadInvoice';
import UpdateStatus from '../Admin/UpdateStatus';
import { Link, useHistory } from "react-router-dom";

function OrderDetails({match, history}) {
  let uploadInvoiceButton = '';
  let updateStatusButton = ''
  const dispatch = useDispatch();
  const navigate = useHistory();
  const MyCodeLoader = () => <Code />
  const getOrderProducts = useSelector(state => state.getOrderProductsWithOrderId);
  const orderDetails = useSelector(state => state.getOrderDetails);
  const user = useSelector((state) => state.user);
  const { order, loading, error } = orderDetails;
  const { orderProducts } = getOrderProducts;

  useEffect(() => {
    dispatch(getOrderDetails(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    dispatch(listOrderProducts(match.params.id))
  }, [dispatch, match.params.id])

  if (order && user.userInfo.details.isAdmin) {
    uploadInvoiceButton  = order && order.hasOwnProperty("_id") && <UploadInvoice order={order}/>;
    updateStatusButton = order && order.hasOwnProperty("_id") && <UpdateStatus order={order} />;
  }

  const goBack = () => {
    navigate.goBack(-1);
  }

  return (
    <>
      { loading? (
        <MyCodeLoader />
      ) : error ? (
        <h2>{error}</h2>
      ) : ( 
        <div className="container mb-40 mt-40">
          <div className="row">
            <div>
              <h3 className="content-title card-title">Order detail</h3>
            </div>
          </div>
          <div className="card mt-40">
            <header className="card-header">
              <div className='col-lg-6 col-md-6 ms-auto text-md-end'>
                <Link to="#" onClick={goBack}>
                  <Button variant="outline-light" size="lg" className="btn btn-secondary btn-sm rounded font-sm ms-2" >
                    Back
                  </Button>
                </Link>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 mb-lg-0 mb-15">
                  <span><b>Order Palaced: <Moment format='MMMM Do YYYY, h:mm:ss a'>{order.createdAt}</Moment></b> </span> <br />
                  <small className="text-muted">Order ID: {order._id}</small>
                </div>
                <div className="col-lg-6 col-md-6 ms-auto text-md-end">
                  <a className="btn btn-secondary btn-sm print ms-2" href={order.invoiceUrl} target="_blank" rel="noreferrer">Download Invoice</a>
                  <a className="btn btn-secondary btn-sm print ms-2" href={order.invoiceUrl} target="_blank" rel="noreferrer">Print Invoice</a>
                </div>
              </div>
            </header>
            <div className="card-body">
              <div className="row mb-50 mt-20 order-info-wrap">
                { order && order.hasOwnProperty("userId") &&
                  <CustomerDetails customer={order.userId} shipping={order.shipping}/>
                }
                { order && order.hasOwnProperty("shipping") &&
                  <ShippingDetails shipping={order.shipping} />
                }
                <div className="col-md-4">
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-place"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Order Summary</h6>
                      <p className="mb-1">
                        Item(s) Subtotal: &#8377;{order.subtotal}<br />
                        Shipping: &#8377; {order.shippingCost}<br />
                        Total: &#8377; {order.total}<br />
                        <strong>Grand Total: &#8377; {order.total} </strong>
                      </p>
                    </div>
                  </article>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="15%">Product</th>
                          <th width="40%">Name</th>
                          <th width="13%">Unit Price</th>
                          <th width="12%">Quantity</th>
                          <th width="20%" className="text-end">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderProducts && orderProducts.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a className="itemside" href="/">
                                <div className="left">
                                  <img src={item.productId.imageUrl ? item.productId.imageUrl : '../logo192.png'} height="72px"  width="72px" className="img-xs" alt=''/>
                                </div>
                              </a>
                            </td>
                            <td className="text">{item.productId.name}</td>
                            <td>&#8377;{item.productPrice}</td>
                            <td>{item.productQuantity}</td>
                            <td className="text-end">&#8377;{Number(item.productQuantity).toFixed(2) * Number(item.productPrice).toFixed(2)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="5">
                            <article className="float-end">
                              <dl className="dlist text-md-end">
                                <dt>Subtotal: &#8377; {order.subtotal}</dt>
                              </dl>
                              <dl className="dlist text-md-end">
                                <dt>Shipping cost: &#8377; {order.shippingCost}</dt>
                              </dl>
                              <dl className="dlist text-md-end">
                                <dt>Grand total: &#8377; {order.total}</dt>
                              </dl>
                            </article>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 ms-auto text-md-end" >
                      {uploadInvoiceButton}
                      {updateStatusButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrderDetails;
