function PrivacyPolicy() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2 text-center">
                    <h2>Privacy Policy</h2>
                  </div>
                  <div className="single-content mb-50">
                    <p>Welcome to dhenki.in, operated by Dhenki Foods Private Limited. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share your information when you visit or make a purchase on our e-commerce website.</p>
                                        <h4>1. Personal Information We Collect</h4>
                    <h5>Device Information:</h5>
                    <p>When you visit our website, we automatically collect certain information about your device, such as your web browser, IP address, time zone, and cookies. We also gather data about the web pages or products you view and how you interact with our site.</p>
                    <p>We use technologies like cookies, log files, web beacons, tags, and pixels to collect this information.</p>
                    
                    <h5>Order Information:</h5>
                    <p>When you make a purchase or attempt to do so, we collect your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We use this information to fulfil your orders, process payments, arrange shipping, and provide order confirmations.</p>
                    <p>In this Privacy Policy, "Personal Information" refers to both Device Information and Order Information.</p>
                    
                    <h4>2. How We Use Your Personal Information</h4>
                    <h5>Device Information:</h5>
                    <p> <strong>We use the Order Information to:</strong></p>
                      <ul>
                        <li>Fulfil orders and provide related services.</li>
                        <li>Communicate with you.</li>
                        <li>Screen orders for potential risk or fraud.</li>
                      </ul>
                    <p className="mt-15">Provide information or advertising related to our products or services based on your preferences.</p>
                    <p><strong>We use Device Information to:</strong></p>
                      <ul>
                        <li>Screen for potential risk and fraud, including monitoring IP addresses.</li>
                        <li>Improve and optimize our website, generating analytics about customer browsing behaviour.</li>
                      </ul>
                    <p className="mt-15">Assess the success of our marketing and advertising campaigns.</p>
                    
                    <h4>3. Sharing Your Personal Information</h4>
                    <p>We share your Personal Information with third parties to assist in the services mentioned above. For example, we use Google Analytics to understand customer usage. Please refer to their privacy policies for more information:</p>
                    <p>Google Analytics: Google Privacy Policy</p>
                    <p>We may also disclose your Personal Information to comply with laws, respond to lawful requests, or protect our rights.</p>
                    
                    <h4>4. Behavioural Advertising</h4>
                    <p>We use your Personal Information to provide targeted advertisements or marketing communications that we believe may interest you. You can opt out of targeted advertising through the following links:</p>
                    <p>Facebook: Facebook Ad Settings</p>
                    <p>Google: Google Ad Settings</p>
                    <p>Bing: Bing Ad Personalized Ads</p>
                    <p>Digital Advertising Alliance: Opt-out Portal</p>
                    
                    <h4>5. Do Not Track</h4>
                    <p>Please note that we do not change our data collection practices when we receive a Do Not Track signal from your browser.</p>
                    
                    <h4>6. Your Rights</h4>
                    <p>If you are an Indian resident, you have the right to access, correct, update, or delete your personal information. To exercise this right, please contact us using the information below.</p>	
                                        
                    <h4>7. Data Retention</h4>
                    <p>We retain your Order Information for our records unless you request its deletion. </p>
                    
                    <h4>8. Changes to Privacy Policy</h4>
                    <p>We may update this Privacy Policy to reflect changes in our practices or for operational, legal, or regulatory reasons. Please check this page periodically for updates.</p>
                    
                    <h4>9. Contact Us</h4>
                    <p>If you have questions, concerns, or wish to make a complaint about our privacy practices, please contact us via email at <strong>dhenkifood@gmail.com</strong> or by mail at: <strong>Dhenki Foods Private Limited,
                      Sammilani Park, Kolkata-700075, Contact Number:  +91 8240379055</strong></p>
                    <p>Thank you for trusting us with your information.</p>
                  </div>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;