import './CommonStyles.css'
import './Dashboard.css'


import {
  NavLink,
} from 'react-router-dom';

function SideNav() {
  return (
    <div className='side-nav'>
    <h3>Quick Links</h3>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/summary"
    >
      Summary
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/products"
    >
      Products
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/categories"
    >
      Categories
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/orders"
    >
      Orders
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/users"
    >
      Users
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        isActive ? "link-active" : "link-inactive"
      }
      to="/admin/advertisements"
    >
      Advertisement
    </NavLink>
  </div>
  )
}

export default SideNav;