import * as actionTypes from "../constants/orderTrackerConstants";

export const getOrderTrackersReducer = (state = { orderTrackers: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_TRACKERS_REQUEST:
      return {
        loading: true,
        orderTrackers: [],
      };
    case actionTypes.GET_ORDER_TRACKERS_SUCCESS:
      return {
        orderTrackers: action.payload,
        loading: false,
      };
    case actionTypes.GET_ORDER_TRACKERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
    }
  };

  export const getOrderTrackersWithOrderIdReducer = (state = { orderTrackers: [] }, action) => {
    switch (action.type) {
      case actionTypes.GET_ORDER_TRACKERS_WITH_ORDER_REQUEST:
        return {
          loading: true,
          orderTrackers: [],
        };
      case actionTypes.GET_ORDER_TRACKERS_WITH_ORDER_SUCCESS:
        return {
          orderTrackers: action.payload,
          loading: false,
        };
      case actionTypes.GET_ORDER_TRACKERS_WITH_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
      }
    };
  
  export const getOrderTrackerDetailsReducer = (state = { orderTracker: {} }, action) => {
    switch (action.type) {
    case actionTypes.GET_ORDER_TRACKER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_ORDER_TRACKER_DETAILS_SUCCESS:
      return {
        loading: false,
        orderTracker: action.payload,
      };
    case actionTypes.GET_ORDER_TRACKER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ORDER_TRACKER_DETAILS_RESET:
      return {
        orderTracker: {},
      };
    default:
      return state;
    }
  };
  