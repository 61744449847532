
function AboutUs() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2 text-center">
                    <h2>About us</h2>
                    <div className="single-content mb-50">
                      <h4>Who We Are?</h4>
										  <p>Welcome to Dhenki Foods, where tradition meets nutrition. Our commitment goes beyond delivering traditional, minimally processed, high-quality food; we strive to uplift rural women and farmers across India. By choosing Dhenki, you indulge in a symphony of flavors and take part in nurturing a future where empowerment and culinary excellence blend harmoniously.</p>
                      <h4>What Do We Offer?</h4>
                      <h5><img src="../images/theme/icons/regenerative.png" width="24" height="24" alt="" />&nbsp;Reviving Tradition:</h5>
                      <p>Dhenki brings back the untainted flavors of traditional food culture, offering a rich culinary heritage experience.</p>
                      <h5><img src="../images/theme/icons/food-processing.png" width="24" height="24" alt="" />&nbsp;Minimally Processed Delights:</h5>
                      <p>Savor authentic goodness with Dhenki's minimally processed treats, preserving natural nutrients for a wholesome taste.</p>
                      <h5><img src="../images/theme/icons/farmer.png" width="24" height="24" alt="" />&nbsp;Direct from Farmers:</h5>
                      <p>Choose Dhenki to support sustainable farming practices, directly empowering local agricultural communities.</p>
                      <h5><img src="../images/theme/icons/quality-assurance.png" width="24" height="24" alt="" />&nbsp;Transparent Quality Assurance:</h5>
                      <p>With tech-enabled transparency, we prioritize transparency, ensuring that each product undergoes rigorous evaluation by our skilled food technologists</p>
                      <h4>How Do We Do It?</h4>
                      <div className="position-relative newsletter-inner">
                        <img src="../images/slider/Delivery_p.jpg" alt="newsletter" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;