import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addCategory } from '../../redux/actions/categoryActions';
import { useHistory } from "react-router-dom";
import './Admin.css';

const CategoryTypes = [
  { value: 'category', text: 'Category' },
  { value: 'solution', text: 'Solution' },
];

function NewCategory() {
  const dispatch = useDispatch()
  const navigate = useHistory();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [popular, setPopular] = useState(false);

  const handleScreenImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addCategory({
        name,
        type,
        imageUrl,
        popular
      })
    );
    handleClose();
    navigate.push("/admin/#categories");
  };
  const handleChange = (e) => {
    const { checked } = e.target;
    setPopular(checked);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add New Category
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Category</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row pb-20">
                  <div className="form-group col-md-12">
                    <label>Upload files <span className="required">*</span></label>
                    <input
                      id="imgUpload"
                      name='file'
                      accept="image/*"
                      type="file"
                      className="button-upload"
                      onChange={handleScreenImageUpload}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <select value={type} onChange={(e) => setType(e.target.value)} required>
                      <option value="">Shop by</option>
                      {CategoryTypes.map(item => (
                        <option value={item.value} key={item.value}>{item.text}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label>Name <span className="required">*</span></label>
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      required />
                  </div>
                  <div className="col-md-12">
                    <div className='col-md-4'>
                      <label >Set as Popular</label>
                    </div>
                    <div className='float-start'>
                      <input
                      type="checkbox"
                      onChange={(e) => handleChange(e)}/>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewCategory;