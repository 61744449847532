import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { fetchOrder as listOrders } from '../../redux/actions/orderActions'
import { useHistory } from "react-router-dom";

function Search() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const getOrders = useSelector(state => state.getOrders);
  const { orders } = getOrders;

  const convertToOrderSearchData = orders => {
    return orders.map(p => {
      return {
        id: p.id,
        name: p.id
      }
    })
  };
  const seachItems = convertToOrderSearchData(orders);
  useEffect(() => {
    dispatch(listOrders())
  }, [dispatch])

  const handleOnSearch = (string, results) => {
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    navigate.push(`/orders/${item.id}`)
    console.log(item)

  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', zIndex: '-131319999px' }} key={item.id}>{item.name}</span>
      </>
    )
  }

  return (
    <div style={{ width: "300px", marginBottom: "20px", height: '20px'}}>
      <ReactSearchAutocomplete
        items={seachItems}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        autoFocus
        formatResult={formatResult}
        showIcon={true}
        styling={{borderRadius: '0', height: '20'}}
      />
    </div>
  )
}

export default Search;