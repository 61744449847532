
function DescriptionTab({product}) {
  return (
    <div className="tab-pane fade show active" id="Description">
      <div className="">
        <div dangerouslySetInnerHTML={{__html: product.descriptionLong}} />
      </div>
    </div>
  )
}

export default DescriptionTab;