import './Dashboard.css'
import { useSelector } from "react-redux";
import  SideNav  from "./SideNav"

const Dashboard = () => {
  const user = useSelector((state) => state.user);

  if (!user.userInfo.details.isAdmin) return <p>Access denied. Not an Admin!</p>;

  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className="content">
      </div>
    </div>
  );
};

export default Dashboard;