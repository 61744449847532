import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
// import { useForm } from 'react-hook-form';
import { Api } from '../../utils/Api'
import { setToken } from '../../utils/localstorage'
import './signIn.css'
import {setUserDeatils} from '../../redux/actions/userAction';
import { toast } from "react-toastify";

function Index() {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  useEffect(() => {
    dispatch(setUserDeatils());
  }, [dispatch]);

  const _handleSubmit = useCallback(async () => {
    if (email.length > 2 && password.length > 2) {
      setLoading(true)
      const {statusCode, data} = await Api.postRequest('/api/users/signin', {
        email,
        password,
      })
      if (statusCode === 400 || statusCode === 500 || statusCode === 403) {
        setLoading(false)
        return
      }
      const {token} = JSON.parse(data)
      if (token) {
        setLoading(false)
        setToken(token)
        dispatch(setUserDeatils(true));
        replace('/');
        toast.success("Login is successful", {
          position: "top-right",
        });
      }
    }
  }, [dispatch, email, password, replace])

  if (loading) return <h1>Loading.....</h1>
  return (
    <div className="page-content pt-50 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
            <div className="row">
              <div className="col-lg-3 pr-30 d-none d-lg-block">
              </div>
              <div className="col-lg-6 col-md-8">
                <div className="login_wrap widget-taber-content background-white">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h1 className="mb-5">Login</h1>
                      <p className="mb-30">Don't have an account? <a href="/signup">Create here</a></p>
                    </div>
                    {/* <form onSubmit={handleSubmit((data) => _handleSubmit())}>
                      <input {...register('firstName')} />
                      <input {...register('lastName', { required: true })} />
                      {errors.lastName && <p>Last name is required.</p>}
                      <input {...register('age', { pattern: /\d+/ })} />
                      {errors.age && <p>Please enter number for age.</p>}
                      <input type="submit" />
                    </form> */}
                    <form method="post">
                      <div className="form-group">
                        <input type="text"
                          required=""
                          name="email"
                          placeholder="Username or Email *"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input required=""
                          type="password"
                          name="password"
                          placeholder="Your password *" 
                          value={password}
                          onChange={e => setPassword(e.target.value)}/>
                      </div>
                      <div className="login_footer form-group mb-50">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" value="" />
                            <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Remember me</span></label>
                          </div>
                        </div>
                        <a className="text-muted" href="/forgot-password">Forgot password?</a>
                      </div>
                      <div>
                        <input type='button'
                          className="btn"
                          name="login"
                          value="Log in"
                          style={{ padding: "20px 30px 40px 30px"}}
                          onClick={_handleSubmit} />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pr-30 d-none d-lg-block">     
              </div>        
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
