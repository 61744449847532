import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Moment from 'react-moment';
import Pagination from '../../pagination/Pagination';
import TrackOrder from './TrackOrder';
import Search from '../Orders/Search';

function OrderList({orders, setSortable}) {
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return JSON.parse(JSON.stringify(orders)).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, orders]);

  const Statuses = [
    {value: 'placed', text: 'Placed'},
    {value: 'processed', text: 'Processed'},
    {value: 'shipped', text: 'Shipped'},
    {value: 'delivered', text: 'Delivered'},
  ];

  return (
    <>
      <div className="container mb-40 mt-40">
        <div className="row mb-10">
          <div className="col-lg-4 col-md-6 mb-20" style={{zIndex: '9999'}}>
            {/* <input type="text" placeholder="Search..." className="form-control" /> */}
            <Search />
          </div>
          <div className="col-lg-4 col-md-4">
            <select onChange={(e) => setSortable(e.target.value)}  className="form-select md-6" defaultValue="Not Yet Shipped" >
              {Statuses.map((status, index) => (
                <option value={status.value} key={status.value}>{status.text}</option>
              ))}
            </select>
          </div>
        </div>
        {orders.length > 0 && currentTableData.length > 0 && currentTableData.map((order, index) => (
          <div className="card mb-4" key={index}>
            <header className="card-header">
              <div className="row gx-3 align-middle">
                <div className="col-md-3 mb-3">
                  <h6 className="mb-0">ORDER No:</h6>
                  <p>{order.id}</p>
                </div>
                <div className="col-md-3 mb-3">
                  <h6 className="mb-0">ORDER PLACED:</h6>
                  <p><Moment format='MMMM Do YYYY, h:mm:ss a'>{order.createdAt}</Moment></p>
                </div>
                <div className="col-md-3 mb-3">
                  <h6 className="mb-0">TOTAL:</h6>
                  <p>&#8377; {order.total.toFixed(2)}</p>
                </div>
                <div className="col-md-3 mb-3">
                  <Link to={`/orders/${order.id}`}>
                    <Button variant="outline-light" size="lg" className="btn btn-secondary btn-sm rounded font-sm ms-2" >
                      Details
                    </Button>
                  </Link>
                  { order.id && <TrackOrder order={order} /> }
                </div>
              </div>
            </header>
          </div>
        ))}
        <Pagination
          key={Date.now()}
          id={Date.now()}
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={orders.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)} />
      </div>
    </>
  )
}

export default OrderList;