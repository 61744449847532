import EditProductform from "../../screens/Admin/EditProductForm"
import { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails } from "../../redux/actions/productActions";
import { Code } from 'react-content-loader';

const EditProduct = ({match, history}) => {
  const MyCodeLoader = () => <Code />
  const dispatch = useDispatch();
  const productDetails = useSelector(state => state.getProductDetails);
  const {loading, error, product} = productDetails;

  useEffect(() => {
    if (product && match.params.id !== product._id) {
      dispatch(getProductDetails(match.params.id))
    }
  }, [dispatch, match, product])

  return (
    <div className='container'>
      {loading && !product? (
         <MyCodeLoader />
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <EditProductform
            product={product} history={history}
        />
      )}
    </div>
  )
}

export default EditProduct;