import './Products.css'
import { useSelector, useDispatch } from "react-redux";
import { addProduct } from "../../redux/actions/productActions";
import { getCategories as listCategories } from '../../redux/actions/categoryActions';
import { useState, useEffect } from "react";
import SideNav from '../SideNav';
import TextEditor from '../TextEditor';

function ProductNew() {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [countInStock, setCountInStock] = useState("");

  const handleProductImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
    TransformFilesData(e.target.files);
  };

  const TransformFilesData = (files) => {
    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      console.log(files[i]);
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        console.log(reader.result)
        imageFiles.push(reader.result);
      }
    }
    setImages(imageFiles);
  }

  const TransformFileData = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    } else {
      setImageUrl("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addProduct({
        name,
        categoryId,
        price,
        shortDescription,
        longDescription,
        imageUrl: imageUrl,
        images: images,
        countInStock,
      })
    );
  };

  const getCategories = useSelector(state => state.getCategories)
  const {categories } = getCategories

  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className='styled-created-product content'>
        <form onSubmit={handleSubmit}>
          <input
            id="imgUpload"
            name='file'
            multiple
            accept="image/*"
            type="file"
            onChange={handleProductImageUpload}
            required
          />
          <select onChange={(e) => setCategoryId(e.target.value)} required>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option value={category._id} key={category._id}>{category.name}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="number"
            placeholder="Price"
            onChange={(e) => setPrice(e.target.value)}
            required
          />
          <input
            type="number"
            placeholder="Count in Stock"
            onChange={(e) => setCountInStock(e.target.value)}
            required
          />
          <TextEditor name={"shortDescription"} value={shortDescription} onChange={setShortDescription} />
          <TextEditor name={"longDescription"} value={longDescription} onChange={setLongDescription} />
 
          <button type="submit">
            Submit
          </button>
        </form>
        <div className='image-preview'>
          {imageUrl ? (
            <>
              <img src={imageUrl} alt="error!" />
            </>
          ) : (
            <p>Product image upload preview will appear here!</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductNew;