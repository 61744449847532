export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const GET_CATEGORY_DETAILS_REQUEST = "GET_CATEGORY_DETAILS_REQUEST";
export const GET_CATEGORY_DETAILS_SUCCESS = "GET_CATEGORY_DETAILS_SUCCESS";
export const GET_CATEGORY_DETAILS_FAIL = "GET_CATEGORY_DETAILS_FAIL";
export const GET_CATEGORY_DETAILS_RESET = "GET_CATEGORY_DETAILS_RESET";

export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
