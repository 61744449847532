import '../css/Homeslider.css'
import Carousel from 'react-bootstrap/Carousel';
import Image from "react-bootstrap/Image"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAdvertisements as listAdvertisements } from '../../redux/actions/advertisementActions'
import { getProducts as listProducts } from '../../redux/actions/productActions';
import { Code } from 'react-content-loader';

function Homeslider() {
  const MyCodeLoader = () => <Code />;
  const dispatch = useDispatch();
  const getAdvertisements = useSelector(state => state.getAdvertisements)
  const { advertisements, loading, error } = getAdvertisements;

  useEffect(() => {
    dispatch(listProducts())
  }, [dispatch])


  useEffect(() =>{
    dispatch(listAdvertisements())
  }, [dispatch]);

  return (
    <section className="home-slider position-relative mb-30">
      {loading ? (
      // <h2>Loading...</h2>
        <MyCodeLoader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <Carousel data-bs-theme="dark" slide={false} indicatorLabels={["1", "2"]}>
          { advertisements.length > 0 && advertisements.filter(advertisement => advertisement.screenType === 'homeslider').map(advertisement => (
            <Carousel.Item key={advertisement._id}>
              <Image src={`${advertisement.imageUrl}`} />
              <Carousel.Caption>
                <div>
                  <h1 className="display-2" style={{marginBottom: "180px"}}>
                    {advertisement.description}
                  </h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </section>
  )
}

export default Homeslider;