function BillingDetails({addresses, setPincode}) {
  const selectedDefaultAddress = addresses.filter(item => item.defaultAddress)
  const selectedAddress = selectedDefaultAddress.length > 0 ? selectedDefaultAddress[0] : addresses[0];

  return (
    <div className="row">
      <h4 className="mb-30">Add address</h4>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>First name<span className="required">*</span></label>
            <input
              type="text"
              disabled
              required=""
              name="firstName"
              value={selectedAddress.firstName}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Last name<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="lastName"
              value={selectedAddress.lastName}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>House number<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="houseNumber"
              value={selectedAddress.houseNumber}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Street<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="street"
              value={selectedAddress.street}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>City<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="city"
              value={selectedAddress.city}/>
          </div>
          <div className="form-group col-lg-6">
            <label>State<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="state"
              value={selectedAddress.state}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>Pin<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="pin"
              value={selectedAddress.pin}
              onChange={(e) => setPincode(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Phone<span className="required">*</span></label>
            <input
              type="text"
              required=""
              disabled
              name="phone"
              value={selectedAddress.phone}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>Email</label>
            <input
              type="text"
              required=""
              disabled
              name="email"
              value={selectedAddress.email}/>
          </div>
          <div className="form-group col-lg-6">
          </div>
        </div>
    </div>
  )
}

export default BillingDetails;