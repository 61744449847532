// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-pills .nav-link.active {
    color: #fff;
    background-color: #3BB77E;
    border-radius: 10px;
}

.nav-pills .nav-link {
    border: #3BB77E 2px;
    font-size: 16px;
    color: #7E7E7E;
    padding: 15px 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
}

.nav-link {
    background-color: transparent;
}

form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

input[type=submit] {
    /* background: #fff; */
    border: 1px solid #ececec;
    height: 64px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    width: 26%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Account/Account.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,UAAU;AACd","sourcesContent":[".nav-pills .nav-link.active {\n    color: #fff;\n    background-color: #3BB77E;\n    border-radius: 10px;\n}\n\n.nav-pills .nav-link {\n    border: #3BB77E 2px;\n    font-size: 16px;\n    color: #7E7E7E;\n    padding: 15px 30px;\n    font-family: \"Quicksand\", sans-serif;\n    font-weight: 700;\n}\n\n.nav-link {\n    background-color: transparent;\n}\n\nform {\n    display: flex;\n    flex-direction: column;\n    margin-top: 2rem;\n}\n\ninput[type=submit] {\n    /* background: #fff; */\n    border: 1px solid #ececec;\n    height: 64px;\n    box-shadow: none;\n    padding-left: 20px;\n    font-size: 16px;\n    width: 26%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
