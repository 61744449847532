import { useState, useEffect } from 'react';

function BillingAddress({setBillingAddress, setPincode}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setPincode(pin);
    setBillingAddress({
      firstName: firstName,
      lastName: lastName,
      street: street,
      houseNumber: houseNumber,
      state: state,
      city: city,
      pin: pin,
      phone: phone,
      email: email
    })
  },[firstName, lastName, street, houseNumber, state, city, pin, phone, email, setPincode, setBillingAddress])

  return (
    <div className="row">
      <h4 className="mb-30">Add address</h4>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>First name<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Last name<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}/>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-lg-6">
            <label>House number<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="houseNumber"
              onChange={(e) => setHouseNumber(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Street<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="street"
              onChange={(e) => setStreet(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>City<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="city"
              onChange={(e) => setCity(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
            <label>State<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="state"
              onChange={(e) => setState(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>Pin<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="pin"
              onChange={(e) => setPin(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
            <label>Phone<span className="required">*</span></label>
            <input
              type="text"
              required=""
              name="phone"
              onChange={(e) => setPhone(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label>Email</label>
            <input
              type="text"
              required=""
              name="email"
              onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="form-group col-lg-6">
          </div>
        </div>
    </div>
  )
}

export default BillingAddress;