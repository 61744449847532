import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories as listCategories} from '../../redux/actions/categoryActions'
import List from '../../admin/categories/list';
import NewCategory from './NewCategory';
import { Code } from 'react-content-loader';

function AdminCategories() {
  const MyCodeLoader = () => <Code />;
  const dispatch = useDispatch()
  const getCategories = useSelector(state => state.getCategories)
  const { loading, error, categories } = getCategories;
  
  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <MyCodeLoader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
      <div className="tab-pane" id="category" role="tabpanel" aria-labelledby="category-tab">
        <div className='row my-3'>
          <div className='col-3'>
            <NewCategory />
          </div>
        </div>
        <div className="row">
          { categories && categories.length > 0 &&
            <List categories={categories} />
          }
        </div>
      </div>
      )}
    </>
  )
}

export default AdminCategories;