import { useEffect, useState   } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Api } from '../../utils/Api';
import { Link } from "react-router-dom";
import { getAddresses as listAddresses } from '../../redux/actions/adressActions'
import BillingDetails from "./BillingDetails";
import ShippingAddress from "./ShipingAddress";
import Cashfree from "../Cashfree/Payment";
import { useHistory } from "react-router-dom";
import BillingAddress from "./BillingAddress";
import _ from 'lodash';

const HowraPincodes = [
  "711101",
  "711102",
  "711103",
  "711104",
  "711106"
]

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [billingAddress, setBillingAddress] = useState({
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    state: '',
    city: '',
    pin: '',
    phone: '',
    email: ''
  })

  const getAddresses = useSelector(state => state.getAddresses);
  const cart = useSelector((state) => state.cart);
  const user = useSelector(state => state.user);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const { addresses } = getAddresses;
  const { cartItems } = cart;
  const buttonText = (addresses && addresses.length > 0) ? 'Ship to a different address?' : 'Add your Shipping Address to proceed';

  const validateBillingAddress = () => {
    return billingAddress.firstName
      && billingAddress.lastName
      && billingAddress.street
      && billingAddress.city
      && billingAddress.state
      && billingAddress.pin
      && billingAddress.phone
      && billingAddress.houseNumber
  }

  const calculateWeight = (item) => {
    const unit = item.unit.split(" ");
    const weight = ["grm", "ml"].includes(unit[1]) ? Number(unit[0])/1000 * item.qty : unit[0] * item.qty;
;    return weight;
  }

  const getTotalWeight = () => {
    return cartItems
      .reduce((weight, item) => weight + calculateWeight(item), 0)
  };

  const getCartSubTotal = () => {
    return cartItems
      .reduce((price, item) => price + item.price * item.qty, 0)
      .toFixed(2);
  };

  const totalCost = () => {
    return (Number(getCartSubTotal()) + Number(applyShippingCost())).toFixed(2);
  }

  const totalShippingCost = () => {
    const cost = Number(totalCost()) - Number(getCartSubTotal());
    return cost === 0 ? "Free" : cost.toFixed(2)
  }

  const placeAnOrder = async () => {
    await Api.postRequest('/api/orders', {
      user: user.userInfo.details,
      paymentMethod,
      products: cartItems,
      subtotal: Number(getCartSubTotal()),
      total: Number(totalCost()),
      shipping: selectedAddress || billingAddress,
      shippingCost: Number(totalShippingCost()),
      currency: 'INR',
      orderNote: 'Its my first order',
    }).then((res) =>{
      navigate.push("/order-placed");
    }).catch((error) => {
      navigate.push("/failure");
    })
  }

  const getZipCode = async (zipcode) => {
    await Api.postRequest('/api/zipcode', { zipcode: zipcode })
    .then((res) => {
      const zip = JSON.parse(res.data).zipcode
      const zipCode = {
        Zip: zip.Zip,
        StateCode: zip.StateCode,
        State: zip.State,
        CityCode: zip.CityCode,
        City: zip.City
      }
      setPostalAddress(zipCode);
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  const applyShippingCost = () => {
    if (!postalAddress.State) {
      return 0.00
    }
    const price = Number(getCartSubTotal());
    const weight = Math.ceil(getTotalWeight());
    if (postalAddress.State === "West Bengal") {
      if (_.startsWith(pincode, '700')) {
        return price < 1800 ? 40.00 : 0.00
      } else if (HowraPincodes.includes(pincode)) {
        return price  < 1800 ? 50.00 : 0.00
      } else if (weight >= 1){
        return weight * 50.00
      } else {
        return 50
      }
    } else {
      if (weight >= 1) {
        return weight * 60.00
      } else {
        return 60.00
      }
    }
  };

  useEffect(() => {
    if (user.userInfo.details.hasOwnProperty('_id')) {
      dispatch(listAddresses(user.userInfo.details._id));
    }
  }, [dispatch, user]);

  useEffect(() =>{
    const selectedDefaultAddress = addresses.filter(item => item.defaultAddress)
    const selectedAddress = selectedDefaultAddress && selectedDefaultAddress.length > 0 ? selectedDefaultAddress[0] : addresses[0];
    setSelectedAddress(selectedAddress);
    if (selectedAddress) {
      setPincode(selectedAddress.pin);
      getZipCode(selectedAddress.pin);
    } else if (pincode) {
      getZipCode(billingAddress.pin);
    }
  }, [addresses, selectedAddress, pincode, billingAddress.pin]);

  return (
    <div>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" rel="nofollow"><i className="fi-rs-home mr-5"></i>Home</a>
            <span></span><a href="/products">Shop</a>
            <span></span> Checkout
          </div>
        </div>
      </div>
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-8 mb-40">
            <h1 className="heading-2 mb-10">Checkout</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand">{cartItems.length}</span> products in your cart
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            { addresses && addresses.length > 0 &&
              <BillingDetails addresses={addresses} setPincode={setPincode} />
            }
            <div className="row">
              { !user.userInfo.isLogin &&
                <div>
                  <BillingAddress
                    setBillingAddress={setBillingAddress}
                    setPincode={setPincode} />
                </div>
              }
              { user.userInfo.isLogin &&
                <div className="col-6">
                  <ShippingAddress buttonText={buttonText}/>
                </div>
              }
            </div>
          </div>
          <div className="col-lg-5">
            <div className="border p-40 cart-totals ml-30 mb-50">
              <div className="d-flex align-items-end justify-content-between mb-30">
                <h4>Your Order</h4>
                <ul>
                  <li><h6 className="text-brand text-right" >Subtotal: &#8377;{getCartSubTotal()}</h6></li>
                  { pincode &&
                    <>
                      <li><h6 className="text-brand text-right" >Shipping: &#8377;{totalShippingCost()}</h6></li>
                      <li><h6 className="text-brand text-right" >Total: &#8377;{totalCost()}</h6></li>
                    </>
                  }
                </ul>
              </div>
              <div className="divider-2 mb-30"></div>
                <div className="table-responsive order_table checkout">
                  <table className="table no-border">
                    <tbody>
                      { cartItems.map(item => 
                        <tr key={item.productId}>
                          <td className="image product-thumbnail">
                            <Link to={`/product/${item.productId}`} className="product-name mb-10 text-heading">
                              <img src={item.imageUrl} alt={item.name} />
                            </Link>
                          </td>
                          <td>
                            <h6 className="w-160 mb-5">
                              <Link to={`/product/${item.productId}`} className="text-heading">
                                {item.productName}
                              </Link>
                            </h6>
                            <div className="product-rate-cover">
                              <div className="product-rate d-inline-block">
                                <div className="product-rating" style={{width:"90%"}}></div>
                              </div>
                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                            </div>
                          </td>
                          <td>
                            <h6 className="text-muted pl-20 pr-20">x {item.qty}</h6>
                          </td>
                          <td>
                            <h4 className="text-brand">&#8377;{item.price}</h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
            </div>
            { pincode && (user.userInfo.isLogin || validateBillingAddress()) && Number(getCartSubTotal()) >= 500 &&
              <div className="payment ml-30">
                <h4 className="mb-30">Payment</h4>
                <div className="payment_option">
                  <div className="custome-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios2"
                      value="direct_bank_transfer"
                      onChange={(e) => setPaymentMethod(e.target.value)}/>
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                      data-bs-toggle="collapse"
                      data-target="#bankTranfer"
                      aria-controls="bankTranfer">Direct Bank Transfer</label>
                  </div>
                  <div className="custome-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios3"
                      value="credit_card"
                      onChange={(e) => setPaymentMethod(e.target.value)} />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios3"
                      data-bs-toggle="collapse"
                      data-target="#bankTranfer"
                      aria-controls="bankTranfer">Credit Card</label>
                  </div>
                  <div className="custome-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios4"
                      value="cash_on_delivery"
                      onChange={(e) => setPaymentMethod(e.target.value)} />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                      data-bs-toggle="collapse"
                      data-target="#checkPayment"
                      aria-controls="checkPayment">Cash on delivery</label>
                  </div>
                  <div className="custome-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="upi"
                      onChange={(e) => setPaymentMethod(e.target.value)} />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                      data-bs-toggle="collapse"
                      data-target="#paypal"
                      aria-controls="paypal">UPI</label>
                  </div>
                </div>
                <div className="row">
                  { paymentMethod !== 'cash_on_delivery' ? (
                    <Cashfree
                      user={user}
                      paymentMethod={paymentMethod}
                      addresses={addresses}
                      cartItems={cartItems}
                      subtotal={getCartSubTotal()}
                      total={totalCost()}
                      billingAddress={billingAddress} />)
                    : paymentMethod === 'cash_on_delivery' ? (
                      <div className='col-6'>
                        <div className='center'>
                          <button
                            title="First create shipping address"
                            className="btn btn-fill-out btn-block mt-10"
                            onClick={placeAnOrder}
                            type="submit">Place an order<i className="fi-rs-sign-out ml-15"></i>
                          </button>
                          <span></span>
                        </div>
                      </div>
                    )
                    : (
                      <div className='col-6'>
                        <div className='center'>
                          <button
                            title="First create shipping address"
                            disabled
                            className="btn btn-fill-out btn-block mt-10"
                            type="submit">Place an order<i className="fi-rs-sign-out ml-15"></i>
                          </button>
                          <span></span>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout;