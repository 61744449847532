
function RefundPolicy() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2 text-center">
                    <h2>Refund Policy</h2>
                  </div>
                  <div className="single-content mb-50">
                    <p>Welcome to Dhenki Foods Private Limited. We appreciate your trust in our products. Our return and refund policy ensures a smooth process in case you encounter any issues with your purchase.</p>
                                        <h4>1. Exchanges and Returns:</h4>
                    <p>We offer a 7-day exchange/return policy, giving you ample time to request a replacement or return. To initiate an exchange/return, please contact us at:</p>
                    <p><strong>Email: dhenkifood@gmail.com</strong></p>
                    <p><strong>Customer Helpline: +91 8240379055</strong></p>
                    <p>To be eligible for an exchange/return, the item must be in its original packaging, unused, and in the same condition as received. The invoice must be provided at the time of return pickup. Once a product has been used, it becomes ineligible for exchange or return.</p>
                    <h5>Exchanges/Returns are permitted in the following cases:</h5>
                    <ul>
                      <li>The product is damaged, or you received the wrong item.</li>
                      <li>The product is not sealed properly upon delivery.</li>
                      <li>The product has expired by the time of delivery.</li>
                    </ul>
                    <p>Upon acceptance of your return, we will arrange for pickup from the same address. You will be notified of the expected pick-up date. In the case of an exchange, the replacement product will be delivered within 14-15 days after the return pick-up.</p>
                    <p>For any delivery-related discrepancies, please contact us within 48 hours after the order has been marked as delivered.</p>
                    <p><strong>Note:</strong> For quality issues, contact us at <strong>+91 8240379055</strong> or via email at <strong>dhenkifood@gmail.com.</strong></p>
              
                    <h4>2. Cancellation:</h4>
                    <p>We accept order cancellation requests if the product has not yet been shipped. If accepted, you are entitled to a refund of the entire amount.</p>
                    <p>Dhenki Foods reserves the right to cancel or refuse any order for reasons such as stock unavailability, pricing errors, or issues with personal/financial details provided by the customer.</p>
                    
                    <h4>3. Refunds:</h4>
                    <p>Once we receive and inspect your return, we will notify you of its status. If approved, you will be automatically refunded via your original payment method. Please allow 5-7 days for the refund to reflect.</p>
                    <p>For any questions regarding returns, please contact us at:</p>
                    <p><strong>Email: dhenkifood@gmail.com</strong></p>
                    <p><strong>Customer Helpline: +91 8240379055</strong></p>
                    
                    <p>Thank you for choosing Dhenki Foods. We are committed to providing a hassle-free experience with our products and services.</p>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>      
  )
}

export default RefundPolicy;