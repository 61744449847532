export const GET_ADDRESSES_REQUEST = "GET_ADDRESSES_REQUEST";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_FAIL = "GET_ADDRESSES_FAIL";

export const GET_ADDRESS_DETAILS_REQUEST = "GET_ADDRESS_DETAILS_REQUEST";
export const GET_ADDRESS_DETAILS_SUCCESS = "GET_ADDRESS_DETAILS_SUCCESS";
export const GET_ADDRESS_DETAILS_FAIL = "GET_ADDRESS_DETAILS_FAIL";
export const GET_ADDRESS_DETAILS_RESET = "GET_ADDRESS_DETAILS_RESET";

export const ADD_ADDRESS = 'ADD_ADDRESS'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
