function CustomerDetails({customer, shipping}) {
  return (
    <div className="col-md-4">
      <article className="icontext align-items-start">
        <span className="icon icon-sm rounded-circle bg-primary-light">
          <i className="text-primary material-icons md-person"></i>
        </span>
        <div className="text">
          <h6 className="mb-1">Customer Details</h6>
          <p className="mb-1">
            {customer.fullName} <br />
            {customer.email} <br />
            {shipping.phone}
          </p>
          <a href={`/users/${customer._id}`}>View profile</a>
        </div>
      </article>
    </div>
  )
}

export default CustomerDetails;