
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { getProductDetails } from '../../redux/actions/productActions'
import ProductInfo from "./ProductInfo";
import { Code } from 'react-content-loader'

function ShowProduct({match, history}) {
  const MyCodeLoader = () => <Code />
  const dispatch = useDispatch()
  const productDetails = useSelector(state => state.getProductDetails)
  const { loading, error, product } = productDetails

  useEffect(() => {
    // if (product && match.params.id !== product._id) {
      dispatch(getProductDetails(match.params.id))
    // }
  }, [dispatch, match])

  return (
    <div>
    {loading ? (
      <MyCodeLoader />
    ) : error ? (
      <h2>{error}</h2>
    ) : (
        product && product.categoryId && <ProductInfo product={product} />
     )}
    </div>
  )
}

export default ShowProduct;