import './Categories.css'
import SideNav from '../SideNav'
import { useDispatch } from "react-redux";
import { addCategory } from "../../redux/actions/categoryActions";
import { useState } from "react";

function CategoryNew() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      addCategory({
        name,
      })
    );
  };

  return (
    <div className='styled-dashboard'>
      <SideNav/>
      <div className='content'>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <button type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default CategoryNew;