import * as actionTypes from "../constants/advertisementConstants";

export const getAdvertisementsReducer = (state = { advertisements: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ADVERTISEMENTS_REQUEST:
      return {
        loading: true,
        advertisements: [],
      };
    case actionTypes.GET_ADVERTISEMENTS_SUCCESS:
      return {
        advertisements: action.payload,
        loading: false,
      };
    case actionTypes.GET_ADVERTISEMENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
    }
  };
  
  export const getAdvertisementDetailsReducer = (state = { advertisement: {} }, action) => {
    switch (action.type) {
    case actionTypes.GET_ADVERTISEMENT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_ADVERTISEMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        advertisement: action.payload,
      };
    case actionTypes.GET_ADVERTISEMENT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ADVERTISEMENT_DETAILS_RESET:
      return {
        advertisement: {},
      };
    default:
      return state;
    }
  };
  