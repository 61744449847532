import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchOrder as listOrders } from '../../redux/actions/orderActions'
import OrderList from '../Account/orderList';

function AdminOrders() {
  const dispatch = useDispatch();
  const getOrders = useSelector(state => state.getOrders);
  const { orders } = getOrders;
  const [sortable, setSortable] = useState('')

  useEffect(() => {
    dispatch(listOrders());
  }, [dispatch])

  const sortedOrders = () => {
    return sortable ? orders.filter((order) => (order.status === sortable)) : orders
  }

  return (
    <div className="tab-pane" id="orders" role="tabpanel" aria-labelledby="orders-tab">
      <div className="row">
        { orders.length > 0 &&
          <OrderList orders={sortedOrders()} setSortable={setSortable} />
        }
      </div>
    </div>
  )
}
  
export default AdminOrders;