export const GET_ORDER_TRACKERS_REQUEST = "GET_ORDER_TRACKERS_REQUEST";
export const GET_ORDER_TRACKERS_SUCCESS = "GET_ORDER_TRACKERS_SUCCESS";
export const GET_ORDER_TRACKERS_FAIL = "GET_ORDER_TRACKERS_FAIL";

export const GET_ORDER_TRACKER_DETAILS_REQUEST = "GET_ORDER_TRACKER_DETAILS_REQUEST";
export const GET_ORDER_TRACKER_DETAILS_SUCCESS = "GET_ORDER_TRACKER_DETAILS_SUCCESS";
export const GET_ORDER_TRACKER_DETAILS_FAIL = "GET_ORDER_TRACKER_DETAILS_FAIL";
export const GET_ORDER_TRACKER_DETAILS_RESET = "GET_ORDER_TRACKER_DETAILS_RESET";

export const ADD_ORDER_TRACKER = 'ADD_ORDER_TRACKER'
export const UPDATE_ORDER_TRACKER = 'UPDATE_ORDER_TRACKER'
export const UPDATE_ORDER_TRACKER_FAIL = 'UPDATE_ORDER_TRACKER_FAIL';
export const FETCH_MY_ORDER_TRACKER = 'FETCH_MY_ORDER_TRACKER'

export const GET_ORDER_TRACKERS_WITH_ORDER_REQUEST = "GET_ORDER_TRACKERS_WITH_ORDER_REQUEST";
export const GET_ORDER_TRACKERS_WITH_ORDER_SUCCESS = "GET_ORDER_TRACKERS_WITH_ORDER_SUCCESS";
export const GET_ORDER_TRACKERS_WITH_ORDER_FAIL = "GET_ORDER_TRACKERS_WITH_ORDER_FAIL";