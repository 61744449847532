import './Categories.css'
import SideNav from '../SideNav'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCategoryDetails } from '../../redux/actions/categoryActions';

function CategoryShow({match, history}) {
  const dispatch = useDispatch();

  const categoryDetails = useSelector(state => state.getCategoryDetails);
  const {loading, error, category} = categoryDetails

  useEffect(() => {
    if (category && match.params.id !== category._id) {
      dispatch(getCategoryDetails(match.params.id))
    }
  }, [dispatch, match, category])
  
  return (
    <div className="styled-dashboard">
      <SideNav/>
      <div className='styled-created-product content'>
      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error}</h2>
      ) : ( 
        <p>
          Category:
          <span>  {category.name}</span>
        </p>
      )}
      </div>
    </div>
  )
}

export default CategoryShow;