function ShippingPolicy() {
  return (
    <div className="page-content pt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2 text-center">
                    <h2>Shipping Policy</h2>
                  </div>
                  <div className="single-content mb-50">
                    <p>Thank you for choosing Dhenki Foods Private Limited. We are committed to providing you with a seamless shopping experience. Please review our Shipping Policy to understand how we handle the delivery of our products.</p>
                    <h4>1. Shipping Partners:</h4>
                    <p>Dhenki Foods Private Limited collaborates with trusted courier partners for shipping within India. We currently offer standard shipping for all orders.</p>
  
                    <h4>2. Shipping Availability:</h4>
                    <p>We currently ship orders only within India. We regret to inform you that international shipping is not available at the moment.</p>

                    <h4>3. Shipping Time:</h4>
                    <p>The delivery time for our standard shipping varies based on the location. Typically, orders are delivered within 3-9 working days from the date of purchase.</p>
  
                    <h4>4. Order Cancellation:</h4>
                    <p>Dhenki Foods Private Limited reserves the right to cancel any order within 48 hours from the time the order is placed. If an order is cancelled, customers will be notified promptly, and any payment made for the cancelled order will be refunded.</p>
                    
                    <h4>5. Address Information:</h4>
                    <p>To ensure accurate and timely delivery, customers are requested to provide complete and accurate address information, including the correct pin code and contact number, at the time of placing an order.</p>
                    
                    <h4>6. Delivery Confirmation:</h4>
                    <p>Once your order has been shipped, you will receive a confirmation email or message containing the tracking information. This allows you to monitor the status of your delivery.</p>	
                                        
                    <h4>7. Non-Delivery Situations:</h4>
                    <p>In rare cases where delivery is not possible due to factors beyond our control, such as natural disasters, pandemics, or unforeseen events, Dhenki Foods Private Limited reserves the right to cancel the order. Customers will be informed of any such situations promptly.</p>
                    
                    <h4>8. Contact Information:</h4>
                    <p>For any questions or concerns regarding shipping, feel free to reach out to our customer service team at dhenkifood@gmail.com or contact us by phone at 8240379055. You can also visit us at Dhenki Foods Private Limited, Sammilani Park, Kolkata-700075, India.</p>
                    
                    <p>Thank you for choosing Dhenki Foods Private Limited. We appreciate your trust in our services, and we are dedicated to providing you with the best possible shipping experience.</p>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShippingPolicy;