import * as actionTypes from "../constants/addressConstants";

export const getAddressesReducer = (state = { addresses: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ADDRESSES_REQUEST:
      return {
        loading: true,
        addresses: [],
      };
    case actionTypes.GET_ADDRESSES_SUCCESS:
      return {
        addresses: action.payload,
        loading: false,
      };
    case actionTypes.GET_ADDRESSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
    }
  };
  
  export const getAddressDetailsReducer = (state = { address: {} }, action) => {
    switch (action.type) {
    case actionTypes.GET_ADDRESS_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.GET_ADDRESS_DETAILS_SUCCESS:
      return {
        loading: false,
        address: action.payload,
      };
    case actionTypes.GET_ADDRESS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ADDRESS_DETAILS_RESET:
      return {
        address: {},
      };
    default:
      return state;
    }
  };
  