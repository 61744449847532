const unitMeasure = { "500 ml": '0.5', "1 L": '1', "2 L": '2', "5 L": '5',
                      "500 grm": '0.5', "1 Kg": '1', "2 Kg": '2', "5 Kg": '5' }
const discountProductPrice = (unit, product) => {
  let price = product.discount ? (product.price * (1 - Number(product.discount)/100)).toFixed(2) : product.price
  return price * Number(unitMeasure[unit]).toFixed(2);
}

export const convertToCartData = carts => {
  return carts.map(c => {
    return {
      productId: c.productId._id,
      productName: c.productId.name,
      desc: c.productId.descriptionShort,
      imageUrl: c.productId.assets && c.productId.assets.length > 0 ? c.productId.assets[0]: '../logo192.png',
      price: discountProductPrice(c.unit, c.productId),
      countInStock: c.productId.countInStock,
      qty: c.count,
      unit: c.unit,
      _id: c._id,
    }
  })
}

export const convertToWishlistData = wishlists => {
  return wishlists.map(c => {
    return {
      productId: c.productId._id,
      productName: c.productId.name,
      desc: c.productId.descriptionShort,
      imageUrl: c.productId.imageUrl,
      price: c.productId.price,
      countInStock: c.productId.countInStock,
      qty: c.count,
      unit: c.unit,
      _id: c._id,
    }
  })
}

export const convertToOrderData = orders => {
  return orders.map(o =>{
    return {
      id: o._id,
      userId: o.userId,
      subtotal: o.subtotal,
      total: o.total,
      payment_status: o.payment_status,
      createdAt: o.createdAt,
      updatedAt: o.updatedAt,
      orderproducts: o.orderproducts,
      shipping: o.shipping,
      status: o.status
    }
  })
}

export const convertToProductSearchData = products => {
  return products.map(p => {
    return {
        id: p._id,
        name: p.name
    }
  })
}

export const convertToTotalRating = reviews => {
  if (reviews && reviews.length > 0) {
    return (reviews.map(r => r.rating).reduce((a, b) => a + b) / reviews.length).toFixed(0);
  } else {
    return 0
  }

}