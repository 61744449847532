import * as actionTypes from "../constants/subscriptionConstants"

export const getSubscriptionReducer = (state = { subscriptions: [] }, action) => {
    switch (action.type) {
      case actionTypes.GET_SUBSCRIPTIONS_REQUEST:
        return {
          loading: true,
          subscriptions: [],
        };
      case actionTypes.GET_SUBSCRIPTIONS_SUCCESS:
        return {
          subscriptions: action.payload.subscriptions,
          loading: false,
        };
      case actionTypes.GET_SUBSCRIPTIONS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };