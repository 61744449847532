import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getOrderDetails } from '../../redux/actions/orderActions';
import { getOrderProductsWithOrderId as listOrderProducts } from '../../redux/actions/orderProductActions';
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Code } from 'react-content-loader'

const OrderProductScreen = ({match, history}) => {
  const dispatch = useDispatch();
  const MyCodeLoader = () => <Code />
  const getOrderProducts = useSelector(state => state.getOrderProductsWithOrderId);
  const { orderProducts, loading, error } = getOrderProducts;

  useEffect(() => {
    dispatch(getOrderDetails(match.params.id));
  }, [dispatch, match]);

  useEffect(() => {
    dispatch(listOrderProducts(match.params.id))
  }, [dispatch, match.params.id])

  return (
    <>
      { loading ? (
        <MyCodeLoader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <div className="container mt-30"  style={{minHeight: "600px"}}>
          <div className="row">
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Date</th>
                <th>Delivery Status</th>
                <th>Payment Status</th>
                <th>Product Name</th>
                <th>Total</th>
                <th>Items</th>
              </tr>
            </thead>
            <tbody>
              {
                orderProducts && orderProducts.length > 0 && orderProducts.map((item, index) =>
                  <tr key={index} >
                    <td>{item.orderId._id}</td>
                    <td>
                      <Moment format='MMMM Do YYYY, h:mm:ss a'>{item.orderId.createdAt}</Moment>
                    </td>
                    <td>{item.delivery_status}</td>
                    <td>{item.payment_status}</td>
                    <td>{item.productId.name}</td>
                    <td>{item.productPrice * item.productQuantity}</td>
                    <td>{item.productQuantity}</td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </div>
          <div className='row'>
            <div className='float-end'>
              <Link className="btn mx-2 py-3 float-end" to={'/my-account'}>
                Cancel
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrderProductScreen;