import Pagination from '../../pagination/Pagination';
import { useMemo, useState } from 'react'
import { useDispatch } from "react-redux";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
import { removeCategoryDetails } from '../../redux/actions/categoryActions';

function List({categories}) {
  const dispatch = useDispatch();
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return categories.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, categories]);

  const removeCategory = (item) => {
    dispatch(removeCategoryDetails({ _id: item._id }));
  };

  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th colSpan="4">Name</th>
          <th colSpan="2">Shop by</th>
          <th>&nbsp;</th>
          <th colSpan="1">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {categories.length > 0 && currentTableData.length > 0 && currentTableData.map((category, index) => (
          <tr key={index} >
            <td colSpan="4">{category.name}</td>
            <td colSpan="2">{category.type}</td>  
            <td>
              <div className='col-md-6' >
                <img src={category.imageUrl} alt="" width={100} height={100}/>
              </div>
            </td>
            <td>
              <Link to={`/admin/categories/edit/${category._id}`}>
                <Button variant="outline-light" size="lg" >
                  Edit
                </Button>
              </Link>
            </td>
            <td>
              <Button variant="warning" size="lg" onClick={() => removeCategory(category)}>
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table><Pagination
        key={Date.now()}
        id={Date.now()}
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={categories.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)} /></>
  )
}

export default List;