import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddress } from '../../redux/actions/adressActions'
import { State }  from 'country-state-city';

function EditAddress({address}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState(address.firstName);
  const [lastName, setLastName] = useState(address.lastName);
  const [street, setStreet] = useState(address.street);
  const [state, setState] = useState(address.state);
  const [city, setCity] = useState(address.city);
  const [email, setEmail] = useState(address.email);
  const [pin, setPin] = useState(address.pin);
  const [phone, setPhone] = useState(address.phone);
  const [houseNumber, setHouseNumber] = useState(address.houseNumber);
  const [addressType, setAddressType] = useState(address.addressType);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateAddress(address._id, {
        userId: user.userInfo.details._id,
        firstName,
        lastName,
        street,
        state,
        city,
        email,
        pin,
        phone,
        houseNumber,
        addressType,
        defaultAddress: address.defaultAddress
      })
    );
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <span href="#" className="btn-small" onClick={handleShow}>Edit</span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h5>Address</h5>
            </div>
            <div className="card-body">
              <form method="post" name="enq">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>First Name <span className="required">*</span></label>
                    <input
                      value={firstName}
                      required=""
                      className="form-control"
                      name="First name"
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Last Name <span className="required">*</span></label>
                    <input
                      value={lastName}
                      required=""
                      className="form-control"
                      name="Last name"
                      type="text"
                      onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Street <span className="required">*</span></label>
                    <input
                      value={street}
                      required=""
                      className="form-control"
                      name="email"
                      type="text"
                      onChange={(e) => setStreet(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>House Number<span className="required">*</span></label>
                    <input
                      value={houseNumber}
                      required=""
                      className="form-control"
                      name="password"
                      type="text"
                      onChange={(e) => setHouseNumber(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>State <span className="required">*</span></label>
                    <select value={state} onChange={(e) => setState(e.target.value)} required className="form-control">
                      <option value="">Select state</option>
                      { State.getStatesOfCountry('IN').map(item => (
                        <option value={item.name} key={item.isoCode}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label>City <span className="required">*</span></label>
                    <input
                      value={city}
                      required=""
                      className="form-control"
                      name="city"
                      type="text"
                      onChange={(e) => setCity(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Pin <span className="required">*</span></label>
                    <input
                      value={pin}
                      required=""
                      className="form-control"
                      name="pin"
                      type="text"
                      onChange={(e) => setPin(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Phone <span className="required">*</span></label>
                    <input
                      value={phone}
                      required=""
                      className="form-control"
                      name="phone"
                      type="text"
                      onChange={(e) => setPhone(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Email <span className="required">*</span></label>
                    <input
                      value={email}
                      required=""
                      className="form-control"
                      name="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Address Type <span className="required">*</span></label>
                    <input
                      value={addressType}
                      required=""
                      className="form-control"
                      name="address_type"
                      type="text"
                      onChange={(e) => setAddressType(e.target.value)} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
  
export default EditAddress;