// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Backdrop.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;AACT","sourcesContent":[".backdrop {\n  width: 100%;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 100;\n  position: fixed;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
